import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getWorkersNumber } from 'services/workers.service';
import UrlRestoringService from 'services/urlRestoring.service';

import { publicUrls } from 'constants/urls';

const useRedirectNewUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedAccountId } = useSelector(state => state.accounts);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const redirectUser = async () => {
      setLoading(true);

      const newUserUrl = UrlRestoringService.getNewUserUrl();
      const currentUrl = location.pathname;
      const isMainPage = currentUrl === publicUrls.main;
      const isDashboard = currentUrl === publicUrls.profile.main;

      if (!newUserUrl || (!isMainPage && !isDashboard)) {
        setLoading(false);
        return;
      }

      const { data } = await getWorkersNumber(selectedAccountId);

      const workersNumber = data ? Object.values(data).reduce((acc, item) => acc + item, 0) : 0;

      if (!workersNumber) {
        navigate(newUserUrl);
      }

      if (isMainPage && workersNumber) {
        navigate(publicUrls.profile.main);
      }

      setLoading(false);
    };

    redirectUser();
  }, [location.pathname, navigate, selectedAccountId]);

  return { loading };
};

export default useRedirectNewUser;
