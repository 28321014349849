import { clientUrl } from 'configs';
import { publicUrls } from 'constants/urls';
import isValueEmpty from 'utils/common/isValueEmpty';

const formatParams = params => {
  if (isValueEmpty(params)) {
    return '';
  }

  return Object.keys(params)
    .map(key => {
      return `${key}=${params[key]}`;
    })
    .join('&');
};

const generateWatcherLink = ({ accessKey, path = publicUrls.observer.profile.main, fullPath, params = {} }) => {
  const basePath = fullPath ? clientUrl : '';
  const paramsString = formatParams({ ...params, access_key: accessKey });

  return `${basePath}${path}?${paramsString}`;
};

export default generateWatcherLink;
