import React from 'react';
import { Steps as AntdSteps } from 'antd';

import './Steps.styles.scss';

const Steps = ({ className = '', ...props }) => {
  return <AntdSteps className={`np-steps ${className}`} {...props} />;
};

export default Steps;
