import { useEffect, useState } from 'react';

import { get2FaUrl } from 'services/user.service';
import getTotpSecretFromUrl from '../utils/getTotpSecretFromUrl';

export const useTotpUrlQuery = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const getTotpUrl = async () => {
    setLoading(true);

    const { success, data } = await get2FaUrl();

    if (success) {
      const secret = getTotpSecretFromUrl(data.totpUri);

      setData({ url: data.totpUri, secret });
    }

    setLoading(false);
  };

  useEffect(() => {
    getTotpUrl();
  }, []);

  return { data, loading, refetch: getTotpUrl };
};
