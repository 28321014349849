import reverseString from './reverseString';

const regex = /\B(?=(\d{3})+(?!\d))/g;

const divideNumberByPlaces = (num, divider = ' ') => {
  const stringNum = num.toString();
  const splitedNum = stringNum.split('.');

  const [int, franc] = splitedNum || [stringNum];

  const newInt = int.replace(regex, divider);

  if (franc) {
    const newFranc = reverseString(reverseString(franc).replace(regex, divider));

    return [newInt, newFranc].join('.');
  }

  return newInt;
};

export default divideNumberByPlaces;
