import _castArray from 'lodash/castArray';

const userHasPermission = ({ user, requiredRoles, requiredAnyRoles }) => {
  if (!user) {
    return false;
  }
  const checkRequiredRoles = (usersRoles, requiredRoles) => {
    if (!usersRoles) {
      return true;
    }

    return _castArray(requiredRoles).every(role => {
      return !!usersRoles.includes(role);
    });
  };

  const checkPossibleRoles = (usersPermissions, possibleRoles) => {
    return _castArray(possibleRoles).some(role => {
      return !!usersPermissions.includes(role);
    });
  };

  if (!requiredRoles && !requiredAnyRoles) {
    return true;
  }
  if (!requiredRoles && requiredAnyRoles) {
    return checkPossibleRoles(user.roles, requiredAnyRoles);
  }
  return checkRequiredRoles(user.roles, requiredRoles);
};

export default userHasPermission;
