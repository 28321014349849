import antEn from 'antd/locale/en_US';
import antRu from 'antd/locale/ru_RU';

import 'dayjs/locale/ru';

export const Language = {
  RU: 'ru',
  EN: 'en',
};

export const LanguageMenuItems = [
  { label: 'EN', key: Language.EN },
  { label: 'RU', key: Language.RU },
];

export const AntLanguage = {
  [Language.EN]: antEn,
  [Language.RU]: antRu,
};
