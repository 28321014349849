import React from 'react';
import { UserOutlined } from '@ant-design/icons';

import { Menu, Spin } from 'components';

import './UserMenu.styles.scss';

const UserMenu = ({ name, footer, options = [], loading, onOptionClick = () => {}, menuClassName = '', ...props }) => {
  return (
    <Menu
      title={name ? name : <UserOutlined />}
      items={options}
      onMenuItemClick={onOptionClick}
      menuClassName={menuClassName}
      dropdownRender={menus => (
        <div className="np-user-menu__dropdown">
          <div className="np-user-menu__dropdown-menu">{loading ? <Spin /> : menus}</div>
          {footer && <div className="np-user-menu__dropdown-footer">{footer}</div>}
        </div>
      )}
      {...props}
    />
  );
};

export default UserMenu;
