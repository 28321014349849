import { createSlice } from '@reduxjs/toolkit';

export const watcherAuthSlice = createSlice({
  name: 'watcherAuth',
  initialState: {
    watcher: null,
    accessKey: null,
    pending: false,
    finished: false,
  },
  reducers: {
    setAuth: (state, action) => {
      const { watcher, accessKey, pending, finished } = action.payload;

      state.watcher = watcher;
      state.pending = pending;
      state.finished = finished;
      state.accessKey = accessKey;
    },

    setAuthPending: (state, action) => {
      state.pending = action.payload;
    },

    setAuthFinished: (state, action) => {
      state.finished = action.payload;
    },

    setAuthAccessKey: (state, action) => {
      state.accessKey = action.payload;
    },

    setWatcher: (state, action) => {
      state.watcher = action.payload;
    },
  },
});

export const { setWatcher, setAuth, setAuthFinished, setAuthPending, setAuthAccessKey } = watcherAuthSlice.actions;

export default watcherAuthSlice.reducer;
