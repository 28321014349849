import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { InfoBlock } from 'components';

import './infoItem.styles.scss';

const InfoItem = ({ title, titleThin, desc = '—', descColor, icon, link }) => {
  const { t } = useTranslation();

  const InfoComponent = (
    <InfoBlock.Item key={title + Math.random()}>
      <InfoBlock.Title icon={icon} thin={titleThin}>
        {t(title)}
      </InfoBlock.Title>

      <InfoBlock.Value style={{ color: descColor }}>{desc}</InfoBlock.Value>
    </InfoBlock.Item>
  );

  return link ? (
    <NavLink to={link} className="np-summary-block__link-block">
      {InfoComponent}
    </NavLink>
  ) : (
    InfoComponent
  );
};

export default InfoItem;
