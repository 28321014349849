import React from 'react';

import MobileAccountsSummary from './components/MobileAccountsSummary';
import DesktopAccountsSummary from './components/DesktopAccountsSummary';

import useMiningAccounts from 'hooks/user/useMiningAccounts';
import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import './AccountsSummary.styles.scss';

const AccountsSummary = ({ isWatcher }) => {
  const { isMobileView } = useWindowResponsiveness(1000);

  const { summary, loading } = useMiningAccounts({ showSummary: true, refetchOnMount: true, isWatcher });

  return isMobileView ? (
    <MobileAccountsSummary data={summary} loading={loading} />
  ) : (
    <DesktopAccountsSummary data={summary} loading={loading} />
  );
};

export default AccountsSummary;
