import { useTranslation } from 'react-i18next';
import { Granularity } from '../constants/granularity';

export const useFilterOptions = () => {
  const { t } = useTranslation();

  return [
    { label: t('hashrateChart.tenMin'), value: Granularity.H5 },
    { label: t('hashrateChart.oneHour'), value: Granularity.H1H },
  ];
};
