import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import StepLayout from '../../common/StepLayout';
import { AuthenticationForm } from 'modules';

import useCaptcha from 'hooks/forms/useCaptcha';
import useUserData from 'hooks/user/useUserData';
import useNotifications from 'hooks/common/useNotifications';

const AccountVerification = ({
  includeGa,
  includeCaptcha,
  onFinish = () => {},
  requestCallback = async () => {},
  ...props
}) => {
  const { user } = useUserData();
  const { t } = useTranslation();
  const { notificationApi } = useNotifications();

  const { isCaptchaError, checkCaptchaError, getCaptchaParams } = useCaptcha('accountVerification');

  const showCaptcha = includeCaptcha && isCaptchaError;

  const [loading, setLoading] = useState(false);

  const handleFinish = async ({ captchaV2, ...values }) => {
    setLoading(true);

    const captchaParams = getCaptchaParams(captchaV2);

    const { success, errorMessage, error } = await requestCallback({ ...values, ...captchaParams });

    if (success) {
      onFinish(values);
    } else {
      checkCaptchaError(error);
      notificationApi.error({ message: t(errorMessage) });
    }

    setLoading(false);
  };

  return (
    <StepLayout title={t('resetProcess.steps.accountVerification.content.title')} maxWidth={450}>
      <AuthenticationForm
        includePassword
        includeGa={includeGa && user['2fa']}
        includeCaptcha={showCaptcha}
        loading={loading}
        onFinish={handleFinish}
        {...props}
      />
    </StepLayout>
  );
};

export default AccountVerification;
