import React from 'react';
import { Space } from 'antd';
import { useLocation } from 'react-router-dom';

import { Logo } from 'components';
import UserMenu from './components/UserMenu';
import VipIndicators from './components/VipIndicators';
import NavigationMenu from './components/NavigationMenu';
import { AccountsSummary, LanguageSelect, NetwokSummary, ThemeSwitcher } from 'modules';

import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import { publicUrls } from 'constants/urls';

import './Header.styles.scss';

const Header = ({ sticky = true, showLogo }) => {
  const stickClass = sticky ? 'np-user-layout-header--sticky' : '';

  const location = useLocation();

  const { isMobileView } = useWindowResponsiveness(900);
  const { isMobileView: hideSummary } = useWindowResponsiveness(1000);
  const { isMobileView: hideNetworkSummary } = useWindowResponsiveness(1200);

  const isDashboardPage = location.pathname === publicUrls.profile.main;
  const showLeftHeader = !isDashboardPage || isMobileView || showLogo || hideSummary;

  return (
    <div className={`np-user-layout-header ${stickClass}`}>
      <div className="np-user-layout-header__body">
        {showLeftHeader && (
          <div className="np-user-layout-header__left">
            {isMobileView && <NavigationMenu />}

            {(showLogo || isMobileView) && <Logo />}
          </div>
        )}

        {isDashboardPage && !hideSummary && (
          <Space size={16} style={{ flexWrap: 'wrap' }}>
            {!hideNetworkSummary && <NetwokSummary />}
            <AccountsSummary />
          </Space>
        )}

        <Space size={isMobileView ? 'middle' : 'large'}>
          <VipIndicators />

          {!isMobileView && <LanguageSelect />}

          {!isMobileView && <ThemeSwitcher />}

          <UserMenu />
        </Space>
      </div>
    </div>
  );
};

export default Header;
