import { apiUrls } from 'constants/urls';
import request from './request.service';
import asyncErrorHandler from 'utils/requests/errorHandler';

export const getCoinsInfo = async () => {
  const callback = async () => {
    return await request(apiUrls.statistics.infoBar);
  };

  return await asyncErrorHandler(callback);
};

export const getServerChartData = async () => {
  const callback = async () => {
    return await request(apiUrls.charts.server);
  };

  return await asyncErrorHandler(callback);
};

export const getServerInfo = async () => {
  const callback = async () => {
    return await request(apiUrls.statistics.statsBar);
  };

  return await asyncErrorHandler(callback);
};
