import { DefaultPagination } from 'constants/pagination';
import { DEFAULT_ROW_HEIGHT } from '../constants/pagination';

const setTableHeight = (node, options = {}) => {
  const { rowHeight = DEFAULT_ROW_HEIGHT, pageSize = DefaultPagination.PAGE_LIMIT } = options || {};

  if (!node) {
    return;
  }

  const tableBodyEl = node.querySelector('.ant-table-body');

  if (!tableBodyEl) {
    return;
  }

  const tableHeight = rowHeight * pageSize;

  tableBodyEl.style.height = tableHeight + 'px';
};

export default setTableHeight;
