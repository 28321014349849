import React from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { BaseModal } from 'components';

import './AlertModal.styles.scss';

const AlertModal = ({ title, message = '', type = 'info', width = 450, children, className = '', ...props }) => {
  const { t } = useTranslation();

  const typeClass = `np-alert-modal--${type}`;

  const icon = type === 'success' ? <CheckCircleOutlined /> : <ExclamationCircleOutlined />;

  return (
    <BaseModal
      icon={icon}
      title={t(title)}
      width={width}
      className={`np-alert-modal ${typeClass} ${className}`}
      {...props}
    >
      <Alert type={type} message={typeof message === 'string' ? t(message) : message} />

      {children}
    </BaseModal>
  );
};

export default AlertModal;
