import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { AcceptFileTypes, FileTypeLabel, MAX_FILES_COUNT, MAX_FILE_SIZE_MB } from '../../constants/files';

import './Requirements.styles.scss';

const { Text } = Typography;

const Requirements = () => {
  const { t } = useTranslation();

  const acceptedFileTypes = AcceptFileTypes.map(type => FileTypeLabel[type]).join(', ');

  return (
    <div className="np-reset-process-identif-verify__requirements">
      <Text className="np-reset-process-identif-verify__requirements-title">
        {t('resetProcess.steps.identityVerification.content.requirements.title')}
      </Text>

      <ol>
        <li>{t('resetProcess.steps.identityVerification.content.requirements.first')}</li>
        <li>{t('resetProcess.steps.identityVerification.content.requirements.second', { value: MAX_FILES_COUNT })}</li>
        <li>
          {t('resetProcess.steps.identityVerification.content.requirements.third', {
            value: MAX_FILE_SIZE_MB,
            types: acceptedFileTypes,
          })}
        </li>
      </ol>
    </div>
  );
};

export default Requirements;
