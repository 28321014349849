import { useTranslation } from 'react-i18next';

import getPowerLabel from 'utils/power/getPowerLabel';
import getComputingSpeedUnit from 'utils/power/getComputingSpeedUnit';

const useSummaryItems = data => {
  const { t } = useTranslation();

  const { price, networkHashrate, difficulty } = data || {};

  return [
    [
      {
        title: t('networkSummary.bitcoinPrice'),
        desc: `$${price}`,
      },
      {
        title: t('networkSummary.networkHashrate'),
        desc: getComputingSpeedUnit(networkHashrate?.count, networkHashrate?.desc),
      },
      {
        title: t('networkSummary.networkDifficulty'),
        desc: `${difficulty?.value} ${getPowerLabel(difficulty?.power)}`,
      },
    ],
  ];
};

export default useSummaryItems;
