import { createSlice } from '@reduxjs/toolkit';

const defaultData = {
  selectedAccountId: null,
  accountsList: [],
  mainAccountId: null,
};

export const watcherAccountsSlice = createSlice({
  name: 'watcherAccounts',
  initialState: defaultData,
  reducers: {
    setAccounts: (state, action) => {
      const { main, subaccounts: subAccounts } = action.payload;
      const selectedAccountId = main ? main.id : subAccounts[0].id;
      const accountsList = main ? [main, ...subAccounts] : subAccounts;

      const accountsData = {
        selectedAccountId,
        accountsList,
      };

      state.selectedAccountId = accountsData.selectedAccountId;
      state.accountsList = accountsData.accountsList;
      state.mainAccountId = main ? main.id : null;
    },
    setSelectedAccountId: (state, action) => {
      const accountData = {
        ...state,
        selectedAccountId: action.payload,
      };

      state.selectedAccountId = accountData.selectedAccountId;
    },
    resetAccounts: state => {
      state.accountsList = defaultData.accountsList;
      state.selectedAccountId = null;
    },
  },
});

export const { setAccounts, setSelectedAccountId, resetAccounts } = watcherAccountsSlice.actions;

export default watcherAccountsSlice.reducer;
