const replacePaginationElements = node => {
  if (!node) {
    return;
  }

  const paginationEls = node.querySelectorAll('.ant-pagination');

  if (!paginationEls.length) {
    return;
  }

  paginationEls.forEach(paginationEl => {
    const pageSizeEl = paginationEl.querySelector('.ant-pagination-options');

    if (!pageSizeEl) {
      return;
    }

    paginationEl.prepend(pageSizeEl);
  });
};

export default replacePaginationElements;
