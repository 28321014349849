import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAuth } from 'store/reducers/user';
import { setAccounts } from 'store/reducers/accounts';
import request from 'services/request.service';
import userHasPermission from 'utils/auth/userHasPermission';
import UrlRestoringService from 'services/urlRestoring.service';

import { apiUrls } from 'constants/urls';

export const useCheckUserAuth = (options = {}) => {
  const dispatch = useDispatch();
  const userAuth = useSelector(state => state.user);
  const { finished, pending, user } = userAuth;
  const { redirectOnFail, requiredRoles, requiredAnyRoles } = options;

  const authRequestWasSent = pending || finished;

  const userIsAuthenticated = finished && user !== null;

  const userIsAuthorized = userHasPermission({ user, requiredRoles, requiredAnyRoles });

  const ensureAuthRequestWasSent = useCallback(async () => {
    if (!authRequestWasSent) {
      if (redirectOnFail) {
        UrlRestoringService.setRedirectUrl(redirectOnFail);
      }

      try {
        dispatch(setAuth({ ...userAuth, pending: true }));

        const [userProfile, userAccounts] = await Promise.all([
          request(apiUrls.auth.currentUser, { disableRedirectOnFail: true }),
          request(apiUrls.accounts.all, { disableRedirectOnFail: true }),
        ]);

        dispatch(setAuth({ user: userProfile, pending: false, finished: true }));
        dispatch(setAccounts(userAccounts));
      } catch (e) {
        dispatch(setAuth({ user: null, pending: false, finished: true }));
      }
    }
  }, [authRequestWasSent, dispatch, redirectOnFail, userAuth]);

  useEffect(() => {
    ensureAuthRequestWasSent();
  }, [ensureAuthRequestWasSent]);

  return { loading: !authRequestWasSent || pending, isAuth: userIsAuthenticated, isAuthorized: userIsAuthorized };
};
