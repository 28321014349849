import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setPaymentBlock } from 'store/reducers/payment';
import checkIsPaymentBlock from 'utils/payment/checkIsPaymentBlock';
import convertTimestampToHumanDate from 'utils/date/convertTimestampToHumanDate';

const usePaymentBlock = (options = {}) => {
  const { onBlock = () => {} } = options || {};

  const dispatch = useDispatch();
  const { block, unlockDate } = useSelector(state => state.payment);

  const setBlock = useCallback(
    ({ block, unlockDate }) => {
      dispatch(setPaymentBlock({ block, unlockDate }));
    },
    [dispatch],
  );

  const checkPaymentError = error => {
    const isPaymentBlock = checkIsPaymentBlock(error);

    if (isPaymentBlock) {
      const unlockTimestamp = error?.body?.options?.unlockDate;
      const unlockDate = convertTimestampToHumanDate(unlockTimestamp).toISOString();

      dispatch(setPaymentBlock({ block: true, unlockDate }));
    }
  };

  useEffect(() => {
    if (block) {
      onBlock();
    }
  }, [block, onBlock]);

  return { block, unlockDate, checkPaymentError, setBlock };
};

export default usePaymentBlock;
