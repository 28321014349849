import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setInternalNotifications } from 'store/reducers/internalNotifications';
import { getInternalUserNotifications } from 'services/notifications.service';

import { InternalNotificationsTypes } from 'constants/internalNotifications';

const useNotificationsQuery = (options = {}) => {
  const { refetchOnMount = true } = options;
  const type = InternalNotificationsTypes.USER;

  const dispatch = useDispatch();
  const { userNotifications } = useSelector(state => state.internalNotifications);

  const [loading, setLoading] = useState(false);

  const onChange = useCallback(
    newData => {
      dispatch(setInternalNotifications({ items: newData || [], type }));
    },
    [dispatch, type],
  );

  const getNotifications = useCallback(async () => {
    setLoading(true);

    const { data } = await getInternalUserNotifications();

    dispatch(setInternalNotifications({ items: data || [], type }));

    setLoading(false);
  }, [dispatch, type]);

  useEffect(() => {
    if (refetchOnMount) {
      getNotifications();
    }
  }, [getNotifications, refetchOnMount]);

  return { data: userNotifications, loading, refetch: getNotifications, onChange };
};

export default useNotificationsQuery;
