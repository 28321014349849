import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { SidebarLayout } from 'components';

import generateWatcherLink from 'utils/watcher/generateWatcherLink';
import useWatcherSidebarOptions from 'hooks/navigation/useWatcherSidebarOptions';

import { publicUrls } from 'constants/urls';

const WatcherSidebarLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const menuItems = useWatcherSidebarOptions();
  const { watcher, accessKey } = useSelector(state => state.watcherAuth);

  const logoUrl = useMemo(
    () => (watcher && accessKey ? generateWatcherLink({ accessKey }) : publicUrls.main),
    [accessKey, watcher],
  );

  const activeMenuItem = location.pathname;

  const defaultOpenedKeys = useMemo(() => {
    if (
      activeMenuItem.includes(publicUrls.observer.profile.settings.main) ||
      activeMenuItem.includes(publicUrls.observer.profile.accountManagement.main)
    ) {
      return publicUrls.observer.profile.settings.main;
    }

    if (activeMenuItem.includes(publicUrls.observer.profile.wallet.main)) {
      return publicUrls.observer.profile.wallet.main;
    }

    return null;
  }, [activeMenuItem]);

  const onMenuClick = item => {
    const path = item.item.props.link;

    if (typeof path === 'string') {
      const watcherLink = generateWatcherLink({ accessKey, path });
      navigate(watcherLink);
    }
  };

  return (
    <SidebarLayout
      onClick={onMenuClick}
      active={activeMenuItem}
      items={menuItems}
      defaultOpenKeys={[defaultOpenedKeys]}
      logoUrl={logoUrl}
    >
      {children}
    </SidebarLayout>
  );
};

export default WatcherSidebarLayout;
