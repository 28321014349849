import { PRIMARY_COLOR, DefaultColors } from 'constants/colors';

export const COLORS_MAP_LIGHT = {
  active: PRIMARY_COLOR,
  inactive: DefaultColors.DARK_CERULEAN,
  offline: DefaultColors.LIGHT_SILVER,
};

export const COLORS_MAP_DARK = {
  active: PRIMARY_COLOR,
  inactive: DefaultColors.DARK_CERULEAN,
  offline: DefaultColors.SPANISH_GRAY,
};
