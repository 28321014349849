import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import { Button, Result as BaseResult } from 'components';

import StepLayout from '../../common/StepLayout';

import useUserData from 'hooks/user/useUserData';

import './Result.styles.scss';

const { Text } = Typography;

const Result = ({ redirectUrl }) => {
  const { t } = useTranslation();
  const { user } = useUserData();

  return (
    <StepLayout className="np-reset-process-result">
      <BaseResult
        status="warning"
        title={t('resetProcess.steps.result.content.title')}
        subTitle={
          <Text className="np-reset-process-result__subtitle">
            <Text>{t('resetProcess.steps.result.content.desc')}</Text>
            <Text className="np-reset-process-result__subtitle--accent">{user.email}</Text>
          </Text>
        }
      />

      <NavLink to={redirectUrl}>
        <Button size="large" block className="np-reset-process-result__button">
          {t('form.button.gotIt')}
        </Button>
      </NavLink>
    </StepLayout>
  );
};

export default Result;
