import React from 'react';

const TableHeader = ({ children, style, className = '', ...props }) => {
  return (
    <div style={{ ...style, ...props }} className={className}>
      {children}
    </div>
  );
};

export default TableHeader;
