import { createSlice } from '@reduxjs/toolkit';
import { InternalNotificationsTypes } from 'constants/internalNotifications';

export const internalNotificationsSlice = createSlice({
  name: 'internalNotifications',

  initialState: {
    userNotifications: [],
    inAppNotifications: [],
  },

  reducers: {
    setInternalNotifications: (state, action) => {
      const { items = [], type } = action.payload;

      switch (type) {
        case InternalNotificationsTypes.USER:
          state.userNotifications = items;
          break;

        case InternalNotificationsTypes.IN_APP:
          state.inAppNotifications = items;
          break;

        default:
          break;
      }
    },

    removeInternalNotification: (state, action) => {
      const { id, type } = action.payload;

      switch (type) {
        case InternalNotificationsTypes.USER:
          state.userNotifications = state.userNotifications.filter(item => item.id !== id);
          break;

        case InternalNotificationsTypes.IN_APP:
          state.inAppNotifications = state.inAppNotifications.filter(item => item.id !== id);
          break;

        default:
          break;
      }
    },

    addInternalNotification: (state, action) => {
      const { item, type } = action.payload;

      switch (type) {
        case InternalNotificationsTypes.USER:
          state.userNotifications.push(item);
          break;

        case InternalNotificationsTypes.IN_APP:
          state.inAppNotifications.push(item);
          break;

        default:
          break;
      }
    },
  },
});

export const { setInternalNotifications, removeInternalNotification, addInternalNotification } =
  internalNotificationsSlice.actions;

export default internalNotificationsSlice.reducer;
