import React from 'react';
import { Loading } from 'components';

import './BlockWrapper.styles.scss';

const BlockWrapper = ({ className, children, noPadding, noBorderRadius, stretch, loading, ...props }) => (
  <div
    className={`np-block-wrapper
     ${className ?? ''}
     ${noPadding ? 'np-block-wrapper--no-padding' : ''}
     ${stretch ? 'np-block-wrapper--stretch' : ''}
     ${noBorderRadius ? 'np-block-wrapper--no-border-radius' : ''}
     `}
    {...props}
  >
    <Loading isLoading={loading}>{children}</Loading>
  </div>
);

BlockWrapper.Title = ({ children, className, size, noPadding = true }) => (
  <div
    className={`np-block-wrapper__title ${className ? className : ''} ${
      size === 'large' ? 'np-block-wrapper__title--large' : ''
    }`}
    style={{ padding: noPadding && 0 }}
  >
    {children}
  </div>
);

BlockWrapper.Header = ({ children, className }) => (
  <div className={`np-block-wrapper__header ${className ?? ''}`}>{children}</div>
);

BlockWrapper.Description = ({ children, className }) => (
  <div className={`np-block-wrapper__description ${className ?? ''}`}>{children}</div>
);

export default BlockWrapper;
