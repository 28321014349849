import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StagedModal } from 'components';
import CompletedStep from './components/CompletedStep';
import DownloadAppStep from './components/DownloadAppStep';
import SecurityCheckForm from './components/SecurityCheckForm';

import useEnableGaSteps from './hooks/useEnableGaSteps';

import { EnableGaSteps } from './constants/steps';

import './EnableGaModal.styles.scss';

const EnableGaModal = ({ open, onClose = () => {}, onFinish = () => {} }) => {
  const { t } = useTranslation();
  const steps = useEnableGaSteps();

  const [currentStep, setCurrentStep] = useState(EnableGaSteps.DOWNLOAD_APP);
  const [codes, setCodes] = useState([]);

  const isDownloadAppStep = currentStep === EnableGaSteps.DOWNLOAD_APP;
  const isSecurityChackStep = currentStep === EnableGaSteps.SECURITY_CHECK;
  const isCompletedStep = currentStep === EnableGaSteps.COMPLETED;

  const downloadAppFinish = () => {
    setCurrentStep(EnableGaSteps.SECURITY_CHECK);
  };

  const checkSecurityFinish = codes => {
    setCurrentStep(EnableGaSteps.COMPLETED);
    setCodes(codes);
    onFinish();
  };

  const handleClose = () => {
    setCurrentStep(EnableGaSteps.DOWNLOAD_APP);
    onClose();
  };

  return (
    <StagedModal
      title={t('accountSettings.accountSecurity.form.twoFAEnable.title')}
      open={open}
      width={450}
      steps={steps}
      currentStep={currentStep}
      onChangeStep={setCurrentStep}
      onCancel={handleClose}
      className="np-enable-ga-modal"
    >
      {isDownloadAppStep && <DownloadAppStep onFinish={downloadAppFinish} />}

      {isSecurityChackStep && (
        <SecurityCheckForm
          showGoBack
          onFinish={checkSecurityFinish}
          onError={handleClose}
          onGoBack={() => setCurrentStep(EnableGaSteps.DOWNLOAD_APP)}
        />
      )}

      {isCompletedStep && <CompletedStep codes={codes} />}
    </StagedModal>
  );
};

export default EnableGaModal;
