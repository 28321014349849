import { useTranslation } from 'react-i18next';

import { PaymentPasswordEditSteps } from '../constants/steps';

const usePaymentPasswordEditSteps = () => {
  const { t } = useTranslation();

  return [
    {
      key: PaymentPasswordEditSteps.PAYMENT_PASSWORD,
      title: t('accountSettings.accountSecurity.form.paymentPassword.steps.setUpPassword'),
    },
    {
      key: PaymentPasswordEditSteps.AUTH,
      title: t('accountSettings.accountSecurity.form.paymentPassword.steps.securityCheck'),
      disabled: true,
    },
    {
      key: PaymentPasswordEditSteps.COMPLETED,
      title: t('accountSettings.accountSecurity.form.paymentPassword.steps.completed'),
      disabled: true,
    },
  ];
};

export default usePaymentPasswordEditSteps;
