import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { publicUrls } from 'constants/urls';

const useDesktopMenuLinks = () => {
  const { t } = useTranslation();
  const { user } = useSelector(state => state.user);

  const unloggedInLinks = [];

  const loggedInLinks = [
    {
      key: publicUrls.profile.main,
      label: <NavLink to={publicUrls.profile.main}>{t('menu.dashboard')}</NavLink>,
    },
    {
      key: publicUrls.profile.workers,
      label: <NavLink to={publicUrls.profile.workers}>{t('menu.workers')}</NavLink>,
    },
    {
      key: publicUrls.profile.settings.payment,
      label: <NavLink to={publicUrls.profile.settings.payment}>{t('menu.assets')}</NavLink>,
    },
  ];

  return user ? loggedInLinks : unloggedInLinks;
};

export default useDesktopMenuLinks;
