import React from 'react';
import { Radio } from 'antd';

import './RadioGroup.styles.scss';

const RadioGroup = ({ children, onChange = () => {}, className = '', ...props }) => {
  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <Radio.Group onChange={handleChange} optionType="button" className={`np-radio-group ${className}`} {...props}>
      {children}
    </Radio.Group>
  );
};

export default RadioGroup;
