import { useState } from 'react';

import { enable2Fa } from 'services/user.service';

export const useEnable2FaMutation = () => {
  const [loading, setLoading] = useState(false);

  const enableUser2Fa = async ({ totp }) => {
    setLoading(true);

    const result = await enable2Fa({ totp });

    setLoading(false);

    return result;
  };

  return { loading, refetch: enableUser2Fa };
};
