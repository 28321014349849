import React, { useMemo } from 'react';

import useTheme from 'hooks/common/useTheme';

import { LayoutBackgroundType } from './constants/background';

import './BackgroundLayout.styles.scss';

const BackgroundLayout = ({ children, backgroundType = LayoutBackgroundType.SOLID, className = '', ...props }) => {
  const { theme } = useTheme();

  const backgroundClass = useMemo(() => {
    if (backgroundType === LayoutBackgroundType.SOLID) {
      return `np-background-layout--solid-${theme}`;
    }

    if (backgroundType === LayoutBackgroundType.LOOPER) {
      return `np-background-layout--looper-${theme}`;
    }

    if (backgroundType === LayoutBackgroundType.GRADIENT) {
      return `np-background-layout--gradient-${theme}`;
    }
  }, [backgroundType, theme]);

  return (
    <div className={`np-background-layout ${backgroundClass} ${className}`} {...props}>
      {children}
    </div>
  );
};

export default BackgroundLayout;
