import React from 'react';
import useNetworkQuery from './hooks/useNetworkQuery';
import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';
import DesktopNetworkSummary from './components/DesktopNetworkSummary/DesktopNetworkSummary';
import MobileNetworkSummary from './components/MobileNetworkSummary';

const NetwokSummary = () => {
  const { isMobileView } = useWindowResponsiveness(1200);

  const { data, isLoading } = useNetworkQuery();

  if (isMobileView) {
    return <MobileNetworkSummary data={data} loading={isLoading} />;
  }

  return <DesktopNetworkSummary data={data} loading={isLoading} />;
};

export default NetwokSummary;
