import React from 'react';
import { Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { CalendarFilled, PushpinFilled } from '@ant-design/icons';

import BgDark from './images/bg-dark.png';
import BgLight from './images/bg-light.png';

import useTheme from 'hooks/common/useTheme';

import './BlockchainLifeDubaiBanner.styles.scss';

const { Text } = Typography;

const BlockchainLifeDubaiBanner = () => {
  const { t } = useTranslation();
  const { isDarkMode } = useTheme();

  const backgroundImage = isDarkMode ? `url(${BgDark})` : `url(${BgLight})`;

  return (
    <div className="np-banner-carousel-blockchain-life-banner" style={{ backgroundImage }}>
      <div className="np-banner-carousel-blockchain-life-banner__header">
        <Text className="np-banner-carousel-blockchain-life-banner__header-title">
          <Trans i18nKey="banners.blockchainLifeInDubai.title" />
        </Text>
      </div>

      <div className="np-banner-carousel-blockchain-life-banner__body">
        <div className="np-banner-carousel-blockchain-life-banner__body-item">
          <PushpinFilled />
          <Text>{t('banners.blockchainLifeInDubai.location')}</Text>
        </div>

        <div className="np-banner-carousel-blockchain-life-banner__body-item">
          <CalendarFilled />
          <Text>{t('banners.blockchainLifeInDubai.date')}</Text>
        </div>
      </div>
    </div>
  );
};

export default BlockchainLifeDubaiBanner;
