import React from 'react';
import { Alert } from 'antd';

import { Spin } from 'components';

import useNotificationsMutation from '../../hooks/user-notifications/useNotificationsMutation';
import useNotificationMessages from '../../hooks/common/useNotificationMessages';

import { NotificationType } from '../../constants/types';

import './Notification.styles.scss';

const Notification = ({ data, onDelete = () => {} }) => {
  const messages = useNotificationMessages();
  const { loading, deleteNotification } = useNotificationsMutation();

  const closable = data.msg_type === NotificationType.CLOSABLE && !loading;
  const message = messages[data.msg] || data.msg;

  const handleDelete = async (id, isInApp) => {
    await deleteNotification(id);
    onDelete(id, isInApp);
  };

  return (
    <Alert
      showIcon
      type="warning"
      message={message}
      closable={closable}
      action={loading && <Spin size="small" />}
      onClose={() => handleDelete(data.id, data.isInApp)}
      className="np-internal-notifications__notification"
    />
  );
};

export default Notification;
