import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    pending: false,
    finished: false,
  },
  reducers: {
    setAuth: (state, action) => {
      const { user, pending, finished } = action.payload;

      state.user = user;
      state.pending = pending;
      state.finished = finished;
    },

    setAuthPending: (state, action) => {
      state.pending = action.payload;
    },

    setAuthFinished: (state, action) => {
      state.finished = action.payload;
    },

    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setAuth, setAuthFinished, setAuthPending } = userSlice.actions;

export default userSlice.reducer;
