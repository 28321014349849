import React, { useCallback } from 'react';

import { FilesDropzone } from 'components';
import FilesList from './components/FilesList';

import { getFilesWithUrl } from './utils/files';
import useErrorMessage from './hooks/useErrorMessage';
import useNotifications from 'hooks/common/useNotifications';

import { DropzoneAcceptFiles, MAX_FILES_COUNT, MAX_FILE_SIZE_MB } from '../../constants/files';

import './Files.styles.scss';

const Files = ({ value: files = [], disabled, onChange = () => {} }) => {
  const errorMessage = useErrorMessage();
  const { notificationApi } = useNotifications();

  const noFiles = !files || !files.length;

  const onUpload = useCallback(
    newFiles => {
      const modifiedFiles = getFilesWithUrl(newFiles);

      onChange([...files, ...modifiedFiles]);
    },
    [onChange, files],
  );

  const onError = useCallback(
    files => {
      const errorCode = files[0].errors[0].code;

      notificationApi.error({ message: errorMessage[errorCode] });
    },
    [errorMessage, notificationApi],
  );

  const onDelete = url => {
    const newFiles = files.filter(file => file.url !== url);

    onChange(newFiles);
  };

  return (
    <div className="np-reset-process-identif-verify__files">
      <FilesDropzone
        multiple
        onUpload={onUpload}
        onError={onError}
        accept={DropzoneAcceptFiles}
        maxSize={MAX_FILE_SIZE_MB}
        maxFiles={Math.max(0, MAX_FILES_COUNT - files.length)}
        disabled={files.length >= MAX_FILES_COUNT || disabled}
      />

      {!noFiles && <FilesList files={files} onDelete={onDelete} />}
    </div>
  );
};

export default Files;
