import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { QuestionIcon } from 'assets/images';
import { ActionIconButton } from 'components';
import LabelWithTag from '../components/LabelWithTag';

import getComputingSpeedUnit from 'utils/power/getComputingSpeedUnit';
import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

const { Text } = Typography;

const useMiningNotificationsTableColumns = ({ onEdit }) => {
  const { t } = useTranslation();
  const { isMobileView } = useWindowResponsiveness(700);

  const renderThresholds = ({ value, unit, is_active }) => {
    return (
      <LabelWithTag
        title="miningSettings.hashrateFluctuationNotification.table.field.thresholds"
        isActive={is_active}
        value={value}
        formatter={value => getComputingSpeedUnit(value, unit, { fixed: false })}
      />
    );
  };

  const renderFluctuation = ({ value, is_active }) => {
    return (
      <LabelWithTag
        title="miningSettings.hashrateFluctuationNotification.table.field.fluctuation"
        isActive={is_active}
        value={value}
        formatter={value => `${value}%`}
      />
    );
  };

  const renderActiveWorkers = ({ value, is_active }) => {
    return (
      <LabelWithTag
        title="miningSettings.hashrateFluctuationNotification.table.field.activeWorkers"
        isActive={is_active}
        value={value}
        formatter={value => `< ${value || 0}`}
      />
    );
  };

  const renderActions = record => {
    return (
      <Tooltip title={t('common.table.actions.edit')}>
        <ActionIconButton icon={<EditOutlined />} onClick={() => onEdit(record.coin_type)} />
      </Tooltip>
    );
  };

  const actionsColumn = [
    {
      title: t('miningSettings.hashrateFluctuationNotification.table.column.actions'),
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: isMobileView ? 60 : 80,
      render: (_, record) => renderActions(record),
    },
  ];

  return [
    {
      title: t('miningSettings.hashrateFluctuationNotification.table.column.coinType'),
      dataIndex: 'coin_type',
      key: 'coin_type',
      align: 'center',
      width: isMobileView ? 80 : 100,
      render: coinType => <Text className="np-mining-notifications-table__text--no-wrap">{coinType}</Text>,
    },
    {
      title: t('miningSettings.hashrateFluctuationNotification.table.column.thresholds'),
      dataIndex: 'thresholds',
      key: 'thresholds',
      align: 'center',
      width: isMobileView ? 150 : 230,
      render: renderThresholds,
    },
    {
      title: () => (
        <div className="np-mining-notifications-table__tooltip-title">
          <span>{t('miningSettings.hashrateFluctuationNotification.table.column.fluctation')}</span>

          <Tooltip title={t('miningSettings.hashrateFluctuationNotification.tooltip.fluct')}>
            <QuestionIcon className="np-mining-notifications-table__tooltip" />
          </Tooltip>
        </div>
      ),
      dataIndex: 'fluctuation',
      key: 'fluctuation',
      align: 'center',
      width: isMobileView ? 150 : 230,
      render: renderFluctuation,
    },
    {
      title: t('miningSettings.hashrateFluctuationNotification.table.column.activeWorkers'),
      dataIndex: 'active_workers',
      key: 'active_workers',
      align: 'center',
      width: isMobileView ? 150 : 230,
      render: renderActiveWorkers,
    },

    ...(onEdit ? actionsColumn : []),
  ];
};

export default useMiningNotificationsTableColumns;
