import React from 'react';

import { SummaryBlock } from 'components';

import useAccountSummaryItems from './hooks/useAccountSummaryItems';

const MobileAccountsSummary = ({ data, loading }) => {
  const items = useAccountSummaryItems(data);

  return <SummaryBlock noBottomLine titleThin title="accountsSummary.title" items={items} loading={loading} />;
};

export default MobileAccountsSummary;
