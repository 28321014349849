import React from 'react';

import { BorderedBlock } from 'components';

import useSummaryItems from './hooks/useSummaryItems';
import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

const DesktopNetworkSummary = ({ data, loading }) => {
  const items = useSummaryItems(data);

  const { isMobileView } = useWindowResponsiveness(1400);

  return <BorderedBlock title={isMobileView ? 'networkSummary.title' : null} items={items} loading={loading} />;
};

export default DesktopNetworkSummary;
