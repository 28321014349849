import { ErrorCode } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { MAX_FILES_COUNT, MAX_FILE_SIZE_MB, MIN_FILE_SIZE } from '../../../constants/files';

const useErrorMessage = () => {
  const { t } = useTranslation();

  return {
    [ErrorCode.TooManyFiles]: t('notification.error.files.tooManyFiles', { value: MAX_FILES_COUNT }),
    [ErrorCode.FileTooSmall]: t('notification.error.files.fileTooSmall', { value: MIN_FILE_SIZE }),
    [ErrorCode.FileTooLarge]: t('notification.error.files.fileTooLarge', { value: MAX_FILE_SIZE_MB }),
    [ErrorCode.FileInvalidType]: t('notification.error.files.fileInvalidType'),
  };
};

export default useErrorMessage;
