import * as echarts from 'echarts';
import { useTranslation } from 'react-i18next';

import useTheme from 'hooks/common/useTheme';
import convertNumber from 'utils/common/convertNumber';
import formatDate from 'utils/date/formatDate';
import { serializeData } from '../utils/serializer';
import getComputingSpeedUnit from 'utils/power/getComputingSpeedUnit';

import { keyMap } from '../constants/chart';
import { DATE_DOT_FORMAT_WITH_TIME } from 'constants/date';
import { DefaultColors, PRIMARY_COLOR } from 'constants/colors';
import { GranularityIntervalLabels } from '../constants/granularity';
import getPositionInside from 'utils/charts/getPositionInside';

const DEFAULT_MAX_HASHRATE = 0.2;

const chartColors = [PRIMARY_COLOR, DefaultColors.SPANISH_GRAY];

const chartAreaGradientColors = [
  { from: 'rgba(24, 156, 216, 0.24)', to: 'rgba(24, 156, 216, 0.00)' },
  { from: '#949495', to: 'rgba(148, 148, 149, 0.00)' },
];

const useChartOptions = (data = {}, options = {}) => {
  const { granularity } = options;

  const { t } = useTranslation();
  const { colors } = useTheme();

  const { parsedDates, parsedSeries } = serializeData(data);

  const hashRateSeries = parsedSeries.find(({ name }) => name === keyMap.HASH_RATE);

  const chartKeysMap = {
    [keyMap.HASH_RATE]: {
      name: keyMap.HASH_RATE,
      title: t('hashrateChart.legend.hashRate'),
      tooltip: {
        valueFormatter: value => getComputingSpeedUnit(value, hashRateSeries.power),
      },
    },

    [keyMap.REJECT_RATE]: {
      name: keyMap.REJECT_RATE,
      title: t('hashrateChart.legend.rejectRate'),

      tooltip: {
        valueFormatter: value => `${value} %`,
      },
    },
  };

  const getHashRateIntervals = hashRateSeries => {
    const roundNearest5 = num => Math.ceil(num / 5) * 5;

    let maxValue = 0;

    hashRateSeries.forEach(point => {
      if (point > maxValue) {
        maxValue = point;
      }
    });

    const getMaxInterval = () => {
      if (maxValue === 0) {
        return DEFAULT_MAX_HASHRATE;
      }

      let value = maxValue;
      let divider = 1;
      let maxInterval;

      while (!maxInterval) {
        if (value > 10) {
          maxInterval = roundNearest5(value) / divider;
          break;
        } else {
          divider = divider * 10;
          value = value * 10;
        }
      }

      return maxInterval;
    };

    const max = getMaxInterval();
    const interval = max / 5;

    return {
      max: convertNumber(max, 4),
      interval: convertNumber(interval, 4),
    };
  };

  return {
    title: {
      text: ' ',
      subtext: t(GranularityIntervalLabels[granularity]),
      left: 'center',
      subtextStyle: {
        color: colors.MAIN_TEXT_COLOR,
        fontSize: 14,
      },
    },
    tooltip: {
      trigger: 'axis',

      formatter: params => {
        const getTitle = name => t(`hashrateChart.tooltip.${name}`);
        const newLineTag = index => (index ? '</br>' : '');

        return `
          <div class="np-hashrate-chart__tooltip">
            <span class="np-hashrate-chart__tooltip-title">${params[0].axisValueLabel} (GMT)</span>

            ${params.map((param, index) => {
              return `${newLineTag(index)}<div class="np-hashrate-chart__tooltip-item">
                <div>
                  ${param.marker}
                  <span>${getTitle(param.seriesName)}</span>
                </div>

                <div style="font-weight: 500">${chartKeysMap[param.seriesName].tooltip.valueFormatter(
                  param.value,
                )}</div>
              </div>`;
            })}
          </div>
        `;
      },

      position: function (pos, params, dom, rect, size) {
        return getPositionInside(pos, size.contentSize, size.viewSize);
      },
    },

    legend: {
      data: Object.values(chartKeysMap).map(e => e.name),

      formatter: function (name) {
        return t(`hashrateChart.legend.${name}`);
      },
    },

    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },

    yAxis: [
      {
        type: 'value',
        axisLabel: { formatter: value => getComputingSpeedUnit(value, hashRateSeries.power) },

        ...getHashRateIntervals(hashRateSeries?.data || []),
      },
      {
        type: 'value',
        min: 0,
        max: 100,
        interval: 20,
        axisLabel: { formatter: '{value} %' },
        splitLine: {
          show: true,
          lineStyle: {
            color: colors.CHART_DIVIDE_LINE_COLOR,
          },
        },
      },
    ],

    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: colors.CHART_DIVIDE_LINE_COLOR_2,
          },
        },

        axisLine: {
          show: false,
        },

        data: parsedDates.map(point => formatDate(point / 1000, { format: DATE_DOT_FORMAT_WITH_TIME })),
      },
    ],

    series: parsedSeries.map(({ data, name }, index) => ({
      name: chartKeysMap[name]?.title,
      type: 'line',
      smooth: true,
      yAxisIndex: index,
      emphasis: { focus: 'series' },
      data: data,
      lineStyle: { color: chartColors[index] },
      itemStyle: { color: chartColors[index] },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 0.8, [
          {
            offset: 0,
            color: chartAreaGradientColors[index].from,
          },
          {
            offset: 1,
            color: chartAreaGradientColors[index].to,
          },
        ]),
      },
      ...chartKeysMap[name],
    })),
  };
};

export default useChartOptions;
