import React from 'react';

import { MainContainer, SocialMediaButton, Logo } from 'components';

import { MediaButtonSize, MediaTypes } from 'components/buttons/SocialMediaButton';

import './Footer.styles.scss';

const Footer = () => {
  return (
    <MainContainer className="np-footer">
      <div className="np-footer__about">
        <Logo />

        {/* <p>{t('footer.desc')}</p> */}

        <ul>
          <SocialMediaButton type={MediaTypes.TELEGRAM} size={MediaButtonSize.LARGE} />
          <SocialMediaButton type={MediaTypes.TWITTER} size={MediaButtonSize.LARGE} />
          <SocialMediaButton type={MediaTypes.INSTAGRAM} size={MediaButtonSize.LARGE} />
        </ul>
      </div>

      {/* <div className="np-footer__map">
        <div className="np-footer-map__list">
          <h6>{t('footer.product.title')}</h6>

          <ul>
            <li>{t('footer.product.app')}</li>
            <li>{t('footer.product.community')}</li>
          </ul>
        </div>

        <div className="np-footer-map__list">
          <h6>{t('footer.support.title')}</h6>

          <ul>
            <li>{t('footer.support.link1')}</li>
            <li>{t('footer.support.link2')}</li>
            <li>{t('footer.support.link3')}</li>
          </ul>
        </div>

        <div className="np-footer-map__list">
          <h6>{t('footer.patterns.title')}</h6>

          <ul>
            <li>{t('footer.patterns.link1')}</li>
            <li>{t('footer.patterns.link2')}</li>
            <li>{t('footer.patterns.link3')}</li>
            <li>{t('footer.patterns.link4')}</li>
          </ul>
        </div>
      </div> */}
    </MainContainer>
  );
};

export default Footer;
