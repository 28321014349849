import React from 'react';

import { Sidebar } from 'components';

import './SidebarLayout.styles.scss';

const SidebarLayout = ({ children, active, logoUrl, ...props }) => {
  return (
    <div className="np-sidebar-layout">
      <div className="np-sidebar-layout__menu">
        <Sidebar active={active} logoUrl={logoUrl} menuProps={props} />
      </div>

      <div className="np-sidebar-layout__content">{children}</div>
    </div>
  );
};

export default SidebarLayout;
