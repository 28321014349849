const DEFAULT_PADDING_Y = 30;

const getPositionInside = (position, contentSize, viewSize, yPadding = DEFAULT_PADDING_Y) => {
  const [x, y] = position;
  const [viewSizeX, viewSizeY] = viewSize;
  const [contentSizeX, contentSizeY] = contentSize;

  const isTooltipInsideX = viewSizeX < x + contentSizeX;
  const isTooltipInsideY = viewSizeY - yPadding < y + contentSizeY;

  const newPosX = Math.max(0, x - contentSizeX);
  const newPosY = Math.max(0, viewSizeY - contentSizeY - yPadding);

  const newX = isTooltipInsideX ? newPosX : x;
  const newY = isTooltipInsideY ? newPosY : y;

  return [newX, newY];
};

export default getPositionInside;
