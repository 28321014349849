export const SortOrder = {
  ASC: 'asc',
  DESC: 'desc',
};

export const AntSortOrder = {
  ASC: 'ascend',
  DESC: 'descend',
};

export const SortOrderMap = {
  [AntSortOrder.ASC]: SortOrder.ASC,
  [AntSortOrder.DESC]: SortOrder.DESC,
};
