import React from 'react';

import { TableWithHeader } from 'components';

import useMyReferredUsersColumns from './hooks/useMyReferredUsersColumns';

import './ReferredUsersTable.styles.scss';

const ReferredUsersTable = ({ className = '', ...props }) => {
  const columns = useMyReferredUsersColumns();

  return (
    <TableWithHeader
      title="referralRewards.table.myReferredUsers.title"
      columns={columns}
      rowKey={record => record.account_name}
      className={`np-referred-users-table ${className}`}
      {...props}
    />
  );
};

export default ReferredUsersTable;
