import React from 'react';
import GoogleCaptcha from 'react-google-recaptcha';
import { useSelector } from 'react-redux';

import useTheme from 'hooks/common/useTheme';

import { captchaKeyV2 } from 'configs';

const Captcha = props => {
  const { theme } = useTheme();
  const { language } = useSelector(state => state.language);

  return <GoogleCaptcha sitekey={captchaKeyV2} hl={language} theme={theme} {...props} />;
};

export default Captcha;
