import React from 'react';
import { BlockWrapper } from 'components';

import './SettingsBlock.styles.scss';

const SettingsBlock = ({ children, noPadding, noBorderRadius, className }) => (
  <BlockWrapper noPadding={noPadding} noBorderRadius={noBorderRadius} className={className}>
    {children}
  </BlockWrapper>
);

SettingsBlock.Header = ({ children }) => <div className="np-settings-block__header">{children}</div>;

SettingsBlock.Title = ({ children, className }) => (
  <BlockWrapper.Title size="large" className={className}>
    {children}
  </BlockWrapper.Title>
);

SettingsBlock.Body = ({ children }) => <div className="np-settings-block__body">{children}</div>;

SettingsBlock.Row = ({ children, noPadding, className = '' }) => (
  <div
    className={`np-settings-block-body__row ${noPadding ? 'np-settings-block-body__row--no-padding' : ''} ${className}`}
  >
    {children}
  </div>
);

// SettingsBlock.Row = ({ children }) => <div className="np-settings-block-body__row">{children}</div>;

SettingsBlock.BodyTitle = ({ children, className }) => (
  <p className={`np-settings-block-body__title ${className}`}>{children}</p>
);

SettingsBlock.BodyValue = ({ children, fullWidth, className }) => (
  <div
    className={`np-settings-block-body__value ${
      fullWidth ? 'np-settings-block-body__value--full-width' : ''
    } ${className}`}
  >
    {children}
  </div>
);

SettingsBlock.Actions = ({ children }) => <div className="np-settings-block__actions">{children}</div>;

export default SettingsBlock;
