import React from 'react';
import { SortAscIcon, SortDescIcon } from 'assets/images';

import { AntSortOrder } from 'constants/table';

import './SortTitle.styles.scss';

const SortTitle = ({ title, order, align }) => {
  const alignClass = align ? `np-table__sort-title--${align}` : '';

  return (
    <div className={`np-table__sort-title ${alignClass}`}>
      {title}

      {order === AntSortOrder.DESC ? (
        <SortDescIcon />
      ) : (
        <SortAscIcon className={order ? '' : 'np-table__sort-title--ghost'} />
      )}
    </div>
  );
};

export default SortTitle;
