import React from 'react';
import { Carousel } from 'components';

import BlockchainLifeDubaiBanner from './components/BlockchainLifeDubaiBanner';

const BannerCarousel = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Carousel {...settings}>
      <BlockchainLifeDubaiBanner />
      <BlockchainLifeDubaiBanner />
    </Carousel>
  );
};

export default BannerCarousel;
