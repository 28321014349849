export const MediaButtonColor = {
  PRIMARY: 'primary',
  DARK_BLUE: 'dark-blue',
  GRAY: 'gray',
};

export const MediaButtonTextColor = {
  DEFAULT: 'default',
  WHITE: 'white',
};
