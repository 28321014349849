import React from 'react';
import { useTranslation } from 'react-i18next';
import formatDate from 'utils/date/formatDate';
import { DATE_DOT_FORMAT_WITH_TIME } from 'constants/date';

const useMiningServersTableColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('accountSettings.signInHistory.headers.singInTime'),
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      width: 200,
      render: value => <div>{formatDate(value, { format: DATE_DOT_FORMAT_WITH_TIME })}</div>,
    },
    {
      title: t('accountSettings.signInHistory.headers.ip'),
      dataIndex: 'ip',
      key: 'ip',
      align: 'center',
      width: 100,
      ellipsis: true,
      render: value => <div>{value}</div>,
    },

    {
      title: t('accountSettings.signInHistory.headers.browser'),
      dataIndex: 'browser',
      key: 'browser',
      align: 'center',
      width: 120,
      render: value => <div>{value}</div>,
    },
    {
      title: t('accountSettings.signInHistory.headers.device'),
      dataIndex: 'device',
      key: 'device',
      align: 'center',
      width: 100,
      render: value => <div>{value}</div>,
    },
    {
      title: t('accountSettings.signInHistory.headers.status.title'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 80,
      render: value => <div>{value ? t(`accountSettings.signInHistory.headers.status.${value}`) : ''}</div>,
    },
  ];
};

export default useMiningServersTableColumns;
