import React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'components';

import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import { SelectVariant } from 'components/selects/Select';

import './FilterSelect.styles.scss';

const FilterSelect = ({ size, width, minWidth, style, placeholder, className = '', ...props }) => {
  const withAllOptionClass = !placeholder ? 'np-filter-select--with-all' : '';

  const { t } = useTranslation();
  const { isMobileView } = useWindowResponsiveness(700);

  return (
    <Select
      allowClear
      width={width}
      size={size ? size : isMobileView ? 'middle' : 'large'}
      style={{ minWidth, ...style }}
      placeholder={t(placeholder || 'common.all')}
      variant={SelectVariant.BORDERED}
      className={`np-filter-select ${withAllOptionClass} ${className}`}
      {...props}
    />
  );
};

export default FilterSelect;
