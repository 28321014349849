import fixStrNumber from 'utils/common/fixStrNumber';

const isNumber = (value, options) => {
  const { withComma } = options || {};

  const num = typeof value === 'string' && withComma ? +fixStrNumber(value) : value;

  return typeof num === 'number';
};

export default isNumber;
