import React, { useEffect } from 'react';
import { ConfigProvider, App as AntdApp } from 'antd';
import { useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Routes from 'routes/Routes';

import useTheme from 'hooks/common/useTheme';
import useLocalStorageField from 'hooks/common/useLocalStorageField';

import accessTokenChangedCallback from 'utils/auth/accessTokenChangedCallback';

import { AntLanguage } from 'constants/languages';
import { StorageKey } from 'constants/localStorage';

import 'assets/styles/common.scss';

const queryClient = new QueryClient();

function App() {
  const { language } = useSelector(state => state.language);
  const { themeConfig, initTheme } = useTheme();

  useEffect(() => {
    initTheme();
  }, [initTheme]);

  useLocalStorageField(StorageKey.ACCESS_TOKEN, accessTokenChangedCallback);

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={themeConfig} locale={AntLanguage[language]}>
        <AntdApp>
          <Routes />
        </AntdApp>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
