import { useTranslation } from 'react-i18next';

import getPowerLabel from 'utils/power/getPowerLabel';
import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';
import getComputingSpeedUnit from 'utils/power/getComputingSpeedUnit';

const useSummaryItems = data => {
  const { t } = useTranslation();

  const { isMobileView } = useWindowResponsiveness(1400);

  const { difficulty, price, networkHashrate } = data || {};

  return [
    {
      key: '1',
      title: !isMobileView ? t('networkSummary.bitcoinPrice') : null,
      tooltip: isMobileView ? t('networkSummary.bitcoinPrice') : null,
      value: price ? `$${price}` : null,
    },
    {
      key: '2',
      title: !isMobileView ? t('networkSummary.networkHashrate') : null,
      tooltip: isMobileView ? t('networkSummary.networkHashrate') : null,
      value: networkHashrate?.count ? getComputingSpeedUnit(networkHashrate?.count, networkHashrate?.desc) : null,
    },
    {
      key: '3',
      title: !isMobileView ? t('networkSummary.networkDifficulty') : null,
      tooltip: isMobileView ? t('networkSummary.networkDifficulty') : null,
      value: difficulty?.value ? `${difficulty?.value} ${getPowerLabel(difficulty?.power)}` : null,
    },
  ];
};

export default useSummaryItems;
