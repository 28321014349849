import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';

import { DefaultColors, PRIMARY_COLOR } from 'constants/colors';

const useWorkersStatus = workers => {
  const { t } = useTranslation();

  return [
    {
      label: t('menu.userProfile.subAccountsInfo.active'),
      value: workers.active,
      icon: <CheckCircleFilled style={{ color: PRIMARY_COLOR }} />,
    },

    {
      label: t('menu.userProfile.subAccountsInfo.inactive'),
      value: workers.inactive,
      icon: <ExclamationCircleFilled style={{ color: DefaultColors.DARK_CERULEAN }} />,
    },

    {
      label: t('menu.userProfile.subAccountsInfo.offline'),
      value: workers.offline,
      icon: <CloseCircleFilled style={{ color: DefaultColors.SPANISH_GRAY }} />,
    },
  ];
};

export default useWorkersStatus;
