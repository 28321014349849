import React from 'react';

import { Loading } from 'components';

import { useCheckUserAuth } from '../utils/hooks/useCheckUserAuth';

const PublicContainer = ({ children }) => {
  const { loading } = useCheckUserAuth();

  if (loading) {
    return <Loading isLoading fullScreen />;
  }

  return children;
};

export default PublicContainer;
