export const WatcherType = {
  MAIN: 'main',
  INDEPENDENT: 'independent',
};

export const WatcherPermissions = {
  DASHBOARD: 'db',
  WORKERS: 'ws',
  EARNINGS: 'ern',
  ASSETS: 'ast',
  ADMIN: 'admin',
};

export const WatcherPermissionsLabels = {
  [WatcherPermissions.DASHBOARD]: 'watcher.permissions.dashboard',
  [WatcherPermissions.WORKERS]: 'watcher.permissions.workers',
  [WatcherPermissions.EARNINGS]: 'watcher.permissions.earnings',
  [WatcherPermissions.ASSETS]: 'watcher.permissions.assets',
};
