import convertBytesToMegaBytes from 'utils/files/convertBytesToMegaBytes';

export const MAX_FILE_SIZE = 6000000; // 6 mb

export const MAX_FILE_SIZE_MB = convertBytesToMegaBytes(MAX_FILE_SIZE, { label: 'MB' });

export const MIN_FILE_SIZE = 0;

export const MAX_FILES_COUNT = 6;

export const FileCategory = {
  IMAGE: 'image/*',
};

export const FileType = {
  [FileCategory.IMAGE]: {
    PNG: '.png',
    JPG: '.jpg',
    JPEG: '.jpeg',
    GIF: '.gif',
    HEIC: '.heic',
  },
};

export const FileTypeLabel = {
  [FileType[FileCategory.IMAGE].PNG]: 'PNG',
  [FileType[FileCategory.IMAGE].JPG]: 'JPG',
  [FileType[FileCategory.IMAGE].JPEG]: 'JPEG',
  [FileType[FileCategory.IMAGE].GIF]: 'GIF',
  [FileType[FileCategory.IMAGE].HEIC]: 'HEIC',
};

export const AcceptFileTypes = Object.values(FileType[FileCategory.IMAGE]);

export const DropzoneAcceptFiles = {
  [FileCategory.IMAGE]: AcceptFileTypes,
};
