import { useDispatch, useSelector } from 'react-redux';

import { setUser } from 'store/reducers/user';
import { getUserProfile } from 'services/user.service';
import { useState } from 'react';

const useUserData = () => {
  const { user } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const reloadUser = async () => {
    setLoading(true);

    const { data, success } = await getUserProfile();

    if (success) {
      dispatch(setUser(data));
    }

    setLoading(false);
  };

  return {
    user,
    loading,
    reloadUser,
  };
};

export default useUserData;
