import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QRCode, Space, theme, Typography } from 'antd';

import { FormButtons } from 'modules';
import { Button, Form, NoData, Loading, CopyableText, ListBlock, Input } from 'components';

import useForm from 'hooks/forms/useForm';
import useTheme from 'hooks/common/useTheme';
import useUserData from 'hooks/user/useUserData';
import useValidation from 'hooks/forms/useValidation';
import { useTotpUrlQuery } from './hooks/useTotpUrlQuery';
import useNotifications from 'hooks/common/useNotifications';
import { useEnable2FaMutation } from './hooks/useEnable2FaMutation';

import ymProtectInput from 'utils/yandex-metrika/ymProtectInput';

import { YM_DISABLE_SUBMIT_CLASS, YM_HIDE_CONTENT_CLASS } from 'constants/yandexMetrika';

import './SecurityCheckForm.styles.scss';

const { useToken } = theme;

const { Text } = Typography;

const SecurityCheckForm = ({ showGoBack, onGoBack = () => {}, onFinish = () => {}, onError = () => {} }) => {
  const { token } = useToken();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { notificationApi } = useNotifications();

  const rules = useValidation();
  const { isDarkMode } = useTheme();
  const { submitDisabled } = useForm(form);

  const { reloadUser } = useUserData();
  const { loading, data } = useTotpUrlQuery();
  const { loading: confirmLoading, refetch: enableUser2Fa } = useEnable2FaMutation();

  const [showQrCode, setShowQrCode] = useState(true);

  const { url, secret } = data || {};

  const qrCodeOptions = isDarkMode ? { color: token.colorWhite, bgColor: token.colorBgLayout } : {};

  const handleFinish = codes => {
    form.resetFields();
    onFinish(codes);
    reloadUser();
  };

  const handleError = ({ errorMessage }) => {
    onError();
    form.resetFields();
    notificationApi.error({ message: t(errorMessage) });
  };

  const handleSubmit = async ({ totp }) => {
    if (!totp) {
      return;
    }

    const { success, data, errorMessage } = await enableUser2Fa({ totp });

    if (success) {
      handleFinish(data.recovery_tokens);
    } else {
      handleError({ errorMessage });
    }
  };

  if (!url && !loading) {
    return <NoData />;
  }

  return (
    <Loading isLoading={loading}>
      <div className="np-enable-ga-security-check">
        {showQrCode ? (
          <>
            <Text className="np-enable-ga-security-check__text">
              1. {t('accountSettings.accountSecurity.form.twoFAEnable.formSteps.scanBarcode')}
            </Text>

            {url && (
              <Space size={4} align="center" direction="vertical">
                <QRCode value={url} bordered={false} {...qrCodeOptions} className={YM_HIDE_CONTENT_CLASS} />

                <Button type="link" onClick={() => setShowQrCode(false)}>
                  {t('accountSettings.accountSecurity.form.twoFAEnable.button.unableToScan')}
                </Button>
              </Space>
            )}
          </>
        ) : (
          <>
            <Text className="np-enable-ga-security-check__text">
              1. {t('accountSettings.accountSecurity.form.twoFAEnable.formSteps.enterKey')}
            </Text>

            {secret && (
              <Space size={4} align="center" direction="vertical" className="np-enable-ga-security-check__code-wrapper">
                <ListBlock empty={!secret}>
                  <CopyableText className={`np-enable-ga-security-check__key ${YM_HIDE_CONTENT_CLASS}`}>
                    {secret}
                  </CopyableText>
                </ListBlock>

                <Button type="link" onClick={() => setShowQrCode(true)}>
                  {t('accountSettings.accountSecurity.form.twoFAEnable.button.scanQrCode')}
                </Button>
              </Space>
            )}
          </>
        )}

        <Text className="np-enable-ga-security-check__text">
          2. {t('accountSettings.accountSecurity.form.twoFAEnable.formSteps.enterCode')}
        </Text>

        <Form
          className={`np-enable-ga-security-check__form ${YM_DISABLE_SUBMIT_CLASS}`}
          layout="vertical"
          form={form}
          name="register"
          onFinish={handleSubmit}
          scrollToFirstError
          requiredMark="optional"
        >
          <Form.Item name="totp" validateFirst rules={rules.ga}>
            <Input disabled={confirmLoading} autoFocus ref={ymProtectInput} />
          </Form.Item>

          <FormButtons
            showGoBack={showGoBack}
            submitDisabled={submitDisabled}
            loading={confirmLoading}
            marginTop={12}
            onGoBack={onGoBack}
            submitLabel="form.button.submit"
          />
        </Form>
      </div>
    </Loading>
  );
};

export default SecurityCheckForm;
