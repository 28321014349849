import 'whatwg-fetch';
import { configureRefreshFetch, fetchJSON } from './refresh-fetch';
import merge from 'lodash/merge';
import auth from './auth.service';
import { baseUrl } from '../configs';
import { apiUrls } from 'constants/urls';

const convertParamArray = (key, paramArray) => {
  let paramStr = '';
  paramArray.forEach(param => {
    paramStr = paramStr + key + '[]=' + encodeValue(param) + '&';
  });
  return paramStr;
};

const encodeValue = value => {
  if (typeof value === 'object') {
    return encodeURIComponent(JSON.stringify(value));
  }

  return encodeURIComponent(value);
};

const formatQueryParams = params =>
  Object.keys(params)
    .map(k =>
      Array.isArray(params[k])
        ? convertParamArray(encodeURIComponent(k), params[k])
        : `${encodeURIComponent(k)}=${encodeValue(params[k])}`,
    )
    .join('&');

const fetchJSONWithToken = (url, options = {}) => {
  const token = auth.getAccessToken();

  const base = options.baseUrl || baseUrl;

  const isWatcher = options.isWatcher;
  const isJson = options.isJson ?? true;
  let optionsWithToken = { ...options };

  if (token != null && !isWatcher) {
    optionsWithToken = merge({}, options, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  if (optionsWithToken && optionsWithToken.body && isJson) {
    optionsWithToken.body = JSON.stringify(optionsWithToken.body);
  }

  if (optionsWithToken && optionsWithToken.params) {
    const params = formatQueryParams(optionsWithToken.params);
    url = `${url}?${params}`;
  }

  return fetchJSON(base + url, optionsWithToken);
};

const shouldRefreshToken = error => {
  return error.response.status === 401;
};

const isForbidden = error => {
  return error.response.status === 403;
};

const refreshToken = async () => {
  const currentRefreshToken = auth.getRefreshToken();

  auth.clearTokens();

  return fetchJSONWithToken(apiUrls.auth.refresh, {
    method: 'POST',
    body: { refresh_token: currentRefreshToken },
  })
    .then(response => {
      auth.setAccessToken(response.access_token, { disableReload: true });
      auth.setRefreshToken(response.refresh_token);
    })
    .catch(error => {
      // Clear token and continue with the Promise catch chain
      auth.clearTokens();
      throw error;
    });
};

const request = configureRefreshFetch({
  fetch: fetchJSONWithToken,
  shouldRefreshToken,
  refreshToken,
  isForbidden,
});

export default request;
