import React from 'react';
import ReactCodeInput from 'react-code-input';

import './CodeInput.styles.scss';

const CodeInput = React.forwardRef(({ className = '', isValid, ...props }, ref) => {
  const hasErrors = !isValid || props['aria-invalid'];

  return <ReactCodeInput ref={ref} isValid={!hasErrors} className={`np-code-input ${className}`} {...props} />;
});

export default CodeInput;
