import React from 'react';
import { Typography } from 'antd';
import { CopyIcon } from 'assets/images';
import { CheckOutlined } from '@ant-design/icons';

import './CopyableText.styles.scss';

const { Text } = Typography;

const CopyableText = ({ children, className, ellipsis, ...props }) => {
  return (
    <Text
      copyable={{
        icon: [
          <CopyIcon key="1" className="np-copyable-text__icon" />,
          <CheckOutlined key="2" className="np-copyable-text__icon" />,
        ],
      }}
      className={`np-copyable-text ${className}`}
      title={ellipsis ? children : null}
      ellipsis={ellipsis}
      {...props}
    >
      {children}
    </Text>
  );
};

export default CopyableText;
