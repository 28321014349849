import React from 'react';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';

import { WatcherPermissionsLabels } from 'constants/watcher';

import './PermissionsPopover.styles.scss';

const Content = ({ permissions = [] }) => {
  const { t } = useTranslation();

  return (
    <div className="np-permissions-popover__content">
      {permissions &&
        permissions.map(permission => (
          <div key={permission} className="np-permissions-popover__content-item">
            <CheckOutlined />
            <span>{t(WatcherPermissionsLabels[permission])}</span>
          </div>
        ))}
    </div>
  );
};

const PermissionsPopover = ({ children, permissions = [] }) => {
  const { t } = useTranslation();

  return (
    <Popover
      content={<Content permissions={permissions} />}
      title={<span className="np-permissions-popover__title">{t('watcher.mySharing.modal.permissions.title')}</span>}
      trigger="hover"
    >
      {children}
    </Popover>
  );
};

export default PermissionsPopover;
