import React, { useMemo } from 'react';
import { Button } from 'antd';

import { ActionIconColors } from './constants/colors';

import './ActionIconButton.styles.scss';

const ActionIconButton = ({ icon, color = ActionIconColors.PRIMARY, className, ...props }) => {
  const classPrefix = 'np-action-icon-button';

  const colorClass = useMemo(() => {
    if (color === ActionIconColors.PRIMARY) {
      return `${classPrefix}--primary`;
    }

    if (color === ActionIconColors.RED) {
      return `${classPrefix}--red`;
    }

    if (color === ActionIconColors.GRAY) {
      return `${classPrefix}--gray`;
    }

    return '';
  }, [color]);

  return (
    <Button
      shape="circle"
      type="text"
      size="small"
      icon={icon}
      className={`${classPrefix} ${colorClass} ${className}`}
      {...props}
    />
  );
};

export default ActionIconButton;
