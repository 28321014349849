import React from 'react';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { MinusOutlined } from '@ant-design/icons';

import isBiggerDate from 'utils/date/isBiggerDate';
import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import { DateType } from './constants/types';
import { DATE_DOT_FORMAT, DATE_DOT_FORMAT_2 } from 'constants/date';

import './DateFilter.styles.scss';

const { RangePicker } = DatePicker;

const DateFilter = ({ value = {}, size, onChange = () => {}, className = '', ...props }) => {
  const { t } = useTranslation();
  const { isMobileView } = useWindowResponsiveness(700);

  const endDate = value.endDate ? dayjs(value.endDate) : null;
  const startDate = value.startDate ? dayjs(value.startDate) : null;

  const disabledDate = current => {
    return isBiggerDate(current, dayjs());
  };

  const onChangeDate = dates => {
    const [startDate, endDate] = dates || [];

    const startDateValue = startDate ? startDate.format('MM/DD/YYYY') : null;
    const endDateValue = endDate ? endDate.format('MM/DD/YYYY') : null;

    onChange([DateType.START_DATE, startDateValue, startDate], [DateType.END_DATE, endDateValue, endDate]);
  };

  return (
    <div className={`np-date-filter ${className}`} {...props}>
      <RangePicker
        size={size ? size : isMobileView ? 'middle' : 'large'}
        disabledDate={disabledDate}
        placeholder={[t('common.from'), t('common.to')]}
        locale={DATE_DOT_FORMAT_2}
        format={DATE_DOT_FORMAT}
        className="bordered"
        style={{ minWidth: 250 }}
        onChange={onChangeDate}
        value={[startDate, endDate]}
        popupClassName="np-date-filter__popup"
        separator={<MinusOutlined className="np-date-filter__separator" />}
      />
    </div>
  );
};

export default DateFilter;
