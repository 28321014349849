import React from 'react';

import DesktopNavigationMenu from './components/DesktopNavigationMenu';
import MobileNavigationMenu from './components/MobileNavigationMenu';

import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

const NavigationMenu = ({ mobile, desktop }) => {
  const { isMobileView } = useWindowResponsiveness(900);

  return isMobileView ? <MobileNavigationMenu {...mobile} /> : <DesktopNavigationMenu {...desktop} />;
};

export default NavigationMenu;
