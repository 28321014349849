const COMMON = {
  SERVER_IS_NOT_RESPONDING: 'requestErrors.serverIsNotResponding',
  UNAUTHORIZED: 'requestErrors.unauthorized',
  FORBIDDEN: 'requestErrors.forbidden',
  NOT_FOUND: 'requestErrors.pageNotFound',
  BAD_REQUEST: 'requestErrors.badRequest',
  UNKNOWN: 'requestErrors.common',
};
const AUTH = {
  INVALID_DATA: 'requestErrors.auth.invalidData',
  EMPTY_DATA: 'requestErrors.auth.emptyData',
};

export const STATUS_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  SERVER: 500,
};

export const ERROR_MESSAGES = {
  COMMON,
  AUTH,
};

export const MAPPING_ERROR_STATUS_CODE_AND_ERROR_DATA = {
  [STATUS_CODE.UNAUTHORIZED]: {
    message: COMMON.UNAUTHORIZED,
  },
  [STATUS_CODE.FORBIDDEN]: {
    message: COMMON.FORBIDDEN,
  },
  [STATUS_CODE.NOT_FOUND]: {
    message: COMMON.NOT_FOUND,
  },
  [STATUS_CODE.BAD_REQUEST]: {
    message: COMMON.BAD_REQUEST,
  },

  [STATUS_CODE.SERVER]: {
    message: COMMON.SERVER_IS_NOT_RESPONDING,
  },
};

export const ErrorType = {
  REQUIRED_2FA: '2fa required',
  INVALID_EMAIL: 'Invalid credentials',
  INVALID_PASSWORD: 'Invalid credentials1',
  VALIDATION_FAILED: 'Input payload validation failed',

  INVALID_CODE: 'invalidCode',

  PAYMENT_BLOCK: 'paymentBlock',
};

export const RequestErrorMessages = {
  accountNameAlreadyRegistered: 'requestErrors.accountNameAlreadyRegistered',
  emailAlreadyRegistered: 'requestErrors.emailAlreadyRegistered',
  invalidParameterValue: 'requestErrors.invalidParameterValue',
  captcha1Failed: 'requestErrors.captcha1Failed',
  captcha2Failed: 'requestErrors.captcha2Failed',
  incorrectTOTP: 'requestErrors.incorrectTOTP',
  incorrectPassword: 'requestErrors.incorrectPassword',
  incorrectExistingPassword: 'requestErrors.incorrectExistingPassword',
  userNotFound: 'requestErrors.userNotFound',
  forbidden: 'requestErrors.forbidden',
  serverIsNotResponding: 'requestErrors.serverIsNotResponding',
  unauthorized: 'requestErrors.unauthorized',
  invalidCredentials: 'requestErrors.invalidCredentials',
  InvalidCredentials: 'requestErrors.invalidCredentials',
  TOTPIsMissing: 'requestErrors.invalidCredentials',
  invalidCode: 'requestErrors.invalidCode',
  paymentBlock: 'requestErrors.payment.block',
  'Too many requests': 'requestErrors.tooManyRequests',

  invalidAccount: 'requestErrors.auth.unauthorized',
};
