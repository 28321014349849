import React, { useMemo } from 'react';

import { MainContainer } from 'components';
import NotificationsGroup from './components/NotificationsGroup';

import { groupByPriority } from './utils/group';
import useInternalNotifications from './hooks/useInternalNotifications';

import './InternalNotifications.styles.scss';

const InternalNotifications = ({ disabledTypes = [] }) => {
  const { notifications, onDelete } = useInternalNotifications(disabledTypes);

  const isEmpty = !notifications || !notifications.length;

  const groupedNotifications = useMemo(() => groupByPriority(notifications), [notifications]);

  return (
    !isEmpty && (
      <MainContainer style={{ paddingTop: 0 }}>
        <div className="np-internal-notifications">
          {groupedNotifications
            .map(notifications => <NotificationsGroup key={Math.random()} data={notifications} onDelete={onDelete} />)
            .filter(Boolean)}
        </div>
      </MainContainer>
    )
  );
};

export default InternalNotifications;
