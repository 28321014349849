import React, { useMemo } from 'react';
import { Select as AntdSelect } from 'antd';

import { SelectVariant } from './constants/select';

import './Select.styles.scss';

const Select = React.forwardRef(
  ({ width, style, variant = SelectVariant.FILLED, size = 'large', className = '', ...props }, ref) => {
    const variantClass = useMemo(() => {
      if (variant === SelectVariant.FILLED) {
        return 'np-select__filled';
      }

      if (variant === SelectVariant.BORDERED) {
        return 'np-select__bordered';
      }
    }, [variant]);

    return (
      <AntdSelect
        ref={ref}
        size={size}
        style={{ width, ...style }}
        className={`np-select ${variantClass} ${className}`}
        {...props}
      />
    );
  },
);

export default Select;
