import React, { useState } from 'react';
import { Space } from 'antd';
import { CloseCircleFilled, SearchOutlined } from '@ant-design/icons';

import { Button, Input } from 'components';

import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import { InputVariant } from 'components/inputs/Input';

import './SearchInput.styles.scss';

const SearchInput = ({ onSearch = () => {}, onReset = () => {}, disabled, resetDisabled, ...props }) => {
  const { isMobileView } = useWindowResponsiveness(900);

  const [value, setValue] = useState('');

  const onChange = event => {
    setValue(event.target.value);
  };

  const handleSearch = () => {
    onSearch(value);
  };

  const handleReset = () => {
    onReset();
    setValue('');
  };

  return (
    <Input
      size={isMobileView ? 'small' : 'middle'}
      disabled={disabled}
      value={value}
      onChange={onChange}
      variant={InputVariant.BORDERED}
      className="np-search-input"
      suffix={
        <Space>
          <Button
            icon={<CloseCircleFilled />}
            disabled={resetDisabled}
            shape="round"
            type="default"
            onClick={handleReset}
            className="np-search-input__button np-search-input__button-reset"
          />

          <Button
            icon={<SearchOutlined />}
            disabled={disabled}
            shape="round"
            type="default"
            onClick={handleSearch}
            className="np-search-input__button"
          />
        </Space>
      }
      {...props}
    />
  );
};

export default SearchInput;
