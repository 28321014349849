import { apiUrls } from 'constants/urls';
import asyncErrorHandler from 'utils/requests/errorHandler';
import filterEmptyParams from 'utils/params/filterEmptyParams';
import request from './request.service';

export const getUserProfile = async () => {
  const callback = async () => {
    return await request(apiUrls.auth.currentUser);
  };

  return await asyncErrorHandler(callback);
};

export const getUserAccounts = async () => {
  const callback = async () => {
    return await request(apiUrls.accounts.all);
  };

  return await asyncErrorHandler(callback);
};

export const resetPassword = async body => {
  const callback = async () => {
    return await request(apiUrls.auth.resetPassword, {
      method: 'POST',
      body,
    });
  };

  return await asyncErrorHandler(callback);
};

export const changeUserEmail = async ({ email, totp, ...params }) => {
  const callback = async () => {
    return await request(apiUrls.user.changeEmail, {
      method: 'POST',
      body: filterEmptyParams({ new_email: email, otp_2fa: totp, ...params }),
    });
  };

  return await asyncErrorHandler(callback);
};

export const sendUserEmailCode = async ({ email }) => {
  const callback = async () => {
    return await request(apiUrls.user.emailSendCode, {
      method: 'POST',
      body: filterEmptyParams({ new_email: email }),
    });
  };

  return await asyncErrorHandler(callback);
};

export const confirmUserEmailCode = async ({ email, totp, ...params }) => {
  const callback = async () => {
    return await request(apiUrls.user.emailConfirmCode, {
      method: 'POST',
      body: filterEmptyParams({ new_email: email, otp_2fa: totp, ...params }),
    });
  };

  return await asyncErrorHandler(callback);
};

export const changeUserMobilePhone = async ({ phone }) => {
  const callback = async () => {
    return await request(apiUrls.user.changePhone, {
      method: 'POST',
      body: { mobile_number: phone },
    });
  };

  return await asyncErrorHandler(callback);
};

export const checkNewPasswordToken = async body => {
  const callback = async () => {
    return await request(apiUrls.auth.resetConfirm, {
      method: 'POST',
      body,
    });
  };

  return await asyncErrorHandler(callback);
};

export const changePassword = async body => {
  const callback = async () => {
    return await request(apiUrls.auth.passwordChange, {
      method: 'POST',
      body,
    });
  };

  return await asyncErrorHandler(callback);
};

export const updatePassword = async ({ password, newPassword, totp }) => {
  const callback = async () => {
    return await request(apiUrls.user.changePassword, {
      method: 'POST',
      body: filterEmptyParams({
        password,
        new_password: newPassword,
        otp_2fa: totp,
      }),
    });
  };

  return await asyncErrorHandler(callback, {
    errors: {
      incorrectTOTP: 'requestErrors.incorrectTOTP',
      incorrectExistingPassword: 'requestErrors.incorrectExistingPassword',
    },
  });
};

export const login = async body => {
  const callback = async () => {
    return await request(apiUrls.auth.login, {
      method: 'POST',
      body,
    });
  };

  return await asyncErrorHandler(callback);
};

export const checkRegistrationToken = async body => {
  const callback = async () => {
    return await request(apiUrls.auth.emailConfirm, {
      method: 'POST',
      body,
    });
  };

  return await asyncErrorHandler(callback);
};

export const getSuggestedUsernames = async body => {
  const callback = async () => {
    return await request(apiUrls.user.suggestNames, {
      method: 'POST',
      body,
    });
  };

  return await asyncErrorHandler(callback);
};

export const sendRegistrationRequest = async body => {
  const callback = async () => {
    return await request(apiUrls.auth.registration, {
      method: 'POST',
      body: filterEmptyParams(body),
    });
  };

  return await asyncErrorHandler(callback);
};

export const registerUser = async body => {
  const callback = async () => {
    return await request(apiUrls.auth.addUser, {
      method: 'POST',
      body,
    });
  };

  return await asyncErrorHandler(callback, {
    errors: {
      accountNameAlreadyRegistered: 'requestErrors.userNameAlreadyRegistered',
    },
  });
};

export const disable2Fa = async body => {
  const callback = async () => {
    return await request(apiUrls.user.disable2Fa, { method: 'POST', body: filterEmptyParams(body) });
  };

  return await asyncErrorHandler(callback, { errorKey: 'message' });
};

export const enable2Fa = async body => {
  const callback = async () => {
    return await request(apiUrls.user['2fa'], { method: 'POST', body });
  };

  return await asyncErrorHandler(callback);
};

export const get2FaUrl = async () => {
  const callback = async () => {
    return await request(apiUrls.user['2fa']);
  };

  return await asyncErrorHandler(callback);
};

export const getSignInHistory = async ({ page, limit }) => {
  const callback = async () => {
    return await request(apiUrls.statistics.logInHistory, {
      params: filterEmptyParams({ page, per_page: limit }),
    });
  };

  return await asyncErrorHandler(callback);
};

export const sendVipApplication = async body => {
  const callback = async () => {
    return await request(apiUrls.vipApplication, { method: 'POST', body });
  };

  return await asyncErrorHandler(callback);
};

export const sendBetaTestApplication = async body => {
  const callback = async () => {
    return await request(apiUrls.betaTestApplication, { method: 'POST', body });
  };

  return await asyncErrorHandler(callback);
};
