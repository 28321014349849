export const Power = {
  DECA: 1,
  HECTO: 2,
  KILO: 3,
  MEGA: 6,
  GIGA: 9,
  TERA: 12,
  PETA: 15,
  EXA: 18,
  ZETTA: 21,
  YOTTA: 24,
  RONNA: 27,
  QUETTA: 30,
};

export const SATOSHI_POWER = 8;

export const SATOSHI = 0.00000001;
