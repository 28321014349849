import React, { useEffect, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

import useNotifications from 'hooks/common/useNotifications';

import { CopyIcon } from 'assets/images';

import './CopyButton.styles.scss';

const COPIED_MS = 3000;

const CopyButton = ({ text, copied: userCopied, size = 16, onChange = () => {} }) => {
  const { t } = useTranslation();
  const { notificationApi } = useNotifications();

  const [copied, setCopied] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const tooltipText = copied ? t('tooltip.copied') : t('tooltip.copy');

  const onChangeCopied = () => {
    setCopied(true);
    onChange(true);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const id = setTimeout(() => {
      setCopied(false);
      onChange(false);
    }, COPIED_MS);

    setTimeoutId(id);
  };

  const onCopy = async e => {
    e.stopPropagation();

    try {
      if (!text) {
        return;
      }

      await navigator.clipboard.writeText(text);

      onChangeCopied();
    } catch (e) {
      notificationApi.warning({ message: t('error.copy') });
    }
  };

  useEffect(() => {
    if (userCopied) {
      onChangeCopied();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCopied]);

  return (
    <Tooltip title={t(tooltipText)}>
      {copied ? (
        <CheckOutlined className="np-copy-button" style={{ width: size, height: size }} />
      ) : (
        <CopyIcon className="np-copy-button" onClick={onCopy} style={{ width: size, height: size }} />
      )}
    </Tooltip>
  );
};

export default CopyButton;
