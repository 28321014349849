import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockWrapper, Chart, Loading, RadioGroup } from 'components';

import useChartOptions from './hooks/useChartOptions';
import { useFilterOptions } from './hooks/useFilterOptions';

import { Granularity } from './constants/granularity';

import './HashrateChart.styles.scss';

const HashrateChart = ({ titleClass, title, column, accountId, onChangeColumn, requestCallback, ...props }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [granularity, setGranularity] = useState(column || Granularity.H5);

  const { t } = useTranslation();

  const filterOptions = useFilterOptions();
  const chartOptions = useChartOptions(data, { granularity });

  const handleChangeColumn = value => {
    setGranularity(value);

    if (onChangeColumn) {
      onChangeColumn(value);
    }
  };

  const getChartData = useCallback(async () => {
    setIsLoading(true);

    const result = await requestCallback({
      column: granularity,
    });

    const { data, success } = result || {};

    if (success && data?.type !== 'error') {
      setData(data);
    } else {
      setData(null);
    }

    setIsLoading(false);
  }, [granularity, requestCallback]);

  useEffect(() => {
    getChartData();
  }, [accountId, getChartData]);

  return (
    <BlockWrapper {...props}>
      <div className="np-hashrate-chart__header">
        <BlockWrapper.Title className={titleClass}>{title || t('hashrateChart.title')}</BlockWrapper.Title>

        <RadioGroup
          size="small"
          value={granularity}
          options={filterOptions}
          onChange={handleChangeColumn}
          className="np-hashrate-chart__switchers"
        />
      </div>

      <Loading isLoading={isLoading} isEmpty={!isLoading && !data}>
        {chartOptions && !isLoading ? <Chart option={chartOptions} /> : null}
      </Loading>
    </BlockWrapper>
  );
};

export default HashrateChart;
