import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseModal } from 'components';
import { AuthenticationForm } from 'modules';

import './AuthenticationModal.styles.scss';

const AuthenticationModal = ({
  open,
  includePaymentPassword,
  includeGa,
  includePassword,
  onClose = () => {},
  onFinish = () => {},
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleFinish = async values => {
    setLoading(true);

    await onFinish(values);

    setLoading(false);
  };

  return (
    <BaseModal
      title={t('modal.authentication.title')}
      open={open}
      width={400}
      onCancel={onClose}
      className="np-authentication-modal"
    >
      <AuthenticationForm
        includePaymentPassword={includePaymentPassword}
        includePassword={includePassword}
        includeGa={includeGa}
        loading={loading}
        onFinish={handleFinish}
      />
    </BaseModal>
  );
};

export default AuthenticationModal;
