import React from 'react';
import { NavLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import formatDate from 'utils/date/formatDate';
import usePaymentBlock from 'hooks/payment/usePaymentBlock';

import { publicUrls } from 'constants/urls';
import { MessageType } from '../../constants/types';
import { DATE_DOT_FORMAT_WITH_TIME } from 'constants/date';

const useNotificationMessages = () => {
  const { t } = useTranslation();
  const { unlockDate } = usePaymentBlock();

  const formatedUnlockDate = formatDate(unlockDate, { format: DATE_DOT_FORMAT_WITH_TIME, utc: false, ms: true });

  return {
    [MessageType.RESET_TWO_FA]: (
      <Trans
        i18nKey="notification.internal.reset2fa"
        components={{ Link: <NavLink to={publicUrls.profile.settings.account} /> }}
      />
    ),

    [MessageType.NO_INTERNET]: t('notification.internal.noInternet'),
    [MessageType.PAYMENT_BLOCK]: t('notification.internal.paymentBlock', {
      unlockDate: formatedUnlockDate,
      interpolation: { escapeValue: false },
    }),
  };
};

export default useNotificationMessages;
