import React from 'react';
import { useSelector } from 'react-redux';

import { SubAccountMenuItem } from 'components';

import useMiningAccounts from 'hooks/user/useMiningAccounts';

import { WatcherPermissions } from 'constants/watcher';

export const useUserMenuOptions = selectedAccountName => {
  const { watcher } = useSelector(state => state.watcherAuth);
  const { accountsList } = useSelector(state => state.watcherAccounts);

  const isWatcherAdmin = watcher?.acl?.includes(WatcherPermissions.ADMIN);
  const workersAllowed = watcher?.acl?.includes(WatcherPermissions.WORKERS) || isWatcherAdmin;

  const { filteredData: accounts, loading } = useMiningAccounts({
    isWatcher: true,
    showHidden: false,
    showSummary: false,
    refetchOnMount: !!watcher && workersAllowed,
  });

  const subAccountOptionsNoWorkers = accountsList
    .filter(({ is_hidden }) => !is_hidden)
    .map(({ account_name, id }) => ({ key: id, label: account_name }));

  const subAccountOptionsWithWorkers = accounts.map(
    ({ account_name, id, hr_1min, hr_1min_power, wks_active, wks_inactive, wks_offline, note }) => ({
      key: id,
      label: (
        <SubAccountMenuItem
          name={account_name}
          note={note}
          hr={hr_1min}
          hrPower={hr_1min_power}
          workers={{ active: wks_active, inactive: wks_inactive, offline: wks_offline }}
          selected={selectedAccountName === account_name}
          isMain={watcher?.main_account_id === id}
          isWatcher
        />
      ),
    }),
  );

  const options = subAccountOptionsWithWorkers.length ? subAccountOptionsWithWorkers : subAccountOptionsNoWorkers;

  return { options, loading };
};
