import { MessageType, NotificationType } from './types';

export const InAppNotificationItems = {
  [MessageType.NO_INTERNET]: {
    priority: 1,
    isInApp: true,
    id: MessageType.NO_INTERNET,
    msg: MessageType.NO_INTERNET,
    msg_type: NotificationType.NOT_CLOSABLE,
  },
};
