import { useTranslation } from 'react-i18next';
import { EnableGaSteps } from '../constants/steps';

const useEnableGaSteps = () => {
  const { t } = useTranslation();

  return [
    {
      key: EnableGaSteps.DOWNLOAD_APP,
      title: t('accountSettings.accountSecurity.form.twoFAEnable.steps.downloadApp'),
    },
    {
      key: EnableGaSteps.SECURITY_CHECK,
      title: t('accountSettings.accountSecurity.form.twoFAEnable.steps.securityCheck'),
    },
    {
      key: EnableGaSteps.COMPLETED,
      title: t('accountSettings.accountSecurity.form.twoFAEnable.steps.boundSuccessfulley'),
      disabled: true,
    },
  ];
};

export default useEnableGaSteps;
