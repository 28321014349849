import { DefaultPagination, PageSizeOptions } from 'constants/pagination';

export const DEFAULT_PAGINATION = {
  position: ['bottomLeft', 'topLeft'],
  showSizeChanger: true,
  pageSizeOptions: PageSizeOptions,
  defaultPageSize: DefaultPagination.PAGE_LIMIT,
};

export const DEFAULT_ROW_HEIGHT = 33;
