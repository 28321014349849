import React from 'react';
import { Button, Typography } from 'antd';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Logo from './components/Logo/Logo';

import { ButtonContent } from './constants/content';

import './DownloadAppButton.styles.scss';

const { Text } = Typography;

const DownloadAppButton = ({ type, link }) => {
  const content = ButtonContent[type];

  const { t } = useTranslation();

  return (
    <NavLink to={link} target="_blank">
      <Button type="text" className="np-download-app-button">
        <Logo type={type} className="np-download-app-button__logo" />

        <div className="np-download-app-button__content">
          <Text className="np-download-app-button__content-title">{t(content.title)}</Text>
          <Text className="np-download-app-button__content-platform">{t(content.platform)}</Text>
        </div>
      </Button>
    </NavLink>
  );
};

export default DownloadAppButton;
