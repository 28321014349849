import { apiUrls } from 'constants/urls';
import asyncErrorHandler from 'utils/requests/errorHandler';
import request from '../request.service';

export const getWatcherAccounts = async ({ accessKey }) => {
  const callback = async () => {
    return await request(apiUrls.watcher.accounts, {
      params: { key: accessKey },
      isWatcher: true,
    });
  };

  return await asyncErrorHandler(callback);
};

export const getWatcherAccountsSummary = async ({ accessKey }) => {
  const callback = async () => {
    return await request(apiUrls.watcher.accountsSummary, {
      params: { key: accessKey },
      isWatcher: true,
    });
  };

  return await asyncErrorHandler(callback);
};
