import React from 'react';
import Spin from '../Spin';

import './PageLoader.styles.scss';

const PageLoader = ({ className = '', ...props }) => {
  return (
    <div className={`np-page-loader__overlay ${className}`} {...props}>
      <Spin size="large" />
    </div>
  );
};

export default PageLoader;
