import React from 'react';
import { useTranslation } from 'react-i18next';

import { BlockWrapper } from 'components';
import ReferralInfoBlock from './components/ReferralInfoBlock';

import { useOverallReferralOptions } from './hooks/useOverallReferralOptions';

import './ReferralInfo.styles.scss';

const ReferralInfo = ({ data, loading }) => {
  const { t } = useTranslation();

  const options = useOverallReferralOptions(data);

  return (
    <BlockWrapper loading={loading} className="np-referral-info">
      <BlockWrapper.Title size="large">{t('referralRewards.overallReferral.title')}</BlockWrapper.Title>

      <div className="np-referral-info__info">
        {options.map(option => (
          <ReferralInfoBlock key={option.title} title={option.title} value={option.value} />
        ))}
      </div>
    </BlockWrapper>
  );
};

export default ReferralInfo;
