export const PaymentAmount = {
  ONE: 1,
  ONE_TENTH: 0.1,
  ONE_HUNDREDTH: 0.01,
  ONE_THOUSANDTH: 0.001,
};

export const MIN_PAYMENT_AMOUNT = 0.001;
export const AUTO_WITHDRAWAL_TIME = '7:00-15:00';
export const AUTO_INTERNAL_TRANSFER_TIME = '15:00-16:00';

export const PaymentTransfer = {
  INTER_USER_TRANSFER: 'inter-user-transfer',
  NORMAL_TRANSFER: 'normal-transfer',
};
