import React, { useEffect, useState } from 'react';
import _cloneDeep from 'lodash/cloneDeep';

import prepareColumns from '../utils/prepareColumns';
import SortTitle from '../components/SortTitle';

const useTableSorter = ({ columns, onChange }) => {
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const [sortColumns, setSortColumns] = useState(prepareColumns(columns));

  const handleChange = (pagination, filters, sorter, extra) => {
    if (onChange) {
      onChange(pagination, filters, sorter, extra);
    }

    setSortBy(sorter.columnKey);
    setSortOrder(sorter.order);

    const sortColumns = _cloneDeep(columns).map(column => {
      if (!column.sorter) {
        return column;
      }

      if (column.key === sorter.columnKey) {
        column.title = <SortTitle title={column.title} order={sorter.order} align={column?.align} />;
      } else if (column.sorter) {
        column.title = <SortTitle title={column.title} align={column?.align} />;
      }

      return column;
    });

    setSortColumns(sortColumns);
  };

  useEffect(() => {
    const newColumns = prepareColumns(columns, { sortBy, sortOrder });

    setSortColumns(newColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  return { columns: sortColumns, onChange: handleChange };
};

export default useTableSorter;
