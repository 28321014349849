import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { Button, DownloadAppButton } from 'components';

import { GaAppLink } from '../../constants/links';

import { PlatformType } from 'components/buttons/DownloadAppButton';

import './DownloadAppStep.styles.scss';

const { Text } = Typography;

const DownloadAppStep = ({ onFinish = () => {} }) => {
  const { t } = useTranslation();

  return (
    <div className="np-enable-ga-download-app">
      <Text className="np-enable-ga-download-app__title">
        {t('accountSettings.accountSecurity.form.twoFAEnable.formSteps.downloadApp')}
      </Text>

      <div className="np-enable-ga-download-app__buttons">
        <DownloadAppButton type={PlatformType.APP_STORE} link={GaAppLink[PlatformType.APP_STORE]} />
        <DownloadAppButton type={PlatformType.GOOGLE_PLAY} link={GaAppLink[PlatformType.GOOGLE_PLAY]} />
      </div>

      <Button block size="large" onClick={onFinish}>
        {t('form.button.next')}
      </Button>
    </div>
  );
};

export default DownloadAppStep;
