import React from 'react';
import { Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { QuestionIcon } from 'assets/images';

import './LabelWithTooltip.styles.scss';

const { Text } = Typography;

const LabelWithTooltip = ({ label, tooltip, fontSize, tooltipSize, align }) => {
  const { t } = useTranslation();

  return (
    <div style={{ justifyContent: align }} className="np-label-with-tooltip">
      <Text style={{ fontSize }} className="np-label-with-tooltip__label">
        {t(label)}
      </Text>

      <Tooltip title={tooltip}>
        <QuestionIcon style={{ width: tooltipSize, height: tooltipSize }} className="np-label-with-tooltip__icon" />
      </Tooltip>
    </div>
  );
};

export default LabelWithTooltip;
