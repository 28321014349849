import React from 'react';
import { Switch, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { QuestionIcon } from 'assets/images';

import './ActivationSwitcher.styles.scss';

const SwitcherTootltip = ({ tooltip }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(tooltip)}>
      <QuestionIcon className="np-activation-switcher__tooltip-icon" />
    </Tooltip>
  );
};

const ActivationSwitcher = ({
  tooltip = '',
  tooltipPlace = 'end',
  checkedChildren = 'common.on',
  unCheckedChildren = 'common.off',
  className = '',
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className={`np-activation-switcher ${className}`}>
      {tooltip && tooltipPlace === 'start' && <SwitcherTootltip tooltip={tooltip} />}

      <Switch checkedChildren={t(checkedChildren)} unCheckedChildren={t(unCheckedChildren)} {...props} />

      {tooltip && tooltipPlace === 'end' && <SwitcherTootltip tooltip={tooltip} />}
    </div>
  );
};

export default ActivationSwitcher;
