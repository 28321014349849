import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';

import './Checkbox.styles.scss';

const Checkbox = ({ className = '', ...props }) => {
  return <AntdCheckbox className={`np-checkbox ${className}`} {...props} />;
};

Checkbox.Group = ({ className = '', children, ...props }) => {
  return (
    <AntdCheckbox.Group className={`${children ? '' : 'np-checkbox'} ${className}`} {...props}>
      {children}
    </AntdCheckbox.Group>
  );
};

export default Checkbox;
