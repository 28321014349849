import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { setLanguage } from 'store/reducers/lang';

import { Language } from 'constants/languages';

const LANG_URL_INDEX = 1;

const useUrlLang = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname, search } = location;

  const removeLangFromUrl = (path = '') => {
    const splitedPath = path.split('/');

    splitedPath.splice(LANG_URL_INDEX, 1);

    return splitedPath.join('/');
  };

  useEffect(() => {
    const fullPath = pathname + search;
    const splitedPath = pathname.split('/');

    const urlLanguage = splitedPath[LANG_URL_INDEX];

    const languages = Object.values(Language);

    const urlHasLang = languages.includes(urlLanguage);

    if (urlHasLang) {
      dispatch(setLanguage(urlLanguage));
      navigate(removeLangFromUrl(fullPath));
    } else {
      navigate(fullPath);
    }
  }, [dispatch, pathname, search, navigate]);
};

export default useUrlLang;
