import getPowerLabel from 'utils/power/getPowerLabel';

const getComputingSpeedUnit = (value, power, options = {}) => {
  const { fixed = 2, unit = 'H/s', fixedZero } = options;

  const powerLabel = getPowerLabel(power);

  let valueLabel = value;

  if ((fixed && value) || (fixed && fixedZero && !value)) {
    valueLabel = value.toFixed(fixed);
  }

  return `${valueLabel} ${powerLabel}${unit}`;
};

export default getComputingSpeedUnit;
