import React, { useMemo } from 'react';
import { Button as AntdButton } from 'antd';

import { ButtonColor } from './constants/types';

import './Button.styles.scss';

const Button = ({
  type = 'primary',
  shape = 'round',
  color = ButtonColor.PRIMARY,
  children,
  maxWidth,
  className,
  style,
  ...props
}) => {
  const colorClass = useMemo(() => {
    if (type === 'default') {
      if (color === ButtonColor.PRIMARY) {
        return 'np-button__default--primary';
      }

      if (color === ButtonColor.RED) {
        return 'np-button__default--red';
      }

      if (color === ButtonColor.DEFAULT) {
        return 'np-button__default--default';
      }
    }

    if (type === 'primary') {
      if (color === ButtonColor.PRIMARY) {
        return 'np-button__primary--primary';
      }

      if (color === ButtonColor.RED) {
        return 'np-button__primary--red';
      }

      if (color === ButtonColor.DEFAULT) {
        return 'np-button__primary--default';
      }
    }
  }, [color, type]);

  return (
    <AntdButton
      type={type}
      shape={shape}
      style={{ maxWidth, ...style }}
      className={`np-button ${colorClass} ${className}`}
      {...props}
    >
      {children}
    </AntdButton>
  );
};

export default Button;
