import React from 'react';

import Accordion from '../Accordion';

import './AccordionGroup.styles.scss';

const AccordionGroup = ({ items = [], gap = 16, maxWidth }) => {
  return (
    <div className="np-accordion-group" style={{ gap, maxWidth }}>
      {items.map(item => (
        <Accordion key={item.key} header={item.header}>
          {item.content}
        </Accordion>
      ))}
    </div>
  );
};

export default AccordionGroup;
