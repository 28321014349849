import React from 'react';
import { useTranslation } from 'react-i18next';

import WorkersStatus from '../components/WorkersStatus';

import formatBtcAmount from 'utils/coins/formatBtcAmount';
import getComputingSpeedUnit from 'utils/power/getComputingSpeedUnit';

const useAccountSummaryItems = data => {
  const { t } = useTranslation();

  const { balance_btc, hr_24h, hr_24h_power } = data || {};

  return [
    [
      {
        title: t('accountsSummary.balance'),
        desc: formatBtcAmount(balance_btc),
      },
      {
        title: t('accountsSummary.hashrate24h'),
        desc: getComputingSpeedUnit(hr_24h, hr_24h_power),
      },
      {
        title: t('accountsSummary.workers'),
        desc: <WorkersStatus data={data} />,
      },
    ],
  ];
};

export default useAccountSummaryItems;
