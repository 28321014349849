import React from 'react';
import Slider from 'react-slick';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.styles.scss';

const Carousel = ({ children, width, style, className = '', ...props }) => {
  const DefaultSettings = {
    nextArrow: <RightOutlined />,
    prevArrow: <LeftOutlined />,
  };

  return (
    <Slider {...DefaultSettings} {...props} style={{ width, ...style }} className={`np-carousel ${className}`}>
      {children}
    </Slider>
  );
};

export default Carousel;
