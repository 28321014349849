import { addMinutes, parseISO, format as formatFn, subMinutes } from 'date-fns';
import timestampToDateString from './timestampToDateString';
import { DATE_DOT_FORMAT_2 } from 'constants/date';

const formatDate = (date, options) => {
  const { timestamp = true, utc = true, format = DATE_DOT_FORMAT_2, ms } = options || {};

  const dateString = timestamp ? timestampToDateString(date, { ms }) : new Date(date).toISOString();
  const parsedDate = parseISO(dateString);

  if (timestamp) {
    const utcDate = utc ? addMinutes(parsedDate, parsedDate.getTimezoneOffset()) : parsedDate;

    return formatFn(utcDate, format);
  }

  const utcDate = !utc ? subMinutes(parsedDate, parsedDate.getTimezoneOffset()) : parsedDate;

  return formatFn(utcDate, format);
};

export default formatDate;
