import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { BlockWrapper } from 'components';

import './ReferralInfoBlock.styles.scss';

const { Text } = Typography;

const ReferralInfoBlock = ({ title, value }) => {
  const { t } = useTranslation();

  return (
    <div className="np-referral-info-block">
      <BlockWrapper.Title className="np-referral-info-block-title">{t(title)}</BlockWrapper.Title>

      <Text className="np-referral-info-block-text">{value}</Text>
    </div>
  );
};

export default ReferralInfoBlock;
