export const ModalType = {
  TWO_FA: '2fa',
  PAYMENT_PASSWORD: 'payment-password',
  NOTICEMENT: 'noticement',
};

export const SecurityNoticeParams = {
  TWO_FA: '2fa',
  PAYMENT_PASSWORD: 'password',
};
