import request from './request.service';
import { apiUrls } from 'constants/urls';
import withDefault from 'utils/params/withDefault';
import asyncErrorHandler from 'utils/requests/errorHandler';
import filterEmptyParams from 'utils/params/filterEmptyParams';

export const getInternalUserNotifications = async () => {
  const callback = async () => {
    return await request(apiUrls.internalNotifications.all);
  };

  return await asyncErrorHandler(callback, { defaultErrorReturn: [] });
};

export const deleteInternalNotification = async id => {
  const callback = async () => {
    return await request(apiUrls.internalNotifications.byId(id), {
      method: 'DELETE',
    });
  };

  return await asyncErrorHandler(callback);
};

export const getNotificationsSettings = async accountId => {
  const callback = async () => {
    return await request(apiUrls.notificationsSettings.byId(accountId));
  };

  return await asyncErrorHandler(callback);
};

export const changeNotificationsSettings = async ({ accountId, body }) => {
  const callback = async () => {
    return await request(apiUrls.notificationsSettings.byId(accountId), {
      method: 'POST',
      body: filterEmptyParams(body),
    });
  };

  return await asyncErrorHandler(callback);
};

export const getMiningNotificationsConfig = async accountId => {
  const callback = async () => {
    return await request(apiUrls.miningNotifications.configById(accountId));
  };

  return await asyncErrorHandler(callback);
};

export const changeMiningNotificationsConfig = async ({ accountId, config }) => {
  const callback = async () => {
    return await request(apiUrls.miningNotifications.configById(accountId), {
      method: 'POST',
      body: config || {},
    });
  };

  return await asyncErrorHandler(callback);
};

export const getMiningNotifications = async accountId => {
  const callback = async () => {
    return await request(apiUrls.miningNotifications.byId(accountId));
  };

  return await asyncErrorHandler(callback, { defaultErrorReturn: [] });
};

export const changeMiningNotifications = async ({ accountId, body }) => {
  const { coin, thresholds, fluctuation, active_workers } = body || {};

  const callback = async () => {
    return await request(apiUrls.miningNotifications.byId(accountId), {
      method: 'POST',
      body: {
        coin,
        unit: withDefault(thresholds?.unit, null),
        threshold: withDefault(thresholds?.value, null),
        fluctuation: withDefault(fluctuation?.value, null),
        active_workers: withDefault(active_workers?.value, null),
        is_active_wks: withDefault(active_workers?.is_active, false),
        is_active_threshold: withDefault(thresholds?.is_active, false),
        is_active_fluctuation: withDefault(fluctuation?.is_active, false),
      },
    });
  };

  return await asyncErrorHandler(callback);
};
