import { createSlice } from '@reduxjs/toolkit';
import { StorageKey } from 'constants/localStorage';

const theme = sessionStorage.getItem(StorageKey.THEME);

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    theme,
  },
  reducers: {
    changeTheme: (state, action) => {
      state.theme = action.payload;
      sessionStorage.setItem(StorageKey.THEME, action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeTheme } = themeSlice.actions;

export default themeSlice.reducer;
