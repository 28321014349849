export const Coin = {
  BTC: 'BTC',
  FB: 'FB',
};

export const CoinLabel = {
  [Coin.BTC]: 'BTC',
  [Coin.FB]: 'FB',
};

export const CoinsLabels = Object.values(Coin).map(coin => ({ value: coin, label: CoinLabel[coin] }));
