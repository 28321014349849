import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SidebarLayout } from 'components';

import useUserSidebarOptions from 'hooks/navigation/useUserSidebarOptions';

import { publicUrls } from 'constants/urls';

const UserSidebarLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const menuItems = useUserSidebarOptions();

  const activeMenuItem = location.pathname;

  const defaultOpenedKeys = useMemo(() => {
    if (
      activeMenuItem.includes(publicUrls.profile.settings.main) ||
      activeMenuItem.includes(publicUrls.profile.accountManagement.main)
    ) {
      return publicUrls.profile.settings.main;
    }

    if (activeMenuItem.includes(publicUrls.profile.wallet.main)) {
      return publicUrls.profile.wallet.main;
    }

    return null;
  }, [activeMenuItem]);

  const onMenuClick = item => {
    navigate(item.item.props.link);
  };

  return (
    <SidebarLayout
      onClick={onMenuClick}
      active={activeMenuItem}
      items={menuItems}
      defaultOpenKeys={[defaultOpenedKeys]}
    >
      {children}
    </SidebarLayout>
  );
};

export default UserSidebarLayout;
