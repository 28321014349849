import { useMemo } from 'react';
import useElementDimensions from 'hooks/elements/useElementDimensions';

const useSidebarBodyHeight = () => {
  const { ref: headerRef, size: headerSize } = useElementDimensions();
  const { ref: footerRef, size: footerSize } = useElementDimensions();
  const { ref: menuFooterRef, size: menuFooterSize } = useElementDimensions();

  const { height: headerHeight = 0 } = headerSize || {};
  const { height: footerHeight = 0 } = footerSize || {};
  const { height: menuFooterHeight = 0 } = menuFooterSize || {};

  const bodyHeight = useMemo(() => {
    return `calc(100vh - ${headerHeight}px - ${footerHeight}px - ${menuFooterHeight}px)`;
  }, [headerHeight, footerHeight, menuFooterHeight]);

  return { headerRef, footerRef, menuFooterRef, bodyHeight };
};

export default useSidebarBodyHeight;
