import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { DashboardIcon, LinksIcon, SettingsIcon, WalletIcon, WorkersIcon } from 'assets/images';

import watcherHasPermission from 'utils/auth/watcherHasPermission';

import { publicUrls } from 'constants/urls';
import { RouteWatcherPermissions } from 'constants/observer/permissions';

const useWatcherSidebarOptions = () => {
  const { t } = useTranslation();
  const { watcher } = useSelector(state => state.watcherAuth);

  const protectItem = (item, options = {}) => {
    const isAuth = watcherHasPermission({ watcher, ...options });

    return isAuth ? item : null;
  };

  return [
    {
      label: t('menu.userProfile.dashboard'),
      key: publicUrls.observer.profile.main,
      link: publicUrls.observer.profile.main,
      icon: <DashboardIcon />,
    },

    protectItem(
      {
        label: t('menu.userProfile.workers'),
        key: publicUrls.observer.profile.workers,
        link: publicUrls.observer.profile.workers,
        icon: <WorkersIcon />,
      },
      RouteWatcherPermissions[publicUrls.observer.profile.workers],
    ),

    protectItem(
      {
        label: t('menu.userProfile.assetsAndPayments'),
        key: publicUrls.observer.profile.wallet.main,
        link: publicUrls.observer.profile.wallet.main,
        icon: <WalletIcon />,
        children: [
          {
            label: t('menu.userProfile.bills'),
            key: publicUrls.observer.profile.wallet.myBills,
            link: publicUrls.observer.profile.wallet.myBills,
          },

          {
            label: t('menu.userProfile.depositsAndWithdrawal'),
            key: publicUrls.observer.profile.wallet.depositAndWithdrawal,
            link: publicUrls.observer.profile.wallet.depositAndWithdrawal,
          },

          protectItem(
            {
              label: t('menu.userProfile.paymentSettings'),
              key: publicUrls.observer.profile.settings.payment,
              link: publicUrls.observer.profile.settings.payment,
            },
            RouteWatcherPermissions[publicUrls.observer.profile.settings.payment],
          ),
        ],
      },
      RouteWatcherPermissions[publicUrls.observer.profile.wallet.main],
    ),

    protectItem(
      {
        label: t('menu.userProfile.accountSettings'),
        key: publicUrls.observer.profile.settings.main,
        link: publicUrls.observer.profile.settings.main,
        icon: <SettingsIcon />,
        children: [
          {
            label: t('menu.userProfile.profile'),
            key: publicUrls.observer.profile.settings.account,
            link: publicUrls.observer.profile.settings.account,
          },

          {
            label: t('menu.userProfile.subAccounts'),
            key: publicUrls.observer.profile.accountManagement.miningAccounts,
            link: publicUrls.observer.profile.accountManagement.miningAccounts,
          },

          {
            label: t('menu.userProfile.watchers'),
            key: publicUrls.observer.profile.accountManagement.watcher,
            link: publicUrls.observer.profile.accountManagement.watcher,
          },

          {
            label: t('menu.userProfile.notifications'),
            key: publicUrls.observer.profile.accountManagement.notifications,
            link: publicUrls.observer.profile.accountManagement.notifications,
          },

          {
            label: t('menu.userProfile.mining'),
            key: publicUrls.observer.profile.settings.mining,
            link: publicUrls.observer.profile.settings.mining,
          },
        ],
      },
      RouteWatcherPermissions[publicUrls.observer.profile.settings.main],
    ),

    protectItem(
      {
        label: t('menu.userProfile.referral'),
        key: publicUrls.observer.profile.referralRewards,
        link: publicUrls.observer.profile.referralRewards,
        icon: <LinksIcon />,
      },
      RouteWatcherPermissions[publicUrls.observer.profile.referralRewards],
    ),
  ].filter(Boolean);
};

export default useWatcherSidebarOptions;
