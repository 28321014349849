import React from 'react';

import WalletTooltip from './components/WalletTooltip';
import { LabelWithTooltip } from 'components';

const WalletLabel = () => {
  return <LabelWithTooltip label="form.label.wallet" tooltip={<WalletTooltip />} />;
};

export default WalletLabel;
