import { Form } from 'antd';
import { useEffect } from 'react';

const useCheckInputSpecialSymbols = (name, form) => {
  const value = Form.useWatch(name, form);

  useEffect(() => {
    if (value) {
      const specialSymbols = /\s+/g;

      const newValue = value.replaceAll(specialSymbols, '');

      form.setFieldValue(name, newValue);
    }
  }, [form, name, value]);
};

export default useCheckInputSpecialSymbols;
