import React from 'react';

import { TableWithHeader } from 'components';

import useBeneficiaryRewardsTableColumns from './hooks/useBeneficiaryRewardsTableColumns';

import './BeneficiaryRewardsTable.styles.scss';

const BeneficiaryRewardsTable = ({ dataSource, className = '', ...props }) => {
  const columns = useBeneficiaryRewardsTableColumns();

  return (
    <TableWithHeader
      title="referralRewards.table.myBeneficiaryRewards.title"
      columns={columns}
      dataSource={dataSource}
      rowKey={record => record.account_name}
      className={`np-beneficiary-rewards-table ${className}`}
      {...props}
    />
  );
};

export default BeneficiaryRewardsTable;
