import { YM_DISABLE_KEYS_CLASS } from 'constants/yandexMetrika';

const ymProtectInput = (ref, newRef, callback = () => {}) => {
  const inputElement = ref?.input;

  if (inputElement) {
    inputElement?.classList?.add(YM_DISABLE_KEYS_CLASS);
  }

  if (newRef) {
    newRef.current = ref;
  }

  callback();
};

export default ymProtectInput;
