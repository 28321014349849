import React, { useMemo } from 'react';

import Notification from '../Notification';

import { groupByType } from '../../utils/group';

import { NotificationType } from '../../constants/types';

const NotificationsGroup = ({ data, onDelete = () => {} }) => {
  const dataGroupedByType = useMemo(() => groupByType(data), [data]);

  const closableNotifications = dataGroupedByType[NotificationType.CLOSABLE] || [];
  const notClosableNotifications = dataGroupedByType[NotificationType.NOT_CLOSABLE] || [];
  const firstClosableNotification = closableNotifications[0];

  return (
    <>
      {notClosableNotifications.map(notification => (
        <Notification key={notification.id} data={notification} onDelete={onDelete} />
      ))}

      {firstClosableNotification && (
        <Notification key={firstClosableNotification.id} data={firstClosableNotification} onDelete={onDelete} />
      )}
    </>
  );
};

export default NotificationsGroup;
