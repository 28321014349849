import React from 'react';
import _cloneDeep from 'lodash/cloneDeep';

import SortTitle from '../components/SortTitle';
import { SortOrderMap } from 'constants/table';

const prepareColumns = (columns, options = {}) => {
  const { sortBy, sortOrder } = options;

  const sortColumns = _cloneDeep(columns);

  if (!sortColumns) {
    return sortColumns;
  }

  return sortColumns.map(column => {
    if (!column?.sorter) {
      return column;
    }

    if (sortBy && sortBy === column.key) {
      column.title = <SortTitle title={column.title} align={column?.align} order={sortOrder} />;

      return column;
    }

    if (column?.defaultSortOrder && !sortBy) {
      column.title = (
        <SortTitle title={column.title} align={column?.align} order={SortOrderMap[column?.defaultSortOrder]} />
      );

      return column;
    }

    column.title = <SortTitle title={column.title} align={column?.align} />;

    return column;
  });
};

export default prepareColumns;
