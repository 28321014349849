import yn from 'yn';
import { useTranslation } from 'react-i18next';

import isNumber from 'utils/common/isNumber';
import checkIsWalletValid from 'utils/validation/checkIsWalletValid';
import getNumberOfDecimalPlaces from 'utils/common/getNumberOfDecimalPlaces';

import regexp from 'constants/regexp';
import { CryptoNetwork } from 'constants/wallet';
import { SATOSHI, SATOSHI_POWER } from 'constants/power';
import { allowGeneralEmail, testWalletPrefix } from 'configs';

const ErrorMessage = {
  REQUIRED: 'validation.required',
  EMAIL_NOT_VALID: 'validation.email.notValid',
  DIGITS_ONLY: 'validation.digitsOnly',
  LOWER_CASE_ONLY: 'validation.lowerCaseOnly',
  LETTERS_NUMBERS_ONLY: 'validation.lettersNumbersOnly',
  GA_LENGTH: 'validation.ga.length',
  PAYMENT_PASSWORD_LENGTH: 'validation.paymentPassword.length',
  HAS_UPPER_CASE: 'validation.hasUpperCase',
  HAS_LOWER_CASE: 'validation.hasLowerCase',
  HAS_NUMBER: 'validation.hasNumber',
  HAS_SPECIAL_CHAR: 'validation.hasSpecialChar',
  USERNAME_LENGTH: 'validation.username.length',
  BEGINS_WITH_LETTER: 'validation.beginsWithLetter',
  FRACTIONAL_NUMBERS: 'validation.fractionalNumbers',
  NATURAL_NUMBERS: 'validation.naturalNumbers',
  WALLET_ADDRESS: 'validation.walletAddress',
  PHONE_NUMBER: 'validation.phoneNumber',
  INTEGER: 'validation.integer',
  TELEGRAM_CHAT_ID: 'validation.telegramChatId',
  MUST_MATCH: 'validation.mustMatch',
  PASSWORDS_MUST_MATCH: 'validation.password.mustMatch',
  SATOSHI: 'validation.satoshi',
  NO_INJECTION: 'validation.noInjection',
};

export default function useValidation() {
  const { t } = useTranslation();

  const required = { required: true, message: t(ErrorMessage.REQUIRED) };
  const requiredNoMessage = { required: true, message: '' };

  const digitsOnly = { pattern: regexp.digitsOnly, message: t(ErrorMessage.DIGITS_ONLY) };
  const lettersOrNumbersOnly = { pattern: regexp.lettersOrNumbers, message: t(ErrorMessage.LETTERS_NUMBERS_ONLY) };

  const hasNumber = { pattern: regexp.number, message: t(ErrorMessage.HAS_NUMBER) };
  const hasUpperCase = { pattern: regexp.upperCase, message: t(ErrorMessage.HAS_UPPER_CASE) };
  const hasLowerCase = { pattern: regexp.lowerCase, message: t(ErrorMessage.HAS_LOWER_CASE) };
  const hasSpecialChar = { pattern: regexp.specialChar, message: t(ErrorMessage.HAS_SPECIAL_CHAR) };

  const beginsWithLetter = { pattern: regexp.beginsWithLetter, message: t(ErrorMessage.BEGINS_WITH_LETTER) };

  const noInjection = { pattern: regexp.noInjection, message: t(ErrorMessage.NO_INJECTION) };

  const integer = { pattern: regexp.integer, message: t(ErrorMessage.INTEGER) };
  const phone = { pattern: regexp.phoneNumber, message: t(ErrorMessage.PHONE_NUMBER) };
  const naturalNumber = { pattern: regexp.digitsOnly, message: t(ErrorMessage.NATURAL_NUMBERS) };
  const fractionalNumber = { pattern: regexp.fractionalNumber, message: t(ErrorMessage.FRACTIONAL_NUMBERS) };

  const email = {
    validator: (_, value, callback) => {
      if (!value) {
        return callback(undefined);
      }

      const isValidEmail = regexp.email.test(value);
      const notValidGmail = regexp.email2.test(value);

      const isValid = yn(allowGeneralEmail) ? isValidEmail : isValidEmail && !notValidGmail;

      return callback(isValid ? undefined : t(ErrorMessage.EMAIL_NOT_VALID));
    },
  };

  const wallet = {
    validator: (_, value, callback) => {
      const options = !testWalletPrefix ? { networks: CryptoNetwork.MAIN_NET } : {};

      const isValid = checkIsWalletValid(value, options);

      return callback(isValid ? undefined : t(ErrorMessage.WALLET_ADDRESS));
    },
  };

  const telegramChatId = { pattern: regexp.integer, message: t(ErrorMessage.TELEGRAM_CHAT_ID) };

  const getRequiredWithMessage = message => {
    return { required: true, message: t(message) };
  };

  const getLenRule = (len, message) => {
    return { len, message: t(message) };
  };

  const getMinLenRule = (min, message) => {
    return { min, message: t(message) };
  };

  const getMaxLenRule = (max, message) => {
    return { max, message: t(message) };
  };

  const getMatchRule = (matchValue, message = '') => {
    return {
      validator: (_, value, callback) => {
        let isMatched = true;

        if (matchValue) {
          isMatched = matchValue === value;
        }

        return callback(isMatched ? undefined : t(message));
      },
    };
  };

  const getGaRules = () => {
    const length = getLenRule(6, ErrorMessage.GA_LENGTH);

    return [required, digitsOnly, length];
  };

  const getPaymentPasswordRules = passwordMatch => {
    const length = getLenRule(6, ErrorMessage.PAYMENT_PASSWORD_LENGTH);
    const match = getMatchRule(passwordMatch, ErrorMessage.PASSWORDS_MUST_MATCH);

    return [required, digitsOnly, length, match];
  };

  const getPasswordRules = passwordMatch => {
    const minLen = getMinLenRule(12, '');
    const maxLen = getMaxLenRule(32, '');
    const match = getMatchRule(passwordMatch);

    return [required, minLen, maxLen, hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar, match];
  };

  const getUsernameRules = () => {
    const minLen = getMinLenRule(2, ErrorMessage.USERNAME_LENGTH);
    const maxLen = getMaxLenRule(15, ErrorMessage.USERNAME_LENGTH);

    return [minLen, maxLen, beginsWithLetter, lettersOrNumbersOnly];
  };

  const getCheckedRequiredRules = message => {
    return {
      validator: async (_, checked) => {
        if (!checked) return Promise.reject(new Error(t(message)));
      },
    };
  };

  const getRequiredMinCountRules = (min = 1, message = '') => {
    return {
      validator: (_, value, callback) => {
        const isValid = value && value.length >= min;

        return callback(isValid ? undefined : t(message));
      },
    };
  };

  const getSatoshiRules = () => {
    return {
      validator: (_, amount, callback) => {
        const isAmountNum = isNumber(+amount);
        const isGreaterThanSatoshi = +amount >= SATOSHI && getNumberOfDecimalPlaces(amount) <= SATOSHI_POWER;
        const isValid = isAmountNum && isGreaterThanSatoshi;

        return callback(isValid ? undefined : t(ErrorMessage.SATOSHI));
      },
    };
  };

  return {
    required,
    requiredNoMessage,
    email,
    digitsOnly,
    hasUpperCase,
    hasLowerCase,
    hasNumber,
    hasSpecialChar,
    beginsWithLetter,
    fractionalNumber,
    naturalNumber,
    wallet,
    phone,
    integer,
    telegramChatId,
    noInjection,

    ga: getGaRules(),
    satoshi: getSatoshiRules(),
    password: getPasswordRules(),
    username: getUsernameRules(),
    paymentPassword: getPaymentPasswordRules(),

    passwordWithMatch: getPasswordRules,
    requiredWithMessage: getRequiredWithMessage,
    paymentPasswordWithMatch: getPaymentPasswordRules,
    requiredCheckedWithMessage: getCheckedRequiredRules,
    requiredMinCount: getRequiredMinCountRules,
    requiredLen: getLenRule,
  };
}
