import React from 'react';
import { Tooltip, Typography } from 'antd';

import './EllipsisText.styles.scss';

const { Text } = Typography;

const EllipsisText = ({ style, children, fontSize = '12px', width, ...props }) => {
  return (
    <Tooltip title={children}>
      <Text ellipsis style={{ fontSize, width, ...style }} className="np-ellipsis-text" {...props}>
        {children}
      </Text>
    </Tooltip>
  );
};

export default EllipsisText;
