import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';

import useNotifications from 'hooks/common/useNotifications';
import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

const ExportButton = ({ size, callback = async () => {}, ...props }) => {
  const { t } = useTranslation();
  const { notificationApi } = useNotifications();
  const { isMobileView } = useWindowResponsiveness(700);

  const [loading, setLoading] = useState(false);

  const onExport = async () => {
    setLoading(true);

    const { success, errorMessage } = await callback();

    if (success) {
      notificationApi.success({ message: t('success.export.emailSend') });
    } else {
      notificationApi.error({ message: t(errorMessage) });
    }

    setLoading(false);
  };

  return (
    <Button
      block
      type="default"
      size={size ? size : isMobileView ? 'middle' : 'large'}
      loading={loading}
      onClick={onExport}
      maxWidth={90}
      {...props}
    >
      {t('form.button.export')}
    </Button>
  );
};

export default ExportButton;
