import React from 'react';
import { Modal } from 'antd';

import BaseModalTitle from './components/BaseModalTitle';

import './BaseModal.styles.scss';

const BaseModal = ({
  children,
  maxWidth,
  icon,
  title,
  footer = [],
  style = {},
  className = '',
  onClose = () => {},
  ...props
}) => {
  return (
    <Modal
      centered
      destroyOnClose
      footer={footer}
      title={<BaseModalTitle title={title} icon={icon} />}
      className={`np-base-modal ${className}`}
      style={{ maxWidth, ...style }}
      onCancel={onClose}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default BaseModal;
