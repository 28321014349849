import { apiUrls } from 'constants/urls';
import asyncErrorHandler from 'utils/requests/errorHandler';
import filterEmptyParams from 'utils/params/filterEmptyParams';
import createFormData from 'utils/params/createFormData';
import request from './request.service';

export const getAccountBalance = async ({ accountId, coin }) => {
  const callback = async () => {
    return await request(apiUrls.wallet.balance(accountId), {
      params: filterEmptyParams({ coin }),
    });
  };

  return await asyncErrorHandler(callback);
};

export const getAccountPaymentSettings = async accountId => {
  const callback = async () => {
    return await request(apiUrls.payment.settingsById(accountId));
  };

  return await asyncErrorHandler(callback);
};

export const getAccountWallet = async accountId => {
  const callback = async () => {
    return await request(apiUrls.wallet.byAcc(accountId));
  };

  return await asyncErrorHandler(callback);
};

export const getFee = async (params = {}) => {
  const { amount, address } = params;

  const callback = async () => {
    return await request(apiUrls.payment.fee, {
      method: 'POST',
      params: filterEmptyParams({ amount, address }),
    });
  };

  return await asyncErrorHandler(callback);
};

export const changeAccountWallet = async ({ accountId, body: { wallet, password, totp, paymentPassword } }) => {
  const callback = async () => {
    return await request(apiUrls.wallet.byAcc(accountId), {
      method: 'POST',
      body: filterEmptyParams({
        wallet_name: wallet,
        password,
        acc_id: accountId,
        otp: totp,
        payment_password: paymentPassword,
      }),
    });
  };

  return await asyncErrorHandler(callback, {
    errors: {
      incorrectTOTP: 'requestErrors.incorrectTOTP',
      incorrectPassword: 'requestErrors.incorrectPassword',
    },
  });
};

export const interUserTransfer = async ({ accountFrom, accountName, amount, paymentPassword, totp }) => {
  const callback = async () => {
    return await request(apiUrls.payment.interUserTransfer, {
      method: 'POST',
      body: filterEmptyParams({
        amount,
        payment_password: paymentPassword,
        otp_2fa: totp,
        account: accountName,
        from_acc: accountFrom,
      }),
    });
  };

  return await asyncErrorHandler(callback, {
    errors: {
      NSF: 'requestErrors.payment.nsf',
      minLimit: 'requestErrors.payment.minLimit',
      incorrectTOTP: 'requestErrors.incorrectTOTP',
      invalidAccount: 'requestErrors.auth.invalidAccount',
      incorrectExistingPassword: 'requestErrors.incorrectExistingPassword',
    },
  });
};

export const payoutTransfer = async ({ accountFrom, withdrawalAddress, amount, id, fee, paymentPassword, totp }) => {
  const callback = async () => {
    return await request(apiUrls.payment.payoutById(accountFrom), {
      method: 'POST',
      body: filterEmptyParams({
        amount,
        commission: fee,
        task_id: id,
        payment_password: paymentPassword,
        otp_2fa: totp,
        wallet: withdrawalAddress,
      }),
    });
  };

  return await asyncErrorHandler(callback, {
    errors: {
      NSF: 'requestErrors.payment.nsf',
      minLimit: 'requestErrors.payment.minLimit',
    },
  });
};

export const getSelfTransfer = async accountId => {
  const callback = async () => {
    return await request(apiUrls.payment.selfTransferById(accountId));
  };

  return await asyncErrorHandler(callback);
};

export const selfTransfer = async ({ to, from, isActive, accountId, paymentPassword, totp }) => {
  const callback = async () => {
    return await request(apiUrls.payment.selfTransferById(accountId), {
      method: 'POST',
      body: filterEmptyParams({
        to,
        from,
        is_active: isActive,
        payment_password: paymentPassword,
        otp_2fa: totp,
      }),
    });
  };

  return await asyncErrorHandler(callback);
};

export const changeMinPayment = async ({ accountId, isActive, payment, paymentPassword, totp }) => {
  const callback = async () => {
    return await request(apiUrls.payment.minPaymentById(accountId), {
      method: 'POST',
      body: filterEmptyParams({
        btc_min_payout: payment,
        btc_auto_pay: isActive,
        payment_password: paymentPassword,
        otp_2fa: totp,
      }),
    });
  };

  return await asyncErrorHandler(callback);
};

export const updateAutoWithdrawalSettings = async ({
  accountId,
  isActive,
  threshold,
  wallet,
  paymentPassword,
  totp,
  coin = 'BTC',
}) => {
  const callback = async () => {
    return await request(apiUrls.payment.withdrawalSettingsById(accountId), {
      method: 'POST',
      body: filterEmptyParams({
        coin,
        min_payout: threshold,
        auto_pay: String(isActive),
        wallet_name: wallet,
        payment_password: paymentPassword,
        otp_2fa: totp,
      }),
    });
  };

  return await asyncErrorHandler(callback);
};

export const changePaymentPassword = async ({ paymentPassword, newPaymentPassword, password, totp }) => {
  const callback = async () => {
    return await request(apiUrls.payment.password.info, {
      method: 'POST',
      body: filterEmptyParams({
        payment_password: paymentPassword,
        new_payment_password: newPaymentPassword,
        login_password: password,
        otp_2fa: totp,
      }),
    });
  };

  return await asyncErrorHandler(callback, {
    errors: {
      incorrectTOTP: 'requestErrors.incorrectTOTP',
      incorrectPassword: 'requestErrors.incorrectExistingPassword',
      incorrectExistingPassword: 'requestErrors.incorrectExistingPassword',
    },
  });
};

export const changePaymentPasswordActivation = async ({ paymentPassword }) => {
  const callback = async () => {
    return await request(apiUrls.payment.password.active, {
      method: 'POST',
      body: filterEmptyParams({
        payment_password: paymentPassword,
      }),
    });
  };

  return await asyncErrorHandler(callback, {
    errors: {
      incorrectTOTP: 'requestErrors.incorrectTOTP',
      incorrectExistingPassword: 'requestErrors.incorrectExistingPassword',
    },
  });
};

export const resetPaymentPasswordAccountVerification = async ({ password, totp }) => {
  const callback = async () => {
    return await request(apiUrls.payment.password.reset.accountVerification, {
      method: 'POST',
      body: filterEmptyParams({
        login_password: password,
        otp_2fa: totp,
      }),
    });
  };

  return await asyncErrorHandler(callback);
};

export const resetPaymentPasswordEmailVerification = async ({ code }) => {
  const callback = async () => {
    return await request(apiUrls.payment.password.reset.emailVerification, {
      method: 'POST',
      body: filterEmptyParams({
        code,
      }),
    });
  };

  return await asyncErrorHandler(callback);
};

export const resetPaymentPasswordUploadFiles = async ({ files, password, totp }) => {
  const callback = async () => {
    const formDataArray = files.map(item => {
      const filteredParams = filterEmptyParams({ images: item.file, login_password: password, otp_2fa: totp });

      return createFormData(filteredParams);
    });

    return await Promise.all(
      formDataArray.map(body =>
        request(apiUrls.payment.password.reset.upload, {
          method: 'POST',
          isJson: false,
          body,
        }),
      ),
    );
  };

  return await asyncErrorHandler(callback);
};
