import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import './VipIndicator.styles.scss';

const VipIndicator = ({ tooltip, Icon }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(tooltip)}>
      <div className="np-user-layout-header__icon-wrapper">
        <Icon className="np-user-layout-header__icon" />
      </div>
    </Tooltip>
  );
};

export default VipIndicator;
