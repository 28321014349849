const MEGA_POWER = 1000000;

const convertBytesToMegaBytes = (sizeInBytes, options = {}) => {
  const { label, fixed } = options;

  const convertedValue = sizeInBytes / MEGA_POWER;
  const fixedValue = fixed ? convertedValue.toFixed(fixed) : convertedValue;

  return label ? `${fixedValue} ${label}` : fixedValue;
};

export default convertBytesToMegaBytes;
