import React from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { MessageOutlined } from '@ant-design/icons';

import StepLayout from '../../common/StepLayout';
import ConfirmationForm from './components/ConfirmationForm';
import ConfirmationReminders from './components/ConfirmationReminders';

const Confirmation = ({ noticements = [], reminders = [], onFinish = () => {}, ...props }) => {
  const { t } = useTranslation();

  const showReminders = reminders && reminders.length > 0;

  return (
    <Space direction="vertical" size={32}>
      {showReminders && (
        <StepLayout title={t('resetProcess.steps.confirmation.reminders.title')}>
          <ConfirmationReminders reminders={reminders} />
        </StepLayout>
      )}

      <StepLayout title={t('resetProcess.steps.confirmation.content.title')} titleIcon={<MessageOutlined />}>
        <ConfirmationForm noticements={noticements} onFinish={onFinish} {...props} />
      </StepLayout>
    </Space>
  );
};

export default Confirmation;
