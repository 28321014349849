import React from 'react';

import { BooleanLabel } from 'components';

import './BooleanTag.styles.scss';

const DEFAULT_LABELS = { bool_true: 'common.on', bool_false: 'common.off' };

const BooleanTag = ({ value, labels = DEFAULT_LABELS }) => {
  const valueClass = value ? 'np-boolean-tag--on' : 'np-boolean-tag--off';

  return (
    <div className={`np-boolean-tag ${valueClass}`}>
      <BooleanLabel value={value} labels={labels} />
    </div>
  );
};

export default BooleanTag;
