import React, { useState } from 'react';
import { Dropdown, Space, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import './Menu.styles.scss';

const { Text } = Typography;

const Menu = ({
  title,
  items = [],
  style,
  width,
  menu,
  onMenuItemClick = () => {},
  menuClassName = '',
  className = '',
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const handleMenuItemClick = item => {
    if (onMenuItemClick) {
      onMenuItemClick(item);
    }

    setOpen(false);
  };

  const menuProps = {
    items,
    className: menuClassName,
    triggerSubMenuAction: 'click',
    onClick: handleMenuItemClick,
    ...menu,
  };

  const onOpenChange = open => {
    setOpen(open);
  };

  return (
    <Dropdown
      open={open}
      onOpenChange={onOpenChange}
      menu={menuProps}
      trigger={['click']}
      className={`np-menu ${className}`}
      dropdownRender={menu => (
        <div className="np-menu__dropdown" style={{ ...style, width }}>
          {menu}
        </div>
      )}
      {...props}
    >
      <Space className="np-menu__title">
        <Text>{title}</Text>
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

export default Menu;
