export const WorkersStateTypes = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  OFFLINE: 'offline',
};

export const DefaultWorkersNumber = {
  [WorkersStateTypes.ACTIVE]: 0,
  [WorkersStateTypes.INACTIVE]: 0,
  [WorkersStateTypes.OFFLINE]: 0,
};
