import React, { useMemo } from 'react';
import { Button } from 'antd';
import { NavLink } from 'react-router-dom';

import {
  Facebook2Icon,
  FacebookIcon,
  Instagram2Icon,
  InstagramIcon,
  Telegram2Icon,
  TelegramIcon,
  TwitterIcon,
  Twitter2Icon,
} from 'assets/images';

import { INSTAGRAM_LINK, TELEGRAM_LINK, TWITTER_LINK } from 'constants/socialMedia';
import { MediaButtonColor, MediaButtonTextColor } from './constants/colors';
import { MediaTypes } from './constants/mediaTypes';
import { MediaButtonSize } from './constants/sizes';

import './SocialMediaButton.styles.scss';

const SocialMediaButton = ({
  type,
  size = MediaButtonSize.MEDIUM,
  color = MediaButtonColor.PRIMARY,
  textColor = MediaButtonTextColor.DEFAULT,
  filled,
  link,
  className,
  ...props
}) => {
  const classPrefix = 'np-social-media-button';

  const filledClass = filled ? `${classPrefix}--filled` : '';

  const typeParams = useMemo(() => {
    if (type === MediaTypes.INSTAGRAM) {
      return {
        icon: filled ? <Instagram2Icon className="full-fill" /> : <InstagramIcon />,
        link: link || INSTAGRAM_LINK,
      };
    }

    if (type === MediaTypes.TELEGRAM) {
      return { icon: filled ? <Telegram2Icon /> : <TelegramIcon />, link: link || TELEGRAM_LINK };
    }

    if (type === MediaTypes.TWITTER) {
      return { icon: filled ? <Twitter2Icon /> : <TwitterIcon />, link: link || TWITTER_LINK };
    }

    if (type === MediaTypes.FACEBOOK) {
      return { icon: filled ? <Facebook2Icon /> : <FacebookIcon />, link };
    }

    return {};
  }, [type, filled, link]);

  const sizeClass = useMemo(() => {
    if (filled) {
      return '';
    }

    if (size === MediaButtonSize.MEDIUM) {
      return `${classPrefix}--medium`;
    }

    if (size === MediaButtonSize.LARGE) {
      return `${classPrefix}--large`;
    }

    return '';
  }, [size, filled]);

  const colorClass = useMemo(() => {
    if (filled) {
      return '';
    }

    if (color === MediaButtonColor.PRIMARY) {
      return `${classPrefix}--primary`;
    }

    if (color === MediaButtonColor.DARK_BLUE) {
      return `${classPrefix}--dark-blue`;
    }

    if (color === MediaButtonColor.GRAY) {
      return `${classPrefix}--gray`;
    }

    return '';
  }, [color, filled]);

  const textColorClass = useMemo(() => {
    if (filled) {
      return '';
    }

    if (textColor === MediaButtonTextColor.DEFAULT) {
      return `${classPrefix}--text-default`;
    }

    if (textColor === MediaButtonTextColor.WHITE) {
      return `${classPrefix}--text-white`;
    }

    return '';
  }, [textColor, filled]);

  return (
    <NavLink to={typeParams.link} target="_blank">
      <Button
        shape="circle"
        type="primary"
        icon={typeParams.icon}
        className={`${classPrefix} ${sizeClass} ${colorClass} ${textColorClass} ${filledClass} ${className}`}
        {...props}
      />
    </NavLink>
  );
};

export default SocialMediaButton;
