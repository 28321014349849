import React from 'react';

import { BorderedBlock } from 'components';

import useAccountSummaryItems from './hooks/useAccountSummaryItems';
import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

const DesktopAccountsSummary = ({ data, loading }) => {
  const items = useAccountSummaryItems(data);

  const { isMobileView } = useWindowResponsiveness(1400);

  return <BorderedBlock title={isMobileView ? 'accountsSummary.title' : null} items={items} loading={loading} />;
};

export default DesktopAccountsSummary;
