import { useQuery } from '@tanstack/react-query';
import { getCoinsInfo } from 'services/statistics.service';

const useNetworkQuery = () => {
  const select = result => {
    return result.data;
  };

  return useQuery({
    queryKey: ['network'],
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    select,
    queryFn: getCoinsInfo,
  });
};

export default useNetworkQuery;
