import React, { useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ForgotLinks, FormButtons } from 'modules';
import { Form, Captcha, FormHiddenInputs, Input } from 'components';

import useForm from 'hooks/forms/useForm';
import useValidation from 'hooks/forms/useValidation';
import useAutoFocus from 'hooks/elements/useAutoFocus';
import useCheckInputSpecialSymbols from 'hooks/forms/useCheckInputSpecialSymbols';

import ymProtectInput from 'utils/yandex-metrika/ymProtectInput';

import { YM_DISABLE_SUBMIT_CLASS } from 'constants/yandexMetrika';

import './AuthenticationForm.styles.scss';

const AuthenticationForm = ({
  loading,
  showGoBack,
  includeEmail,
  includePassword,
  includeGa,
  includePaymentPassword,
  includeCaptcha,
  onFinish = () => {},
  onGoBack = () => {},
  className = '',
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const rules = useValidation();

  const { submitDisabled } = useForm(form);

  const totpRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const payPasswordRef = useRef();

  const autoFocusRef = useMemo(() => {
    if (includeEmail) {
      return emailRef;
    }

    if (includePassword) {
      return passwordRef;
    }

    if (includePaymentPassword) {
      return payPasswordRef;
    }

    if (includeGa) {
      return totpRef;
    }
  }, [includeEmail, includeGa, includePassword, includePaymentPassword]);

  useAutoFocus(autoFocusRef);

  useCheckInputSpecialSymbols('email', form);

  return (
    <div className="np-authentication-form__wrapper">
      <Form
        requiredMark="optional"
        layout="vertical"
        form={form}
        name="auth"
        onFinish={onFinish}
        className={`np-authentication-form ${YM_DISABLE_SUBMIT_CLASS} ${className}`}
      >
        <FormHiddenInputs />

        {includeEmail && (
          <Form.Item
            validateFirst
            name="email"
            label={t('form.label.email')}
            rules={[rules.requiredWithMessage('validation.email.required'), rules.email]}
          >
            <Input autoFocus ref={ref => ymProtectInput(ref, emailRef)} size="large" disabled={loading} />
          </Form.Item>
        )}

        {includePassword && (
          <Form.Item
            name="password"
            validateFirst
            label={t('form.label.password')}
            rules={[rules.required]}
            className="np-authentication-form__input-block"
          >
            <Input.Password ref={ref => ymProtectInput(ref, passwordRef)} size="large" disabled={loading} />
          </Form.Item>
        )}

        {includePaymentPassword && (
          <Form.Item
            name="paymentPassword"
            validateFirst
            label={t('form.label.currentPaymentPassword')}
            rules={rules.paymentPassword}
            className="np-authentication-form__input-block"
          >
            <Input.Password ref={ref => ymProtectInput(ref, payPasswordRef)} size="large" disabled={loading} />
          </Form.Item>
        )}

        {includeGa && (
          <Form.Item
            name="totp"
            validateFirst
            label={t('form.label.gaCode')}
            rules={rules.ga}
            className="np-authentication-form__input-block"
          >
            <Input ref={ref => ymProtectInput(ref, totpRef)} size="large" disabled={loading} />
          </Form.Item>
        )}

        {includeCaptcha && (
          <Form.Item name="captchaV2" validateFirst rules={[rules.requiredWithMessage('validation.сaptcha.required')]}>
            <Captcha />
          </Form.Item>
        )}

        <FormButtons
          showGoBack={showGoBack}
          submitDisabled={submitDisabled}
          loading={loading}
          marginTop={12}
          onGoBack={onGoBack}
        />
      </Form>

      <ForgotLinks
        includeGa={includeGa}
        includePaymentPassword={includePaymentPassword}
        className="np-authentication-form__links"
      />
    </div>
  );
};

export default AuthenticationForm;
