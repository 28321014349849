import React from 'react';
import { Tabs } from 'antd';

import CardTabsBar from './components/CardTabsBar';

import './CardTabs.styles.scss';

const CardTabs = ({ items = [], stretch, className = '', ...props }) => {
  const defaultActiveKey = items.length ? items[0].key : null;

  const stretchClass = stretch ? 'np-card-tabs--stretch' : '';

  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      type="card"
      items={items}
      renderTabBar={props => <CardTabsBar items={items} activeKey={props.activeKey} onTabClick={props.onTabClick} />}
      className={`np-card-tabs ${stretchClass} ${className}`}
      {...props}
    />
  );
};

export default CardTabs;
