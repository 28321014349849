import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TimerButton } from 'modules';

import useNotifications from 'hooks/common/useNotifications';

const REFETCH_TIME = 60 * 1000; // 60 s

const RefetchTimer = ({ refetch = async () => {}, ...props }) => {
  const { t } = useTranslation();
  const { notificationApi } = useNotifications();

  const [timerMode, setTimerMode] = useState(true);
  const [loading, setLoading] = useState(false);

  const resendCode = async () => {
    setLoading(true);

    const { success, errorMessage, error } = await refetch();

    if (success) {
      notificationApi.success({ message: t('notification.success.resendVerificationCode') });
      setTimerMode(true);
      setLoading(false);
    } else {
      notificationApi.success({ message: t(errorMessage) });
      return { error };
    }
  };

  return (
    <TimerButton
      ms={REFETCH_TIME}
      loading={loading}
      timerMode={timerMode}
      onClick={resendCode}
      onChangeTimerMode={setTimerMode}
      {...props}
    >
      {t('form.button.resetCode')}
    </TimerButton>
  );
};

export default RefetchTimer;
