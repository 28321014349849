import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { WarningOutlined } from '@ant-design/icons';

import { BaseModal } from 'components';

import formatDate from 'utils/date/formatDate';
import usePaymentBlock from 'hooks/payment/usePaymentBlock';

import { DATE_DOT_FORMAT_WITH_TIME } from 'constants/date';

import './PaymentBlockModal.styles.scss';

const { Text } = Typography;

const PaymentBlockModal = ({ open, onClose = () => {}, className = '', ...props }) => {
  const { t } = useTranslation();
  const { unlockDate } = usePaymentBlock();

  const formatedUnlockDate = formatDate(unlockDate, { format: DATE_DOT_FORMAT_WITH_TIME, utc: false, ms: true });

  return (
    <BaseModal
      title={t('modal.paymentBlock.title')}
      open={open}
      width={500}
      onCancel={onClose}
      className={`np-payment-block-modal ${className}`}
      icon={<WarningOutlined />}
      {...props}
    >
      <Text>
        {t('modal.paymentBlock.desc', {
          unlockDate: formatedUnlockDate,
          interpolation: { escapeValue: false },
        })}
      </Text>

      <Text>{t('modal.paymentBlock.disabledOperations')}</Text>

      <ul className="np-payment-block-modal__list">
        <li>{t('modal.paymentBlock.operations.withdrawal')}</li>
        <li>{t('modal.paymentBlock.operations.autoWithdrawal')}</li>
        <li>{t('modal.paymentBlock.operations.settingsChange')}</li>
        <li>{t('modal.paymentBlock.operations.walletChange')}</li>
      </ul>

      <Text>{t('modal.paymentBlock.thanks')}</Text>
    </BaseModal>
  );
};

export default PaymentBlockModal;
