import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { QuestionIcon } from 'assets/images';
import InfoItem from './components/InfoItem';
import { Loading, BlockWrapper, InfoBlock } from 'components';

import './SummaryBlock.styles.scss';

const SummaryBlock = ({
  loading,
  title,
  rightHeaderComponent = null,
  items = [],
  children,
  itemsBottom,
  noBottomLine,
  titleThin,
  titleTooltip,
  inline,
  footer,
  className = '',
  ...props
}) => {
  const { t } = useTranslation();

  const inlineClass = inline ? 'np-summary-block--inline' : '';

  return (
    <BlockWrapper className={`np-summary-block ${inlineClass} ${className}`} {...props}>
      <div className="np-summary-block__header">
        <div className="np-summary-block__header-title">
          <BlockWrapper.Title>{t(title)}</BlockWrapper.Title>

          {titleTooltip && (
            <Tooltip title={titleTooltip}>
              <QuestionIcon />
            </Tooltip>
          )}
        </div>

        {rightHeaderComponent}
      </div>

      <Loading isEmpty={(!items || !items.length) && !children} isLoading={loading}>
        <InfoBlock>
          {itemsBottom && children}

          {items.map((item, index) => (
            <InfoBlock.Items key={index} noBottomLine={noBottomLine}>
              {item.map(subItem => (
                <InfoItem key={subItem.title + Math.random()} titleThin={titleThin} {...subItem} />
              ))}
            </InfoBlock.Items>
          ))}

          {!itemsBottom && children}
        </InfoBlock>

        {footer}
      </Loading>
    </BlockWrapper>
  );
};

export default SummaryBlock;
