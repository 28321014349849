import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import useInternetConnection from 'hooks/common/useInternetConnection';
import { addInternalNotification, removeInternalNotification } from 'store/reducers/internalNotifications';

import { MessageType } from '../../constants/types';
import { InAppNotificationItems } from '../../constants/notifications';
import { InternalNotificationsTypes } from 'constants/internalNotifications';

const useNoInternetNotifications = () => {
  const dispatch = useDispatch();

  const type = InternalNotificationsTypes.IN_APP;

  const onlineCallback = useCallback(() => {
    dispatch(removeInternalNotification({ id: MessageType.NO_INTERNET, type }));
  }, [dispatch, type]);

  const offlineCallback = useCallback(() => {
    const item = InAppNotificationItems[MessageType.NO_INTERNET];
    dispatch(addInternalNotification({ item, type }));
  }, [dispatch, type]);

  useInternetConnection({ onlineCallback, offlineCallback });
};

export default useNoInternetNotifications;
