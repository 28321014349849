import { useDispatch } from 'react-redux';

import useInAppNotifications from './useInAppNotifications';
import { removeInternalNotification } from 'store/reducers/internalNotifications';

import { InternalNotificationsTypes } from 'constants/internalNotifications';
import useUserNotifications from './useUserNotifications';

const useInternalNotifications = (disabledTypes = []) => {
  const dispatch = useDispatch();

  const userTypeDisabled = disabledTypes.includes(InternalNotificationsTypes.USER);
  const inAppTypeDisabled = disabledTypes.includes(InternalNotificationsTypes.IN_APP);

  const { inAppNotifications } = useInAppNotifications({ disabled: inAppTypeDisabled });
  const { userNotifications, loading } = useUserNotifications({ disabled: userTypeDisabled });

  const onDelete = (id, isInApp) => {
    const type = isInApp ? InternalNotificationsTypes.IN_APP : InternalNotificationsTypes.USER;

    dispatch(removeInternalNotification({ id, type }));
  };

  const mergeNotifications = () => {
    let notifications = [];

    if (!inAppTypeDisabled) {
      notifications = [...notifications, ...inAppNotifications];
    }

    if (!userTypeDisabled) {
      notifications = [...notifications, ...userNotifications];
    }

    return notifications;
  };

  return { notifications: mergeNotifications(), loading, onDelete };
};

export default useInternalNotifications;
