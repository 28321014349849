import React from 'react';
import { Switch } from 'antd';
import { MoonIcon, SunIcon } from 'assets/images';

import useTheme from 'hooks/common/useTheme';

import { ThemeMode } from 'constants/theme';

import './ThemeSwitcher.styles.scss';

const ThemeSwitcher = () => {
  const { changeTheme, isDarkMode } = useTheme();

  const changeThemeMode = checked => {
    if (checked) {
      changeTheme(ThemeMode.LIGHT);
    } else {
      changeTheme(ThemeMode.DARK);
    }
  };

  return (
    <Switch
      checkedChildren={<SunIcon />}
      unCheckedChildren={<MoonIcon />}
      defaultChecked={!isDarkMode}
      onChange={changeThemeMode}
      className="np-theme-switcher"
    />
  );
};

export default ThemeSwitcher;
