import React from 'react';
import { Collapse } from 'antd';
import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';

import './Accordion.styles.scss';

const { Panel } = Collapse;

const Accordion = ({ header, children, divider, size = 'large', defaultActive, className = '', ...props }) => {
  const expandIcon = ({ isActive }) => (isActive ? <MinusCircleFilled /> : <PlusCircleFilled />);

  const sizeClass = size === 'large' ? 'np-large' : 'np-small';
  const dividerClass = divider ? 'np-divided' : '';

  const defaultActiveKey = defaultActive ? ['collapse-key'] : null;

  return (
    <Collapse
      size="large"
      bordered={false}
      expandIcon={expandIcon}
      expandIconPosition="end"
      defaultActiveKey={defaultActiveKey}
      className={`np-accordion ${dividerClass} ${className}`}
      {...props}
    >
      <Panel header={header} className={sizeClass} key="collapse-key">
        {children}
      </Panel>
    </Collapse>
  );
};

export default Accordion;
