import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';

import { Button } from 'components';

import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import './EditButton.styles.scss';

const EditButton = ({ ...props }) => {
  const { t } = useTranslation();
  const { isMobileView } = useWindowResponsiveness(700);

  return (
    <Button size="middle" type="default" shape={isMobileView ? 'circle' : 'round'} icon={<EditOutlined />} {...props}>
      {!isMobileView && t('form.button.edit')}
    </Button>
  );
};

export default EditButton;
