import divideNumberByPlaces from 'utils/common/divideNumberByPlaces';
import roundBtcToSatoshi from './roundBtcToSatoshi';

const formatBtcAmount = (value, options = {}) => {
  const { round = true } = options;

  const roundedValue = round ? roundBtcToSatoshi(+value) : +value || 0;

  return divideNumberByPlaces(roundedValue, '');
};

export default formatBtcAmount;
