import React from 'react';
import { Result as AntdResult } from 'antd';

import './Result.styles.scss';

const Result = ({ className = '', ...props }) => {
  return <AntdResult className={`np-result ${className}`} {...props} />;
};

export default Result;
