import { useCallback, useEffect } from 'react';

const useInternetConnection = ({ onlineCallback = () => {}, offlineCallback = () => {} }) => {
  const checkInternetOnLoad = useCallback(() => {
    navigator.onLine ? onlineCallback() : offlineCallback();
  }, [offlineCallback, onlineCallback]);

  useEffect(() => {
    window.addEventListener('online', onlineCallback);
    window.addEventListener('offline', offlineCallback);
    window.addEventListener('load', checkInternetOnLoad);

    return () => {
      window.removeEventListener('online', onlineCallback);
      window.removeEventListener('offline', offlineCallback);
      window.removeEventListener('load', checkInternetOnLoad);
    };
  }, [checkInternetOnLoad, offlineCallback, onlineCallback]);
};

export default useInternetConnection;
