import { useEffect } from 'react';

const useAutoFocus = ref => {
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }

    // move cursor to the end of textarea after focusing
    const textArea = ref.current?.resizableTextArea?.textArea;

    if (textArea) {
      const value = textArea.value;
      textArea.value = '';
      textArea.value = value;
    }
  }, [ref]);
};

export default useAutoFocus;
