import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { BtcAddressLabel } from 'constants/wallet';

import './WalletTooltip.styles.scss';

const { Text } = Typography;

const WalletTooltip = () => {
  const { t } = useTranslation();

  const types = Object.values(BtcAddressLabel);

  return (
    <div className="np-wallet-label-tooltip">
      <Text>{t('tooltip.allowedWalletTypes')}</Text>

      <ul className="np-wallet-label-tooltip__list">
        {types.map(type => (
          <li key={type}>
            <Text>{type}</Text>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WalletTooltip;
