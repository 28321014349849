import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu } from 'antd';

import './DesktopNavigationMenu.styles.scss';

const DesktopNavigationMenu = ({ items = [], onClick = () => {} }) => {
  const location = useLocation();

  const [current, setCurrent] = useState(location.pathname);

  const onMenuClick = e => {
    setCurrent(e.key);
    onClick(e.key);
  };

  useEffect(() => {
    setCurrent(location.pathname);
  }, [location]);

  return (
    <Menu
      mode="horizontal"
      items={items}
      onClick={onMenuClick}
      selectedKeys={[current]}
      className="np-desktop-navigation-menu"
    />
  );
};

export default DesktopNavigationMenu;
