import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserMenu as BaseUserMenu } from 'components';

import { useUserMenuOptions } from './hooks/useUserMenuOptions';
import { setSelectedAccountId } from 'store/reducers/watcher/accounts';

import './UserMenu.styles.scss';

const UserMenu = () => {
  const { watcher } = useSelector(state => state.watcherAuth);
  const { selectedAccountId, accountsList } = useSelector(state => state.watcherAccounts);

  const dispatch = useDispatch();

  const accountName = useMemo(
    () => accountsList.find(account => account.id === selectedAccountId)?.account_name || '',
    [accountsList, selectedAccountId],
  );

  const { options, loading } = useUserMenuOptions(accountName);

  const onMenuClick = ({ key }) => {
    const isCurrentAccount = key === String(selectedAccountId);

    if (!isCurrentAccount) {
      dispatch(setSelectedAccountId(+key));
    }
  };

  return watcher ? (
    <BaseUserMenu
      name={accountName}
      options={options}
      loading={loading}
      onOptionClick={onMenuClick}
      menuClassName="np-user-menu__dropdown"
    />
  ) : null;
};

export default UserMenu;
