import React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';

import { DefaultColors, PRIMARY_COLOR } from 'constants/colors';

const useWorkerStatusItems = workersCount => {
  const { t } = useTranslation();

  const { wks_active = 0, wks_inactive = 0, wks_offline = 0 } = workersCount || {};

  return [
    {
      tooltip: t('accountsSummary.activeWorkers'),
      value: wks_active,
      icon: <CheckCircleFilled style={{ color: PRIMARY_COLOR }} />,
    },

    {
      tooltip: t('accountsSummary.inactiveWorkers'),
      value: wks_inactive,
      icon: <ExclamationCircleFilled style={{ color: DefaultColors.DARK_CERULEAN }} />,
    },

    {
      tooltip: t('accountsSummary.offlineWorkers'),
      value: wks_offline,
      icon: <CloseCircleFilled style={{ color: DefaultColors.SPANISH_GRAY }} />,
    },
  ];
};

export default useWorkerStatusItems;
