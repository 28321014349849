import merge from 'lodash/merge';

const fetchJSON = async (url, options = {}) => {
  const isJson = options.isJson ?? true;

  const jsonOptions = merge(
    {
      headers: isJson ? { 'Content-Type': 'application/json' } : {},
    },
    options,
  );

  return fetch(url, jsonOptions)
    .then(response => {
      return getResponseBody(response).then(body => ({
        response,
        body,
      }));
    })
    .then(checkStatus);
};

const getResponseBody = response => {
  const contentType = response.headers.get('content-type');
  return contentType && contentType.indexOf('json') >= 0
    ? response.clone().text().then(tryParseJSON)
    : response.clone().text();
};

const tryParseJSON = json => {
  if (!json) {
    return null;
  }

  try {
    return JSON.parse(json);
  } catch (e) {
    throw new Error(`Failed to parse unexpected JSON response: ${json}`);
  }
};

function ResponseError(status, response, body) {
  this.name = 'ResponseError';
  this.status = status;
  this.response = response;
  this.body = body;
}

ResponseError.prototype = Error.prototype;

const checkStatus = ({ response, body }) => {
  if (response.ok) {
    return body;
  } else {
    throw new ResponseError(response.status, response, body);
  }
};

export default fetchJSON;
