import React from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

const ChangePasswordWarning = () => {
  const { t } = useTranslation();

  return <Alert type="warning" message={t('accountSettings.accountSecurity.form.paymentPassword.change.warning')} />;
};

export default ChangePasswordWarning;
