import React, { useState } from 'react';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Image } from 'antd';

import { NoImageLight, NoImageDark } from 'assets/images';
import { ActionIconButton } from 'components';

import useTheme from 'hooks/common/useTheme';
import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import { ActionIconColors } from 'components/buttons/ActionIconButton';

import './FilesList.styles.scss';

const FilesList = ({ files = [], onDelete = () => {} }) => {
  const { isDarkMode } = useTheme();
  const { isMobileView } = useWindowResponsiveness(700);

  const [previewImg, setPreviewImg] = useState(null);

  const onVisibleChange = visible => {
    if (!visible) {
      setPreviewImg(null);
    }
  };

  return (
    <div className="np-reset-process-identif-verify__files-list">
      {files.map(({ url }) => (
        <Image
          key={url}
          src={url}
          width={isMobileView ? 100 : 170}
          height={isMobileView ? 100 : 170}
          fallback={isDarkMode ? NoImageDark : NoImageLight}
          preview={{
            visible: previewImg === url,
            onVisibleChange,
            mask: (
              <div className="np-reset-process-identif-verify__files-mask">
                <ActionIconButton
                  icon={<EyeOutlined />}
                  color={ActionIconColors.GRAY}
                  onClick={() => setPreviewImg(url)}
                />

                <ActionIconButton
                  icon={<DeleteOutlined />}
                  color={ActionIconColors.GRAY}
                  onClick={() => onDelete(url)}
                />
              </div>
            ),
          }}
        />
      ))}
    </div>
  );
};

export default FilesList;
