import React from 'react';

import Confirmation from '../../steps/Confirmation';
import AccountVerification from '../../steps/AccountVerification';
import SecurityVerification from '../../steps/SecurityVerification';
import IdentifyVerification from '../../steps/IdentifyVerification';
import Result from '../../steps/Result';

import { DefaultNextStep, DefaultSteps } from '../../../constants/steps';

const SwitchSteps = ({ step, onChangeStep = () => {} }) => {
  const { key, Component = null, props = {}, nextStep } = step || {};
  const { onFinish: onFinishFn, ...otherProps } = props || {};

  const onFinish = values => {
    const next = nextStep || DefaultNextStep[step.key];

    if (onFinishFn) {
      onFinishFn(values);
    }

    if (next) {
      onChangeStep(next);
    }
  };

  if (Component) {
    return <Component onFinish={onFinish} {...otherProps} />;
  }

  switch (key) {
    case DefaultSteps.CONFIRMATION:
      return <Confirmation onFinish={onFinish} {...otherProps} />;

    case DefaultSteps.ACCOUNT_VERIFICATION:
      return <AccountVerification onFinish={onFinish} {...otherProps} />;

    case DefaultSteps.SECURITY_VERIFICATION:
      return <SecurityVerification onFinish={onFinish} {...otherProps} />;

    case DefaultSteps.IDENTITY_VERIFICATION:
      return <IdentifyVerification onFinish={onFinish} {...otherProps} />;

    case DefaultSteps.RESULT:
      return <Result onFinish={onFinish} {...otherProps} />;

    default:
      return null;
  }
};

export default SwitchSteps;
