import React, { useEffect, useState } from 'react';
import { Space, Switch, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReloadOutlined } from '@ant-design/icons';

import { Button, Select } from 'components';

import useIntervalSelectOptions from './hooks/useIntervalSelectOptions';
import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import { SelectVariant } from 'components/selects/Select';

import './RefreshActions.styles.scss';

const { Text } = Typography;

const RefreshActions = ({ defaultValue = true, size = 'middle', noWrap, disabled, onRefresh = () => {} }) => {
  const { t } = useTranslation();
  const { isMobileView } = useWindowResponsiveness(700);

  const options = useIntervalSelectOptions();

  const [autorefresh, setAutorefresh] = useState(defaultValue);
  const [intervalId, setIntervalId] = useState(null);
  const [intervalMs, setIntervalMs] = useState(options[options.length - 1].value);

  const onAutoRefreshChange = value => {
    if (value) {
      const intervalId = setInterval(onRefresh, intervalMs);
      setIntervalId(intervalId);
    } else {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    setAutorefresh(value);
  };

  const onIntervalChange = ms => {
    setIntervalMs(ms);
    clearInterval(intervalId);

    const newIntervalId = setInterval(onRefresh, ms);
    setIntervalId(newIntervalId);
  };

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  useEffect(() => {
    if (!autorefresh) {
      return;
    }

    if (intervalId) {
      clearInterval(intervalId);
    }

    const newIntervalId = setInterval(onRefresh, intervalMs);
    setIntervalId(newIntervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRefresh]);

  return (
    <Space size={10} className="np-refresh-action" style={{ flexWrap: noWrap && 'nowrap' }}>
      <Space>
        <Switch checked={autorefresh} disabled={disabled} onChange={onAutoRefreshChange} />
        <Text>{t('form.button.autorefresh')}</Text>
      </Space>

      <Select
        size={size}
        value={intervalMs}
        options={options}
        popupMatchSelectWidth={false}
        onSelect={onIntervalChange}
        disabled={!autorefresh || disabled}
        variant={SelectVariant.BORDERED}
        width={120}
      />

      <Button
        size={size}
        type="primary"
        disabled={disabled}
        icon={<ReloadOutlined />}
        onClick={onRefresh}
        style={{ minWidth: isMobileView ? 0 : 120 }}
      >
        {isMobileView ? '' : t('form.button.refresh')}
      </Button>
    </Space>
  );
};

export default RefreshActions;
