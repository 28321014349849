import React from 'react';

import { Loading } from 'components';

import './ListBlock.styles.scss';

const ListBlock = ({
  header,
  height,
  maxWidth,
  loading,
  error,
  empty,
  children,
  style = {},
  className = '',
  ...props
}) => {
  const errorClass = error ? 'np-list-block--error' : '';

  return (
    <div className={`np-list-block ${errorClass} ${className}`} style={{ maxWidth, ...style }} {...props}>
      {header && <div className="np-list-block__header">{header}</div>}

      <div className="np-list-block__body" style={{ height }}>
        <Loading isLoading={loading} isEmpty={empty}>
          {children}
        </Loading>
      </div>

      <div className="np-list-block__overlay" style={{ display: loading ? 'block' : 'none' }} />
    </div>
  );
};

export default ListBlock;
