export const NotificationType = {
  CLOSABLE: 'MD',
  NOT_CLOSABLE: 'NMD',
};

export const MessageType = {
  RESET_TWO_FA: 'reset2fa',
  NO_INTERNET: 'noInternetConnection',
  PAYMENT_BLOCK: 'paymentBlock',
};
