export const CryptoNetwork = {
  MAIN_NET: 'mainnet',
  TEST_NET: 'testnet',
  REG_TEST: 'regtest',
};

export const BtcAddressType = {
  P2PKH: 'p2pkh',
  P2SH: 'p2sh',
  P2WPKH: 'p2wpkh',
  P2TR: 'p2tr',
  P2WSH: 'p2wsh',
};

export const BtcAddressLabel = {
  [BtcAddressType.P2PKH]: 'P2PKH (legacy)',
  [BtcAddressType.P2SH]: 'P2SH-P2WPKH (segwit)',
  [BtcAddressType.P2WPKH]: 'P2WPKH (segwit bech32)',
  [BtcAddressType.P2WSH]: 'P2WSH (segwit bech32)',
  [BtcAddressType.P2TR]: 'P2TR (taproot bech32m)',
};
