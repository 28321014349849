import { apiUrls } from 'constants/urls';
import request from './request.service';
import asyncErrorHandler from 'utils/requests/errorHandler';
import filterEmptyParams from 'utils/params/filterEmptyParams';

export const getApiKeys = async ({ page, pageSize }) => {
  const callback = async () => {
    return await request(apiUrls.apiManagement.apiKeys, {
      params: filterEmptyParams({ page, per_page: pageSize }),
    });
  };

  return await asyncErrorHandler(callback);
};

export const createApiKey = async ({ accessLevel, note, totp }) => {
  const callback = async () => {
    return await request(apiUrls.apiManagement.apiKeys, {
      method: 'POST',
      body: filterEmptyParams({ access_level: accessLevel, note, otp_2fa: totp }),
    });
  };

  return await asyncErrorHandler(callback, {
    errors: {
      keyLimitReached: 'requestErrors.apiKeys.keyLimitReached',
      accessLevelNotPermitted: 'requestErrors.apiKeys.accessLevelNotPermitted',
    },
  });
};

export const editApiKey = async ({ id, note }) => {
  const callback = async () => {
    return await request(apiUrls.apiManagement.apiKeys, {
      method: 'PUT',
      body: filterEmptyParams({ id, note }),
    });
  };

  return await asyncErrorHandler(callback);
};

export const removeApiKeys = async ({ ids, totp }) => {
  const callback = async () => {
    return await request(apiUrls.apiManagement.apiKeys, {
      method: 'DELETE',
      body: filterEmptyParams({ id_list: ids, otp_2fa: totp }),
    });
  };

  return await asyncErrorHandler(callback);
};
