import React from 'react';
import { Navigate } from 'react-router-dom';

import { Loading } from 'components';

import UrlRestoringService from 'services/urlRestoring.service';
import { useCheckUserAuth } from '../utils/hooks/useCheckUserAuth';

import { publicUrls } from 'constants/urls';

const ProtectedPrivateContainer = ({
  requiredRoles,
  requiredAnyRoles,
  redirectOnFail = publicUrls.login,
  redirectOnAuthorizationFail = publicUrls.main,
  saveUrlOnFail,
  fallback,
  children,
}) => {
  const { loading, isAuth, isAuthorized } = useCheckUserAuth({ redirectOnFail, requiredRoles, requiredAnyRoles });

  const renderAuthFailure = () => {
    if (saveUrlOnFail) {
      UrlRestoringService.setUrl(window.location.pathname);
    }

    if (redirectOnFail) {
      return <Navigate to={redirectOnFail} />;
    }

    return fallback || null;
  };

  if (loading) {
    return <Loading isLoading fullScreen />;
  }

  if (!isAuth) {
    return renderAuthFailure();
  }

  if (!isAuthorized && redirectOnAuthorizationFail) {
    return <Navigate to={redirectOnAuthorizationFail} />;
  }

  if (!isAuthorized) {
    return renderAuthFailure();
  }

  return children;
};

export default ProtectedPrivateContainer;
