import React, { useState } from 'react';
import { Steps as AntSteps } from 'antd';
import { useTranslation } from 'react-i18next';

import { Loading, BlockWrapper } from 'components';
import SwitchSteps from './components/common/SwitchSteps';
import ErrorMessage from './components/common/ErrorMessage';

import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import { DefaultStepLabels } from './constants/steps';

import './ResetProcess.styles.scss';

const ResetProcess = ({ title, steps = [], error, loading }) => {
  const { t } = useTranslation();
  const { isMobileView } = useWindowResponsiveness(1000);

  const [currentStep, setCurrentStep] = useState(steps[0]);

  const currentStepNumber = steps.findIndex(step => step.key === currentStep.key);

  const stepsItems = steps.map(step => ({ key: step.key, title: t(step.title || DefaultStepLabels[step.key]) }));

  const onChangeStep = stepKey => {
    const step = steps.find(step => step.key === stepKey);

    setCurrentStep(step);
  };

  return (
    <BlockWrapper className="np-reset-process">
      <BlockWrapper.Title size="large">{title}</BlockWrapper.Title>

      <div className="np-reset-process__content">
        <Loading isLoading={loading}>
          {error ? (
            <ErrorMessage title={error?.title} subTitle={error?.subTitle} />
          ) : (
            <>
              <AntSteps
                labelPlacement={isMobileView ? 'vertical' : 'horizontal'}
                current={currentStepNumber}
                items={stepsItems}
              />

              <SwitchSteps step={currentStep} onChangeStep={onChangeStep} />
            </>
          )}
        </Loading>
      </div>
    </BlockWrapper>
  );
};

export default ResetProcess;
