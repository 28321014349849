import React from 'react';
import { Tooltip, Typography } from 'antd';

import useWorkerStatusItems from '../../../../hooks/useWorkerStatusItems';

import './WorkersStatus.styles.scss';

const { Text } = Typography;

const WorkersStatus = ({ data }) => {
  const workersItems = useWorkerStatusItems(data);

  return (
    <div className="np-mobile-accounts-summary__workers">
      {workersItems.map(item => (
        <Tooltip title={item.tooltip} key={item.tooltip}>
          <div className="np-mobile-accounts-summary__workers-item">
            <div className="np-mobile-accounts-summary__workers-icon">{item.icon}</div>

            <Text className="np-mobile-accounts-summary__workers-value">{item.value}</Text>
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default WorkersStatus;
