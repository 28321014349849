import { useCallback, useRef, useState } from 'react';
import useEventListener from 'hooks/elements/useEventListener';

const useElementDimensions = () => {
  const ref = useRef(null);
  const [size, setSize] = useState(null);

  const refresh = useCallback(() => {
    const domRect = ref.current?.getBoundingClientRect();

    if (domRect) {
      setSize(domRect);
    }
  }, []);

  useEventListener('resize', refresh);
  useEventListener('scroll', refresh, true);

  return { size, ref, refresh };
};

export default useElementDimensions;
