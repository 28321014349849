import React from 'react';
import { Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { BooleanLabel } from 'components';

import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import { PRIMARY_COLOR } from 'constants/colors';

import './LabelWithTag.styles.scss';

const { Text } = Typography;

const activationLabels = { bool_true: 'common.on', bool_false: 'common.off' };

const LabelWithTag = ({ title, value, isActive, formatter = () => {} }) => {
  const { t } = useTranslation();
  const { isMobileView } = useWindowResponsiveness(700);

  return isMobileView ? (
    <div className="np-mining-notifications-table__label-wrapper">
      <Text>{t(title)}</Text>

      <div className="np-mining-notifications-table__text-wrapper">
        <Text className="np-mining-notifications-table__text--primary">
          {value || value === 0 ? formatter(value) : t('common.notSet').toLowerCase()}
        </Text>

        <Tag bordered={false} color={isActive && PRIMARY_COLOR}>
          <BooleanLabel value={isActive} labels={activationLabels} />
        </Tag>
      </div>
    </div>
  ) : (
    <div className="np-mining-notifications-table__label-wrapper">
      <Tag bordered={false} color={isActive && PRIMARY_COLOR}>
        <BooleanLabel value={isActive} labels={activationLabels} />
      </Tag>

      <div className="np-mining-notifications-table__text-wrapper">
        <Text style={{ textAlign: 'center' }}>{t(title)}</Text>

        <Text className="np-mining-notifications-table__text--primary" style={{ whiteSpace: 'nowrap' }}>
          {value || value === 0 ? formatter(value) : t('common.notSet').toLowerCase()}
        </Text>
      </div>
    </div>
  );
};

export default LabelWithTag;
