import React, { useEffect } from 'react';
import { Form as AntForm } from 'antd';
import { useTranslation } from 'react-i18next';

import './Form.styles.scss';

const Form = ({ form, children, className = '', ...props }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const translateErrors = () => {
      const errorFields = form
        .getFieldsError()
        .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);

      form.validateFields(errorFields);
    };

    translateErrors();
  }, [form, t]);

  return (
    <AntForm form={form} className={`np-form ${className}`} {...props}>
      {children}
    </AntForm>
  );
};

Form.useForm = AntForm.useForm;
Form.useFormInstance = AntForm.useFormInstance;
Form.useWatch = AntForm.useWatch;
Form.Item = AntForm.Item;
Form.List = AntForm.List;
Form.ErrorList = AntForm.ErrorList;
Form.Provider = AntForm.Provider;
Form.create = AntForm.create;

export default Form;
