import { useSelector } from 'react-redux';

import useNoInternetNotifications from './in-app-notifications/useNoInternetNotifications';

const useInAppNotifications = (options = {}) => {
  const { disabled } = options;
  const { inAppNotifications } = useSelector(state => state.internalNotifications);

  if (!disabled) {
    useNoInternetNotifications();
  }

  return { inAppNotifications };
};

export default useInAppNotifications;
