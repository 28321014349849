import React from 'react';

import { Spin } from 'components';

import './BackgroundLoader.styles.scss';

const BackgroundLoader = () => {
  return (
    <div className="np-background-loader">
      <Spin size="large" />
    </div>
  );
};

export default BackgroundLoader;
