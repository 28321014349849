import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as MenuLogo } from 'assets/images/svg/logoDark.svg';
import { LogoLightIcon } from 'assets/images';

import useTheme from 'hooks/common/useTheme';
import useUserData from 'hooks/user/useUserData';

import { publicUrls } from 'constants/urls';

import './Logo.styles.scss';

const Logo = ({ url }) => {
  const { isDarkMode } = useTheme();
  const { user } = useUserData();

  const logoUrl = useMemo(() => {
    if (url) {
      return url;
    }

    if (user) {
      return publicUrls.profile.main;
    }

    return publicUrls.main;
  }, [url, user]);

  return (
    <NavLink to={logoUrl} className="np-user-layout-logo">
      {isDarkMode ? <MenuLogo /> : <LogoLightIcon />}
    </NavLink>
  );
};

export default Logo;
