import * as echarts from 'echarts';
import { DarkColors, DefaultColors, LightColors } from 'constants/colors';
import { DefaultFontFamily } from 'constants/font';

echarts.registerTheme('dark', {
  backgroundColor: DarkColors.MAIN_BG_COLOR_2,

  textStyle: {
    color: DefaultColors.SPANISH_GRAY,
    fontFamily: DefaultFontFamily,
  },

  legend: {
    textStyle: {
      color: DarkColors.MAIN_TEXT_COLOR,
      inactiveColor: '#494949',
      fontFamily: DefaultFontFamily,
    },
  },
});

echarts.registerTheme('light', {
  backgroundColor: LightColors.MAIN_BG_COLOR_2,

  textStyle: {
    color: DefaultColors.SPANISH_GRAY,
    fontFamily: DefaultFontFamily,
  },

  legend: {
    textStyle: {
      color: LightColors.MAIN_TEXT_COLOR,
      inactiveColor: '#494949',
      fontFamily: DefaultFontFamily,
    },
  },
});
