import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import StepLayout from '../../common/StepLayout';
import Requirements from './components/Requirements';
import IdentifyVerificationForm from './components/IdentifyVerificationForm';

import useCaptcha from 'hooks/forms/useCaptcha';
import useNotifications from 'hooks/common/useNotifications';

const IdentifyVerification = ({ includeCaptcha, onFinish = () => {}, requestCallback = async () => {}, ...props }) => {
  const { t } = useTranslation();
  const { notificationApi } = useNotifications();

  const { isCaptchaError, checkCaptchaError, getCaptchaParams } = useCaptcha('securityVerification');

  const showCaptcha = includeCaptcha && isCaptchaError;

  const [loading, setLoading] = useState(false);

  const handleFinish = async ({ captchaV2, ...values }) => {
    setLoading(true);

    const captchaParams = getCaptchaParams(captchaV2);

    const { success, errorMessage, error } = await requestCallback({ ...values, ...captchaParams });

    if (success) {
      onFinish(values);
    } else {
      checkCaptchaError(error);
      notificationApi.error({ message: t(errorMessage) });
    }

    setLoading(false);
  };

  return (
    <StepLayout
      title={t('resetProcess.steps.identityVerification.content.title')}
      description={t('resetProcess.steps.identityVerification.content.desc')}
    >
      <Requirements />
      <IdentifyVerificationForm loading={loading} onFinish={handleFinish} includeCaptcha={showCaptcha} {...props} />
    </StepLayout>
  );
};

export default IdentifyVerification;
