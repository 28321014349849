const WALLET = 'wallet';
const PROFILE = 'profile';
const SETTINGS = 'settings';
const ACCOUNT_MANAGEMENT = 'accountManagement';
const API_MANAGEMENT = 'apiManagement';
const OBSERVER = 'observer';

export const publicUrls = {
  main: '/',
  singUp: '/signUp',
  login: '/login',
  logout: '/logout',
  emailConfirm: '/registration',
  forgotPassword: '/forgotPassword',
  resetPassword: '/resetPassword',
  vipApplication: '/vipApplication',
  betaTestApplication: '/betaTestApplication',
  referralRewardsIntroduction: '/referralRewardsIntroduction',
  resetGa: '/resetGa',

  profile: {
    main: `/${PROFILE}`,

    settings: {
      main: `/${PROFILE}/${SETTINGS}`,
      mining: `/${PROFILE}/${SETTINGS}/miningSettings`,
      payment: `/${PROFILE}/${SETTINGS}/paymentSettings`,
      account: `/${PROFILE}/${SETTINGS}/accountSettings`,
    },

    accountManagement: {
      main: `/${PROFILE}/${ACCOUNT_MANAGEMENT}`,
      watcher: `/${PROFILE}/${ACCOUNT_MANAGEMENT}/watcher`,
      miningAccounts: `/${PROFILE}/${ACCOUNT_MANAGEMENT}/miningAccounts`,
      notifications: `/${PROFILE}/${ACCOUNT_MANAGEMENT}/notifications`,
    },

    apiManagement: {
      main: `/${PROFILE}/${API_MANAGEMENT}`,
      apiKeys: `/${PROFILE}/${API_MANAGEMENT}/apiKeys`,
    },

    wallet: {
      main: `/${PROFILE}/${WALLET}`,
      myBills: `/${PROFILE}/${WALLET}/myBills`,
      depositAndWithdrawal: `/${PROFILE}/${WALLET}/depositAndWithdrawal`,
    },

    workers: `/${PROFILE}/workers`,
    referralRewards: `/${PROFILE}/referralRewards`,
    resetPaymentPassword: `/${PROFILE}/resetPaymentPassword`,
  },

  observer: {
    main: `/${OBSERVER}`,
    invalidWatcherLink: `/${OBSERVER}/invalidWatcherLink`,

    profile: {
      main: `/${OBSERVER}/${PROFILE}`,

      settings: {
        main: `/${OBSERVER}/${PROFILE}/${SETTINGS}`,
        mining: `/${OBSERVER}/${PROFILE}/${SETTINGS}/miningSettings`,
        payment: `/${OBSERVER}/${PROFILE}/${SETTINGS}/paymentSettings`,
        account: `/${OBSERVER}/${PROFILE}/${SETTINGS}/accountSettings`,
      },

      accountManagement: {
        main: `/${OBSERVER}/${PROFILE}/${ACCOUNT_MANAGEMENT}`,
        watcher: `/${OBSERVER}/${PROFILE}/${ACCOUNT_MANAGEMENT}/watcher`,
        miningAccounts: `/${OBSERVER}/${PROFILE}/${ACCOUNT_MANAGEMENT}/miningAccounts`,
        notifications: `/${OBSERVER}/${PROFILE}/${ACCOUNT_MANAGEMENT}/notifications`,
        apiManagement: `/${OBSERVER}/${PROFILE}/${ACCOUNT_MANAGEMENT}/apiManagement`,
      },

      wallet: {
        main: `/${OBSERVER}/${PROFILE}/${WALLET}`,
        myBills: `/${OBSERVER}/${PROFILE}/${WALLET}/myBills`,
        depositAndWithdrawal: `/${OBSERVER}/${PROFILE}/${WALLET}/depositAndWithdrawal`,
      },

      workers: `/${OBSERVER}/${PROFILE}/workers`,
      referralRewards: `/${OBSERVER}/${PROFILE}/referralRewards`,
      resetPaymentPassword: `/${OBSERVER}/${PROFILE}/resetPaymentPassword`,
    },
  },
};

const URL_PREFIX = '/api/v1';

export const apiUrls = {
  auth: {
    login: `${URL_PREFIX}/login`,
    login2FA: `${URL_PREFIX}/login2fa`,
    recoveryLogin: `${URL_PREFIX}/login/otp`,
    refresh: `${URL_PREFIX}/refresh`,

    currentUser: `${URL_PREFIX}/user/profile`,

    registration: `${URL_PREFIX}/user/register`,
    emailConfirm: `${URL_PREFIX}/emailconfirm`,
    addUser: `${URL_PREFIX}/user/add`,

    resetPassword: `${URL_PREFIX}/user/password/reset`,
    resetConfirm: `${URL_PREFIX}/user/password/reset/confirm`,
    passwordChange: `${URL_PREFIX}/user/password`,

    logout: '/api/auth/logout',
  },

  watcherLinks: {
    all: `${URL_PREFIX}/user/watcherlink`,
  },

  referral: {
    stats: `${URL_PREFIX}/user/referral/stats`,
    ambassador: `${URL_PREFIX}/user/referral/ambassador`,
    referredUsers: `${URL_PREFIX}/user/referral/referredusers`,
    effectiveReferrals: `${URL_PREFIX}/user/referral/effectivereferredusers`,
    records: `${URL_PREFIX}/user/referral/records`,
    referralLinks: `${URL_PREFIX}/user/reflinks`,
  },

  beneficiary: {
    rewards: `${URL_PREFIX}/user/referral/partnerRecords`,
  },

  payment: {
    interUserTransfer: `${URL_PREFIX}/user/internaltransfer/`,
    fee: `${URL_PREFIX}/payment/btc_fee`,
    payoutById: id => `${URL_PREFIX}/account/withdraw/${id}`,
    selfTransferById: id => `${URL_PREFIX}/user/selftransfer/${id}`,
    settingsById: id => `${URL_PREFIX}/account/paymentsettings/${id}`,
    minPaymentById: id => `${URL_PREFIX}/account/btcautopay/${id}`,
    withdrawalSettingsById: id => `${URL_PREFIX}/account/${id}/withdrawal_settings`,

    password: {
      info: `${URL_PREFIX}/user/payment_password`,
      active: `${URL_PREFIX}/user/payment_password/toggle`,

      reset: {
        accountVerification: `${URL_PREFIX}/user/payment_password/reset/step1`,
        emailVerification: `${URL_PREFIX}/user/payment_password/reset/step2`,
        upload: `${URL_PREFIX}/user/payment_password/reset/upload`,
      },
    },
  },

  bills: {
    byId: id => `${URL_PREFIX}/account/stats/${id}/statement`,
    depositsById: id => `${URL_PREFIX}/account/stats/${id}/deposits`,
    withdrawalsById: id => `${URL_PREFIX}/account/stats/${id}/withdrawals`,
  },

  workers: {
    lastSubmit: `${URL_PREFIX}/account/stats/lastsubmit`,
    instructionById: id => `${URL_PREFIX}/account/${id}/suggest_workername`,
    workersNumber: accId => `${URL_PREFIX}/account/stats/${accId}/workers_number`,
    workersHashRate: accId => `${URL_PREFIX}/account/stats/${accId}/worker_hashrates`,
  },

  apiManagement: {
    apiKeys: `${URL_PREFIX}/user/profile/api-keys`,
  },

  export: {
    refferedUsers: `${URL_PREFIX}/dl/user/referral/referredusers`,
    effectiveReferrals: `${URL_PREFIX}/dl/user/referral/effectivereferredusers`,
    referralRecords: `${URL_PREFIX}/dl/user/referral/records`,
    beneficiaryRewards: `${URL_PREFIX}/dl/user/beneficiary/rewards`,
    profitAndHashrateById: id => `${URL_PREFIX}/dl/account/${id}/table/daily_hashrate_and_profit`,
    workersById: id => `${URL_PREFIX}/dl/account/stats/${id}/worker_hashrates`,
    myBills: id => `${URL_PREFIX}/dl/account/stats/${id}/statement`,
  },

  internalNotifications: {
    all: `${URL_PREFIX}/user/toast/`,
    byId: id => `${URL_PREFIX}/user/toast/${id}/`,
  },

  miningNotifications: {
    byId: id => `${URL_PREFIX}/account/thresholds/${id}`,
    configById: id => `${URL_PREFIX}/account/hrnotification/${id}`,
  },

  notificationsSettings: {
    byId: id => `${URL_PREFIX}/account/notifications/${id}`,
  },

  user: {
    suggestNames: `${URL_PREFIX}/user/suggest_username`,
    emailSendCode: `${URL_PREFIX}/user/profile/email_send_code`,
    emailConfirmCode: `${URL_PREFIX}/user/profile/email_confirm_code`,
    changePassword: `${URL_PREFIX}/user/password/change`,
    '2fa': `${URL_PREFIX}/user/2fa`,
    disable2Fa: `${URL_PREFIX}/user/2faoff`,
    changePhone: `${URL_PREFIX}/user/profile`,
    changeEmail: `${URL_PREFIX}/user/profile/email`,
  },

  charts: {
    server: `${URL_PREFIX}/chartdata`,
    hashrate: `${URL_PREFIX}/user/stats`,
    hashrateChartByAcc: (accId, column) => `${URL_PREFIX}/account/stats/${accId}/${column}`,
    hashrateChartByWorker: column => `${URL_PREFIX}/account/workers/stats/${column}`,
    profitChart: accId => `${URL_PREFIX}/account/${accId}/profit_chart`,
  },

  statistics: {
    infoBar: `${URL_PREFIX}/landing/infobar`,
    statsBar: `${URL_PREFIX}/landing/statsbar`,
    hashrateStats: `${URL_PREFIX}/user/hashratestats`,
    logInHistory: `${URL_PREFIX}/userstats/logins/30d`,
    profitTable: accId => `${URL_PREFIX}/account/${accId}/table/daily_hashrate_and_profit`,
    hashrateStatsByAcc: accId => `${URL_PREFIX}/account/stats/${accId}/hashratestats`,
  },

  accounts: {
    all: `${URL_PREFIX}/user/accounts`,
    addSubAccount: `${URL_PREFIX}/user/create_subaccount`,
    accountSummary: `${URL_PREFIX}/user/accounts_summary`,
    account: `${URL_PREFIX}/account`,
    accountStateById: id => `${URL_PREFIX}/account/state/${id}`,
  },

  wallet: {
    byAcc: accId => `${URL_PREFIX}/account/${accId}/wallet`,
    balance: accId => `${URL_PREFIX}/account/${accId}/balance`,
  },

  vipApplication: `${URL_PREFIX}/vipApplication`,
  betaTestApplication: `${URL_PREFIX}/demo_application`,

  watcher: {
    profile: `${URL_PREFIX}/wt/user/profile`,
    accounts: `${URL_PREFIX}/wt/user/accounts`,
    hashrateStats: `${URL_PREFIX}/wt/user/hashratestats`,
    accountsSummary: `${URL_PREFIX}/wt/user/accounts_summary`,
    balance: `${URL_PREFIX}/wt/user/balance`,
    logInHistory: `${URL_PREFIX}/wt/userstats/logins/30d`,

    account: {
      stats: {
        hashrateChart: (id, column) => `${URL_PREFIX}/wt/account/stats/${id}/${column}`,
        hashrateStatsById: id => `${URL_PREFIX}/wt/account/stats/${id}/hashratestats`,
        workersNumberStatsById: id => `${URL_PREFIX}/wt/account/stats/${id}/workers_number`,
      },

      table: {
        profitAndHashrateById: id => `${URL_PREFIX}/wt/account/${id}/table/daily_hashrate_and_profit`,
      },

      workers: {
        lastSubmit: `${URL_PREFIX}/wt/account/stats/lastsubmit`,
        workersHashrateById: id => `${URL_PREFIX}/wt/account/stats/${id}/worker_hashrates`,
        hashrateChart: column => `${URL_PREFIX}/wt/account/workers/stats/${column}`,
      },

      bills: {
        byId: id => `${URL_PREFIX}/wt/account/stats/${id}/statement`,
        depositsById: id => `${URL_PREFIX}/wt/account/stats/${id}/deposits`,
        withdrawalsById: id => `${URL_PREFIX}/wt/account/stats/${id}/withdrawals`,
      },

      notificationsSettings: {
        byId: id => `${URL_PREFIX}/wt/account/notifications/${id}`,
      },

      miningNotifications: {
        byId: id => `${URL_PREFIX}/wt/account/thresholds/${id}`,
        configById: id => `${URL_PREFIX}/wt/account/hrnotification/${id}`,
      },

      payment: {
        selfTransferById: id => `${URL_PREFIX}/wt/user/selftransfer/${id}`,
        settingsById: id => `${URL_PREFIX}/wt/account/paymentsettings/${id}`,
      },

      walletById: id => `${URL_PREFIX}/wt/account/${id}/wallet`,
      balanceById: id => `${URL_PREFIX}/wt/account/${id}/balance`,
      profitChartById: id => `${URL_PREFIX}/wt/account/${id}/profit_chart`,
    },

    watcherLinks: {
      all: `${URL_PREFIX}/wt/user/watcherlink`,
    },

    referral: {
      stats: `${URL_PREFIX}/wt/user/referral/stats`,
      referredUsers: `${URL_PREFIX}/wt/user/referral/referredusers`,
      effectiveReferrals: `${URL_PREFIX}/wt/user/referral/effectivereferredusers`,
      records: `${URL_PREFIX}/wt/user/referral/records`,
      referralLinks: `${URL_PREFIX}/wt/user/reflinks`,
    },

    beneficiary: {
      rewards: `${URL_PREFIX}/wt/user/referral/partnerRecords`,
    },

    apiManagement: {
      all: `${URL_PREFIX}/wt/user/apikey`,
    },

    export: {
      refferedUsers: `${URL_PREFIX}/wt/dl/user/referral/referredusers`,
      effectiveReferrals: `${URL_PREFIX}/wt/dl/user/referral/effectivereferredusers`,
      referralRecords: `${URL_PREFIX}/wt/dl/user/referral/records`,
      beneficiaryRewards: `${URL_PREFIX}/wt/dl/user/beneficiary/rewards`,
      myBills: id => `${URL_PREFIX}/wt/dl/account/stats/${id}/statement`,
      workersById: id => `${URL_PREFIX}/wt/dl/account/stats/${id}/worker_hashrates`,
      profitAndHashrateById: id => `${URL_PREFIX}/wt/dl/account/${id}/table/daily_hashrate_and_profit`,
    },
  },
};
