import { publicUrls } from 'constants/urls';
import { WatcherPermissions } from 'constants/watcher';

const { main, workers, wallet, settings, accountManagement, referralRewards } = publicUrls.observer.profile;

export const RouteWatcherPermissions = {
  [main]: {},

  [workers]: {
    requiredAnyPermissions: [WatcherPermissions.WORKERS, WatcherPermissions.ADMIN],
  },

  [wallet.main]: {
    requiredAnyPermissions: [WatcherPermissions.ASSETS, WatcherPermissions.ADMIN],
  },

  [settings.main]: {
    requiredPermissions: [WatcherPermissions.ADMIN],
  },

  [settings.account]: {
    requiredPermissions: [WatcherPermissions.ADMIN],
  },

  [settings.mining]: {
    requiredPermissions: [WatcherPermissions.ADMIN],
  },

  [settings.payment]: {
    requiredPermissions: [WatcherPermissions.ADMIN],
  },

  [accountManagement.miningAccounts]: {
    requiredPermissions: [WatcherPermissions.ADMIN],
  },

  [accountManagement.notifications]: {
    requiredPermissions: [WatcherPermissions.ADMIN],
  },

  [accountManagement.watcher]: {
    requiredPermissions: [WatcherPermissions.ADMIN],
  },

  [referralRewards]: {
    requiredPermissions: [WatcherPermissions.ADMIN],
  },
};
