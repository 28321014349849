export const getCheckboxGroupValues = (values = []) => {
  return values.map(item => item?.value || item);
};

export const verifyCheckedAll = (values = [], options = []) => {
  if (options.length !== 0) {
    if (options.length === values.length) {
      return true;
    }
  }

  return false;
};

export const verifyCheckedIndeterminate = (values = [], options = []) => {
  if (options.length !== 0 && values.length !== 0) {
    if (options.length > values.length) {
      return true;
    }
  }

  return false;
};
