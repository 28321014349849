import { useMemo } from 'react';
import { format } from 'date-fns';
import { theme as antdTheme } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { changeTheme as changeThemeStore } from 'store/reducers/theme';

import { ThemeMode } from 'constants/theme';
import { DarkColors, DefaultColors, LightColors, ThemeTokenDark, ThemeTokenLight } from 'constants/colors';

const DAY_HOURS_START = 6;
const DAY_HOURS_END = 19;

const useTheme = () => {
  const themeColorNames = Object.keys(LightColors);
  const defaultColorNames = Object.keys(DefaultColors);

  const dispatch = useDispatch();
  const { theme } = useSelector(state => state.theme);

  const isDarkMode = theme === ThemeMode.DARK;
  const colors = isDarkMode ? DarkColors : LightColors;

  const themeConfig = useMemo(
    () => ({
      token: isDarkMode ? ThemeTokenDark : ThemeTokenLight,
      algorithm: isDarkMode ? antdTheme.darkAlgorithm : antdTheme.defaultAlgorithm,
    }),
    [isDarkMode],
  );

  const changeThemeCssVariables = theme => {
    const themeColors = theme === ThemeMode.LIGHT ? LightColors : DarkColors;

    themeColorNames.forEach(color => {
      document.documentElement.style.setProperty(`--${color}`, themeColors[color]);
    });
  };

  const changeDefaultCssVariables = () => {
    defaultColorNames.forEach(color => {
      document.documentElement.style.setProperty(`--${color}`, DefaultColors[color]);
    });
  };

  const checkIsDay = () => {
    const currentDate = new Date();
    const currentTime = +format(currentDate, 'HH');

    return currentTime >= DAY_HOURS_START && currentTime <= DAY_HOURS_END;
  };

  const changeTheme = theme => {
    dispatch(changeThemeStore(theme));
    changeThemeCssVariables(theme);
  };

  const initTheme = () => {
    if (theme) {
      changeTheme(theme);
    } else {
      const isDay = checkIsDay();
      const newTheme = isDay ? ThemeMode.LIGHT : ThemeMode.DARK;

      changeTheme(newTheme);
    }

    changeDefaultCssVariables();
  };

  return { theme, colors, isDarkMode, themeConfig, changeTheme, initTheme };
};

export default useTheme;
