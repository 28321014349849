import React from 'react';
import { Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { Loading } from 'components';

import './BorderedBlock.styles.scss';

const { Text } = Typography;

const BorderedBlock = ({ title = '', items = [], loading, className = '', ...props }) => {
  const { t } = useTranslation();

  const checkIsString = value => {
    return typeof value === 'string';
  };

  return (
    <div className={`np-bordered-block ${className}`} {...props}>
      {title && <Text className="np-bordered-block__title">{t(title)}</Text>}

      <div className="np-bordered-block__body">
        <Loading inline noMargin isLoading={loading}>
          {items.map(item => (
            <div key={item.key} className="np-bordered-block__item">
              {checkIsString(item.title) ? (
                <Text className="np-bordered-block__item-title">{t(item.title)}</Text>
              ) : (
                item.title
              )}

              <Tooltip title={item?.tooltip ? item?.tooltip : null}>
                {checkIsString(item.value) ? (
                  <Text className="np-bordered-block__item-value">{t(item.value || '—')}</Text>
                ) : (
                  item.value || '—'
                )}
              </Tooltip>
            </div>
          ))}
        </Loading>
      </div>
    </div>
  );
};

export default BorderedBlock;
