import { createSlice } from '@reduxjs/toolkit';

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    block: false,
    unlockDate: null,
  },
  reducers: {
    setPaymentBlock: (state, action) => {
      const { block, unlockDate } = action.payload || {};

      state.block = block;
      state.unlockDate = unlockDate;
    },
  },
});

export const { setPaymentBlock } = paymentSlice.actions;

export default paymentSlice.reducer;
