import React from 'react';
import { Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import './InfoItem.styles.scss';
import { Loading } from 'components';
import { QuestionIcon } from 'assets/images';

const { Text } = Typography;

const InfoItem = ({ title, titleIcon, value, tooltip, loading }) => {
  const { t } = useTranslation();

  return (
    <div className="np-payment-settings-info-item">
      <div className="np-payment-settings-info-item__header">
        {titleIcon}
        <Text className="np-payment-settings-info-item__header-title">{t(title)}</Text>
      </div>

      <div className="np-payment-settings-info-item__body">
        <Loading inline noMargin isLoading={loading}>
          <div className="np-payment-settings-info-item__body-value">{value}</div>

          {tooltip && (
            <Tooltip title={tooltip}>
              <QuestionIcon />
            </Tooltip>
          )}
        </Loading>
      </div>
    </div>
  );
};

export default InfoItem;
