import { useMemo, useState, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getAccountsSummary } from 'services/accountStatistics.service';
import { getWatcherAccountsSummary } from 'services/watcher/watcherAccounts.service';

const useMiningAccounts = ({ showHidden, showSummary = true, refetchOnMount, isWatcher }) => {
  const [summary, setSummary] = useState(null);

  const { accessKey } = useSelector(state => state.watcherAuth);
  const { userAccountsList } = useSelector(state => state.accounts);
  const { watcherAccountsList } = useSelector(state => state.watcherAccounts);

  const queryKey = ['mining-accounts', isWatcher ? watcherAccountsList : userAccountsList];

  const filterAccounts = (accounts = [], filter = {}) => {
    const { showHidden } = filter;

    if (!showHidden) {
      return accounts.filter(account => !account.is_hidden);
    }

    return accounts;
  };

  const getMiningAccounts = useCallback(async () => {
    const request = isWatcher ? getWatcherAccountsSummary : getAccountsSummary;

    return await request({ accessKey });
  }, [accessKey, isWatcher]);

  const selectData = useCallback(
    result => {
      const { data, success } = result;

      if (success && data) {
        const summary = showSummary ? [{ ...data.summary, isSummary: true }] : [];
        setSummary(summary[0] || null);

        return [...summary, ...data.accounts];
      }

      return [];
    },
    [showSummary],
  );

  const {
    data: miningAccounts,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey,
    enabled: ((isWatcher && !!accessKey) || !isWatcher) && refetchOnMount,
    refetchOnMount,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    select: selectData,
    queryFn: getMiningAccounts,
  });

  const filteredMiningAccounts = useMemo(() => {
    return filterAccounts(miningAccounts, { showHidden });
  }, [miningAccounts, showHidden]);

  return {
    data: miningAccounts,
    filteredData: filteredMiningAccounts,
    summary,
    isFetching,
    loading: isLoading,
    reload: refetch,
  };
};

export default useMiningAccounts;
