import React from 'react';
import { Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import { Sidebar } from 'components';
import { CloseIcon } from 'assets/images';
import { LanguageSelect, ThemeSwitcher } from 'modules';

import useModal from 'hooks/common/useModal';

import './MobileNavigationMenu.styles.scss';

const MobileNavigationMenu = ({ active, logoUrl, items = [], onClick, ...props }) => {
  const { open, onOpen, onClose } = useModal();

  const handleClick = item => {
    onClick(item);
    onClose();
  };

  return (
    <>
      <MenuOutlined onClick={onOpen} className="np-mobile-navigation-menu__icon" />

      <Drawer
        open={open}
        closable={false}
        placement="left"
        onClose={onClose}
        contentWrapperStyle={{ width: 300 }}
        className="np-mobile-navigation-menu__drawer"
      >
        <CloseIcon onClick={onClose} className="np-mobile-navigation-menu__close-btn" />

        <Sidebar
          active={active}
          logoUrl={logoUrl}
          menuProps={{ items, onClick: handleClick, ...props }}
          menuFooter={
            <div className="np-mobile-navigation-menu__sidebar-footer">
              <LanguageSelect />
              <ThemeSwitcher />
            </div>
          }
        />
      </Drawer>
    </>
  );
};

export default MobileNavigationMenu;
