import { ERROR_MESSAGES, RequestErrorMessages } from 'constants/errors';

// export interface IErrorHandlerOptions<T> {
//   defaultErrorReturn?: T;
//   errorMessage?: string;
//   errors?: Record<string, string>;
//   allowErrorBodyMessage?: boolean;
//   errorKey?: string;
// }

// export interface IErrorHandlerResponse<T> {
//   data: T;
//   success: boolean;
//   errorMessage?: string;
//   error?: ResponseError;
// }

const asyncErrorHandler = async (callback, options) => {
  const {
    defaultErrorReturn = null,
    errorMessage = ERROR_MESSAGES.COMMON.UNKNOWN,
    errors = {},
    allowErrorBodyMessage,
    errorKey = 'key',
  } = options || {};

  try {
    const data = await callback();
    return { data, success: true };
  } catch (error) {
    if (!error?.body?.[errorKey] && !allowErrorBodyMessage) {
      return { data: defaultErrorReturn, success: false, errorMessage, error };
    }

    const requestErrors = { ...RequestErrorMessages, ...errors };

    const errorBodyMessage = allowErrorBodyMessage ? error?.body?.message || error?.message : null;
    const message = errorBodyMessage || requestErrors[error.body[errorKey]] || errorMessage;

    return { data: defaultErrorReturn, success: false, errorMessage: message, error };
  }
};

export default asyncErrorHandler;
