import React from 'react';

import { SummaryBlock } from 'components';

import useSummaryItems from './hooks/useSummaryItems';

const MobileNetworkSummary = ({ data, loading }) => {
  const items = useSummaryItems(data);

  return <SummaryBlock noBottomLine titleThin title="networkSummary.title" items={items} loading={loading} />;
};

export default MobileNetworkSummary;
