import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseModal } from 'components';
import ExportForm from './components/ExportForm';

const ExportModal = ({ open, loading, onClose = () => {}, onFinish = () => {}, ...props }) => {
  const { t } = useTranslation();

  return (
    <BaseModal title={t('modal.exportToEmail.title')} open={open} width={450} onCancel={onClose} {...props}>
      <ExportForm loading={loading} onFinish={onFinish} />
    </BaseModal>
  );
};

export default ExportModal;
