import convertNumber from 'utils/common/convertNumber';
import { keyMap } from '../constants/chart';

const parseSeries = data => {
  return data.map((value, index, array) => {
    if (value === null) {
      const prevElem = array[index + 1];
      const nextElem = array[index - 1];

      if (prevElem === null || nextElem === null) {
        return 0;
      } else {
        return (prevElem + nextElem) / 2;
      }
    }

    const number = Number(value) ?? 0;

    return convertNumber(number, 4);
  });
};

export const serializeData = (data = {}) => {
  const { dates = [], series = [] } = data || {};

  const hashRate = series.find(({ name }) => name === keyMap.HASH_RATE) || {};
  const rejectRate = series.find(({ name }) => name === keyMap.REJECT_RATE) || {};

  const { data: hashRateData = [] } = hashRate;
  const { data: rejectRateData = [] } = rejectRate;

  const hashRateSeries = [...(hashRateData || [])];
  const rejectRateSeries = [...(rejectRateData || [])];
  const datesSeries = [...dates];

  const isFirstElemEmpty = hashRateSeries[0] === null || rejectRateSeries[0] === null;

  const isSecondEmpty = hashRateSeries[1] === null || rejectRateSeries[1] === null;

  const isLastElemEmpty =
    hashRateSeries[hashRateSeries.length - 1] === null || rejectRateSeries[rejectRateSeries.length - 1] === null;

  const isBeforeLastEmpty =
    hashRateSeries[hashRateSeries.length - 2] === null || rejectRateSeries[rejectRateSeries.length - 2] === null;

  if (isFirstElemEmpty && !isSecondEmpty) {
    hashRateSeries.splice(0, 1);
    rejectRateSeries.splice(0, 1);
    datesSeries.splice(0, 1);
  } else if (isLastElemEmpty && !isBeforeLastEmpty) {
    hashRateSeries.splice(hashRateSeries.length - 1, 1);
    rejectRateSeries.splice(rejectRateSeries.length - 1, 1);
    datesSeries.splice(datesSeries.length - 1, 1);
  }

  const serializedHashRate = parseSeries(hashRateSeries);
  const serializedRejectRate = parseSeries(rejectRateSeries);

  return {
    parsedSeries: [
      {
        ...hashRate,
        data: serializedHashRate,
      },
      {
        ...rejectRate,
        data: serializedRejectRate,
      },
    ],

    parsedDates: datesSeries,
  };
};
