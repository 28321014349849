import _castArray from 'lodash/castArray';

const watcherHasPermission = ({ watcher, requiredPermissions, requiredAnyPermissions }) => {
  if (!watcher) {
    return false;
  }

  const checkRequiredPermissions = (watcherPermissions, requiredPermissions) => {
    if (!watcherPermissions) {
      return true;
    }

    const isNested = !!_castArray(requiredPermissions).find(permission => Array.isArray(permission));

    if (isNested) {
      return _castArray(requiredPermissions).some(permissions => {
        if (Array.isArray(permissions)) {
          return permissions.every(role => {
            return !!watcherPermissions.includes(role);
          });
        }

        return !!watcherPermissions.includes(permissions);
      });
    }

    return _castArray(requiredPermissions).every(permission => {
      return !!watcherPermissions.includes(permission);
    });
  };

  const checkPossiblePermissions = (watcherPermissions, possiblePermissions) => {
    return _castArray(possiblePermissions).some(permission => {
      return !!watcherPermissions.includes(permission);
    });
  };

  if (!requiredPermissions && !requiredAnyPermissions) {
    return true;
  }

  if (!requiredPermissions && requiredAnyPermissions) {
    return checkPossiblePermissions(watcher.acl, requiredAnyPermissions);
  }

  return checkRequiredPermissions(watcher.acl, requiredPermissions);
};

export default watcherHasPermission;
