import React from 'react';
import { useTranslation } from 'react-i18next';

import VipIndicator from './components/VipIndicator';
import { DiamondIcon, VipIcon } from 'assets/images';

import useUserData from 'hooks/user/useUserData';

import { RefStatusType, VipStatusType } from 'constants/userStatuses';

import './VipIndicators.styles.scss';

const VipIndicators = () => {
  const { user } = useUserData();
  const { t } = useTranslation();

  const isVipUser = user?.vip_status === VipStatusType.VIP;
  const isAmbassador = user?.ref_status === RefStatusType.AMBASSADOR;

  const showIndicators = user && (isVipUser || isAmbassador);

  return (
    showIndicators && (
      <div className="np-user-layout-header__icons">
        {isVipUser && <VipIndicator tooltip={t('menu.userStatus.vip')} Icon={DiamondIcon} />}
        {isAmbassador && <VipIndicator tooltip={t('menu.userStatus.ambassador')} Icon={VipIcon} />}
      </div>
    )
  );
};

export default VipIndicators;
