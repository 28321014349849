import dayjs from 'dayjs';

const isBiggerDate = (date1, date2) => {
  if (!date1 || !date2) {
    return false;
  }

  const dateJs1 = dayjs(date1).startOf('date');
  const dateJs2 = dayjs(date2).startOf('date');

  return dateJs1.isAfter(dateJs2);
};

export default isBiggerDate;
