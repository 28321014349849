import { useTranslation } from 'react-i18next';

const MS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;

const useIntervalSelectOptions = () => {
  const { t } = useTranslation();

  return [
    {
      value: 10 * SECONDS_IN_MINUTE * MS_IN_SECOND,
      label: t('common.interval.10min'),
    },
    {
      value: 30 * SECONDS_IN_MINUTE * MS_IN_SECOND,
      label: t('common.interval.30min'),
    },
    {
      value: MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MS_IN_SECOND,
      label: t('common.interval.1h'),
    },
  ];
};

export default useIntervalSelectOptions;
