export const DefaultSteps = {
  CONFIRMATION: 'confirmation',
  ACCOUNT_VERIFICATION: 'account-verification',
  SECURITY_VERIFICATION: 'security-verification',
  IDENTITY_VERIFICATION: 'identity-verification',
  RESULT: 'result',
};

export const DefaultNextStep = {
  [DefaultSteps.CONFIRMATION]: DefaultSteps.ACCOUNT_VERIFICATION,
  [DefaultSteps.ACCOUNT_VERIFICATION]: DefaultSteps.SECURITY_VERIFICATION,
  [DefaultSteps.SECURITY_VERIFICATION]: DefaultSteps.IDENTITY_VERIFICATION,
  [DefaultSteps.IDENTITY_VERIFICATION]: DefaultSteps.RESULT,
};

export const DefaultStepLabels = {
  [DefaultSteps.CONFIRMATION]: 'resetProcess.steps.confirmation.title',
  [DefaultSteps.ACCOUNT_VERIFICATION]: 'resetProcess.steps.accountVerification.title',
  [DefaultSteps.SECURITY_VERIFICATION]: 'resetProcess.steps.securityVerification.title',
  [DefaultSteps.IDENTITY_VERIFICATION]: 'resetProcess.steps.identityVerification.title',
  [DefaultSteps.RESULT]: 'resetProcess.steps.result.title',
};
