import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import request from 'services/request.service';
import { setAuth } from 'store/reducers/watcher/watcherAuth';
import { setAccounts } from 'store/reducers/watcher/accounts';
import watcherHasPermission from 'utils/auth/watcherHasPermission';

import { apiUrls } from 'constants/urls';

export const useCheckWatcherAuth = (options = {}) => {
  const dispatch = useDispatch();
  const [urlParams] = useSearchParams();
  const watcherAuth = useSelector(state => state.watcherAuth);
  const accessKeyUrl = urlParams.get('access_key');

  const { finished, pending, watcher, accessKey } = watcherAuth;
  const { requiredPermissions, requiredAnyPermissions } = options;

  const authRequestWasSent = pending || finished;

  const userIsAuthenticated = finished && !!watcher && accessKey && accessKey === accessKeyUrl;

  const userIsAuthorized = watcherHasPermission({ watcher, requiredPermissions, requiredAnyPermissions });

  const ensureAuthRequestWasSent = useCallback(async () => {
    if (!authRequestWasSent) {
      try {
        dispatch(setAuth({ ...watcherAuth, pending: true }));

        const requestOptions = { disableRedirectOnFail: true, isWatcher: true, params: { key: accessKeyUrl } };

        const [profile, accounts] = await Promise.all([
          request(apiUrls.watcher.profile, requestOptions),
          request(apiUrls.watcher.accounts, requestOptions),
        ]);

        dispatch(setAuth({ watcher: profile, accessKey: accessKeyUrl, pending: false, finished: true }));
        dispatch(setAccounts(accounts));
      } catch (e) {
        dispatch(setAuth({ watcher: null, accessKey: null, pending: false, finished: true }));
      }
    }
  }, [accessKeyUrl, authRequestWasSent, dispatch, watcherAuth]);

  useEffect(() => {
    ensureAuthRequestWasSent();
  }, [ensureAuthRequestWasSent]);

  return { loading: !authRequestWasSent || pending, isAuth: userIsAuthenticated, isAuthorized: userIsAuthorized };
};
