import React, { useState } from 'react';
import { Divider, Switch, Tooltip, Typography } from 'antd';

import { QuestionIcon } from 'assets/images';

import './SwitchItem.styles.scss';

const { Text } = Typography;

const SwitchItem = ({ title, isActive, id, divider, tooltip, loading: fullLoading, readOnly, onChange }) => {
  const [loading, setLoading] = useState(false);

  const handleChange = async value => {
    setLoading(true);

    await onChange(id, value);

    setLoading(false);
  };

  return (
    <div className="np-switch-item">
      <div className="np-switch-item__content">
        <div className="np-switch-item__title">
          <Text>{title}</Text>

          {tooltip && (
            <Tooltip title={tooltip}>
              <QuestionIcon />
            </Tooltip>
          )}
        </div>

        <Switch disabled={readOnly} checked={isActive} loading={loading || fullLoading} onChange={handleChange} />
      </div>

      {divider && <Divider />}
    </div>
  );
};

export default SwitchItem;
