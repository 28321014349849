import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SignInLinks from './components/SignInLinks';
import { UserMenu as BaseUserMenu } from 'components';
import UserMenuFooter from './components/UserMenuFooter';

import { useUserMenuOptions } from './hooks/useUserMenuOptions';
import { setSelectedAccountId } from 'store/reducers/accounts';

import './UserMenu.styles.scss';

const UserMenu = () => {
  const { user } = useSelector(state => state.user);
  const { selectedAccountId, accountsList } = useSelector(state => state.accounts);

  const dispatch = useDispatch();

  const accountName = useMemo(
    () => accountsList.find(account => account.id === selectedAccountId)?.account_name || '',
    [accountsList, selectedAccountId],
  );

  const { options, loading } = useUserMenuOptions(accountName);

  const onMenuClick = async ({ key }) => {
    const isCurrentAccount = key === String(selectedAccountId);

    if (!isCurrentAccount) {
      dispatch(setSelectedAccountId(+key));
    }
  };

  return user ? (
    <BaseUserMenu
      name={accountName}
      options={options}
      loading={loading}
      onOptionClick={onMenuClick}
      footer={<UserMenuFooter />}
      menuClassName="np-user-menu__dropdown"
    />
  ) : (
    <SignInLinks />
  );
};

export default UserMenu;
