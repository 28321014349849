import { PlatformType } from './types';

export const ButtonContent = {
  [PlatformType.APP_STORE]: {
    title: 'form.button.appStore.title',
    platform: 'form.button.appStore.platform',
  },

  [PlatformType.GOOGLE_PLAY]: {
    title: 'form.button.googlePlay.title',
    platform: 'form.button.googlePlay.platform',
  },
};
