import React from 'react';
import { Space } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';

import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import { publicUrls } from 'constants/urls';

const SignInLinks = () => {
  const { t } = useTranslation();
  const { isMobileView } = useWindowResponsiveness(900);

  return (
    <Space>
      {!isMobileView && (
        <Link to={publicUrls.login}>
          <Button type="default" color="default" shape="round">
            {t('menu.userProfile.logInBtn')}
          </Button>
        </Link>
      )}

      <Link to={publicUrls.singUp}>
        <Button shape="round" type="primary">
          {t('menu.userProfile.signUpBtn')}
        </Button>
      </Link>
    </Space>
  );
};

export default SignInLinks;
