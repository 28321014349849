import React from 'react';

import { TableWithHeader } from 'components';

import useMyReferralRecordsColumns from './hooks/useMyReferralRecordsColumns';

import './ReferralRewardsTable.styles.scss';

const ReferralRewardsTable = ({ dataSource, className = '', ...props }) => {
  const columns = useMyReferralRecordsColumns();

  return (
    <TableWithHeader
      title="referralRewards.table.myReferralRecords.title"
      columns={columns}
      dataSource={dataSource}
      rowKey={record => record.time}
      className={`np-referral-records-table ${className}`}
      {...props}
    />
  );
};

export default ReferralRewardsTable;
