import { apiUrls } from 'constants/urls';
import request from './request.service';
import asyncErrorHandler from 'utils/requests/errorHandler';
import filterEmptyParams from 'utils/params/filterEmptyParams';

export const getWorkersLastSubmit = async accountName => {
  const callback = async () => {
    return await request(apiUrls.workers.lastSubmit, { params: { account: accountName } });
  };

  return await asyncErrorHandler(callback, { defaultErrorReturn: [] });
};

export const getWorkersNumber = async accountId => {
  const callback = async () => {
    return await request(apiUrls.workers.workersNumber(accountId));
  };

  return await asyncErrorHandler(callback);
};

export const getWorkersHashrate = async ({ accountId, page, limit, search, filter, sortBy, sortOrder }) => {
  const callback = async () => {
    return await request(apiUrls.workers.workersHashRate(accountId), {
      params: filterEmptyParams({
        page,
        per_page: limit,
        q: search,
        status: filter,
        order_by: sortBy,
        order: sortOrder,
      }),
    });
  };

  return await asyncErrorHandler(callback);
};

export const getHashrateChartByAccount = async ({ accountId, column }) => {
  const callback = async () => {
    return await request(apiUrls.charts.hashrateChartByAcc(accountId, column));
  };

  return await asyncErrorHandler(callback);
};

export const getHashrateChartByWorker = async ({ workerName, column }) => {
  const callback = async () => {
    return await request(apiUrls.charts.hashrateChartByWorker(column), {
      method: 'POST',
      body: { wname: workerName },
    });
  };

  return await asyncErrorHandler(callback);
};

export const getWorkersInstruction = async accountId => {
  const callback = async () => {
    return await request(apiUrls.workers.instructionById(accountId));
  };

  return await asyncErrorHandler(callback);
};

export const removeWorkers = async ({ accountId, workerNames }) => {
  const callback = async () => {
    return await request(apiUrls.workers.workersHashRate(accountId), {
      method: 'DELETE',
      body: filterEmptyParams({
        workernames: workerNames,
      }),
    });
  };

  return await asyncErrorHandler(callback);
};
