import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavigationMenu as BaseNavigationMenu } from 'components';

import useDesktopMenuLinks from './hooks/useDesktopMenuLinks';
import generateWatcherLink from 'utils/watcher/generateWatcherLink';
import useWatcherSidebarOptions from 'hooks/navigation/useWatcherSidebarOptions';

import { publicUrls } from 'constants/urls';

const NavigationMenu = () => {
  const mobileItems = useWatcherSidebarOptions();
  const desktopItems = useDesktopMenuLinks();

  const location = useLocation();
  const navigate = useNavigate();

  const { watcher, accessKey } = useSelector(state => state.watcherAuth);

  const logoUrl = useMemo(
    () => (watcher && accessKey ? generateWatcherLink({ accessKey }) : publicUrls.main),
    [accessKey, watcher],
  );

  const active = location.pathname;

  const defaultOpenKeys = useMemo(() => {
    if (
      active.includes(publicUrls.observer.profile.settings.main) ||
      active.includes(publicUrls.observer.profile.accountManagement.main)
    ) {
      return publicUrls.observer.profile.settings.main;
    }

    if (active.includes(publicUrls.observer.profile.wallet.main)) {
      return publicUrls.observer.profile.wallet.main;
    }

    return null;
  }, [active]);

  const onClick = item => {
    const path = item.item.props.link;

    if (typeof path === 'string') {
      const watcherLink = generateWatcherLink({ accessKey, path });
      navigate(watcherLink);
    }
  };

  const desktop = { items: desktopItems };
  const mobile = { items: mobileItems, onClick, active, defaultOpenKeys, logoUrl };

  return <BaseNavigationMenu mobile={mobile} desktop={desktop} />;
};

export default NavigationMenu;
