import React from 'react';

import { AppleLogoIcon, GooglePlayLogoIcon } from 'assets/images';

import { PlatformType } from '../../constants/types';

const Logo = ({ type, className = '' }) => {
  if (type === PlatformType.APP_STORE) {
    return <AppleLogoIcon className={className} />;
  }

  if (type === PlatformType.GOOGLE_PLAY) {
    return <GooglePlayLogoIcon className={className} />;
  }
};

export default Logo;
