import React from 'react';
import { Navigate } from 'react-router-dom';

import { Loading } from 'components';

import { useCheckWatcherAuth } from '../utils/hooks/useCheckWatcherAuth';

import { publicUrls } from 'constants/urls';

const ProtectedWatcherContainer = ({
  children,
  requiredPermissions,
  requiredAnyPermissions,
  redirectOnAuthorizationFail,
  fallbackOnAuthorizationFail,
  fallbackOnFail,
}) => {
  const { loading, isAuth, isAuthorized } = useCheckWatcherAuth({ requiredPermissions, requiredAnyPermissions });

  const renderAuthFailure = () => {
    const failureUrl = publicUrls.observer.invalidWatcherLink;

    return fallbackOnFail || <Navigate to={failureUrl} />;
  };

  if (loading) {
    return <Loading isLoading fullScreen />;
  }

  if (!isAuth) {
    return renderAuthFailure();
  }

  if (!isAuthorized && redirectOnAuthorizationFail) {
    return <Navigate to={redirectOnAuthorizationFail} />;
  }

  if (!isAuthorized) {
    return fallbackOnAuthorizationFail;
  }

  return children;
};

export default ProtectedWatcherContainer;
