import React from 'react';
import { useTranslation } from 'react-i18next';

import WorkersStatus from '../components/WorkersStatus';

import formatBtcAmount from 'utils/coins/formatBtcAmount';
import getComputingSpeedUnit from 'utils/power/getComputingSpeedUnit';
import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

const useAccountSummaryItems = data => {
  const { t } = useTranslation();

  const { isMobileView } = useWindowResponsiveness(1400);

  const { balance_btc, hr_24h, hr_24h_power } = data || {};

  return [
    {
      key: '1',
      title: !isMobileView ? t('accountsSummary.balance') : null,
      tooltip: isMobileView ? t('accountsSummary.balance') : null,
      value: `${formatBtcAmount(balance_btc)} BTC`,
    },
    {
      key: '2',
      title: !isMobileView ? t('accountsSummary.hashrate24h') : null,
      tooltip: isMobileView ? t('accountsSummary.hashrate24h') : null,
      value: getComputingSpeedUnit(hr_24h, hr_24h_power),
    },
    {
      key: '3',
      title: !isMobileView ? t('accountsSummary.workers') : null,
      value: <WorkersStatus data={data} />,
    },
  ];
};

export default useAccountSummaryItems;
