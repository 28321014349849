export const DATE_SLASH_FORMAT = 'MM/DD/YYYY';
export const DATE_DOT_FORMAT = 'DD.MM.YYYY';

export const DATE_DOT_FORMAT_WITH_FULL_TIME_2 = 'DD.MM.YYYY HH:mm:ss';

// DayJS

export const DATE_SLASH_FORMAT_2 = 'MM/dd/yyyy';
export const DATE_SLASH_FORMAT_WITH_TIME = 'MM/dd/yyyy HH:mm';
export const DATE_SLASH_FORMAT_WITH_FULL_TIME = 'MM/dd/yyyy HH:mm:ss';

export const DATE_DOT_FORMAT_2 = 'dd.MM.yyyy';
export const DATE_DOT_FORMAT_WITH_TIME = 'dd.MM.yyyy HH:mm';
export const DATE_DOT_FORMAT_WITH_FULL_TIME = 'dd.MM.yyyy HH:mm:ss';
