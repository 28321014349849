import React from 'react';
import { Typography } from 'antd';

import './StepLayout.styles.scss';

const { Text } = Typography;

const StepLayout = ({ title, titleIcon, description, children, maxWidth = 600, className = '', style, ...props }) => {
  const noHeader = !title && !titleIcon && !description;

  return (
    <div className={`np-reset-process__step-layout ${className}`} style={{ maxWidth, ...style }} {...props}>
      {!noHeader && (
        <div className="np-reset-process__step-layout-header">
          <div className="np-reset-process__step-layout-title">
            {titleIcon}

            <Text>{title}</Text>
          </div>

          <Text className="np-reset-process__step-layout-desc">{description}</Text>
        </div>
      )}

      {children}
    </div>
  );
};

export default StepLayout;
