import React from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import './AutoTransferTooltip.styles.scss';

const AutoTransferTooltip = ({ settings }) => {
  const { auto_payout, auto_payout_address, auto_transfer_to, auto_transfer_to_address } = settings || {};

  const { t } = useTranslation();

  return (
    <Space direction="vertical">
      {auto_payout && (
        <div>
          <div className="np-user-menu-sub-account__auto-transf-tooltip-title">
            {t('menu.userProfile.subAccountsInfo.autoTransfers.tooltip.autoWithdrawal')}:
          </div>

          <div style={{ wordBreak: 'break-all' }}>{auto_payout_address}</div>
        </div>
      )}

      {auto_transfer_to && (
        <div>
          <div className="np-user-menu-sub-account__auto-transf-tooltip-title">
            {t('menu.userProfile.subAccountsInfo.autoTransfers.tooltip.autoInternalTransfer')}:
          </div>

          <div>
            {t('menu.userProfile.subAccountsInfo.autoTransfers.tooltip.autoInternalTransferAccs', {
              count: auto_transfer_to_address?.length || 0,
            })}
            :
          </div>

          <ul className="np-user-menu-sub-account__auto-transf-accounts">
            {auto_transfer_to_address?.map(address => (
              <li key={address}>{address}</li>
            ))}
          </ul>
        </div>
      )}
    </Space>
  );
};

export default AutoTransferTooltip;
