import { useState } from 'react';
import { deleteInternalNotification } from 'services/notifications.service';

const useNotificationsMutation = () => {
  const [loading, setLoading] = useState(false);

  const deleteNotification = async id => {
    setLoading(true);

    const result = await deleteInternalNotification(id);

    setLoading(false);

    return result;
  };

  return { loading, deleteNotification };
};

export default useNotificationsMutation;
