import { useState } from 'react';

import { disable2Fa } from 'services/user.service';

export const useDisable2FaMutation = () => {
  const [loading, setLoading] = useState(false);

  const disableUser2Fa = async ({ totp, otp }) => {
    setLoading(true);

    const result = await disable2Fa({ totp, otp });

    setLoading(false);

    return result;
  };

  return { loading, refetch: disableUser2Fa };
};
