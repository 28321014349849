import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setUser } from 'store/reducers/user';
import { changePaymentPassword } from 'services/payment.service';

const useChangePaymentPassword = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);

  const [loading, setLoading] = useState(false);

  const changePassword = async body => {
    setLoading(true);

    const result = await changePaymentPassword(body);

    if (result.success) {
      dispatch(
        setUser({
          ...user,
          has_pay_password: result.data.paymentPasswordSet,
        }),
      );
    }

    setLoading(false);

    return result;
  };

  return { loading, refetch: changePassword };
};

export default useChangePaymentPassword;
