import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseModal } from 'components';
import Disable2FaForm from './components/Disable2FaForm';

const DisableGaModal = ({ open, onClose = () => {}, onFinish = () => {} }) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      title={t('accountSettings.accountSecurity.form.twoFADisable.title')}
      open={open}
      width={450}
      onCancel={onClose}
    >
      <Disable2FaForm onFinish={onFinish} onError={onClose} />
    </BaseModal>
  );
};

export default DisableGaModal;
