import React from 'react';

import Files from '../Files';
import { FormButtons } from 'modules';
import { Form, Captcha } from 'components';

import useForm from 'hooks/forms/useForm';
import useValidation from 'hooks/forms/useValidation';

import './IdentifyVerificationForm.styles.scss';

const IdentifyVerificationForm = ({ loading, includeCaptcha, onFinish = () => {} }) => {
  const [form] = Form.useForm();
  const rules = useValidation();

  const { submitDisabled } = useForm(form);

  const handleFinish = values => {
    onFinish(values);
  };

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      layout="vertical"
      requiredMark="optional"
      validateTrigger="onSubmit"
      name="reset-pay-pass-identify-verify"
      className="np-reset-process-identify-verify__form"
    >
      <Form.Item name="files" rules={[rules.required]}>
        <Files disabled={loading} />
      </Form.Item>

      {includeCaptcha && (
        <Form.Item
          name="captchaV2"
          validateFirst
          rules={[rules.requiredWithMessage('validation.сaptcha.required')]}
          className="np-reset-process-identify-verify__form-captcha"
        >
          <Captcha />
        </Form.Item>
      )}

      <FormButtons marginTop={0} submitDisabled={submitDisabled} loading={loading} />
    </Form>
  );
};

export default IdentifyVerificationForm;
