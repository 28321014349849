import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StagedModal } from 'components';
import { AuthenticationForm } from 'modules';
import PaymentPasswordForm from './components/PaymentPasswordForm';
import ChangePasswordWarning from './components/ChangePasswordWarning';

import useUserData from 'hooks/user/useUserData';
import usePaymentBlock from 'hooks/payment/usePaymentBlock';
import useNotifications from 'hooks/common/useNotifications';
import useChangePaymentPassword from './hooks/useChangePaymentPassword';
import usePaymentPasswordEditSteps from './hooks/usePaymentPasswordEditSteps';

import { ModalType } from './constants/type';
import { PaymentPasswordEditSteps } from './constants/steps';

const PaymentPasswordEditModal = ({ open, type, onClose = () => {}, onFinish = () => {} }) => {
  const { t } = useTranslation();
  const { user } = useUserData();
  const steps = usePaymentPasswordEditSteps();
  const { notificationApi } = useNotifications();

  const { checkPaymentError } = usePaymentBlock();

  const [paymentPasswordData, setPaymentPasswordData] = useState(null);
  const [currentStep, setCurrentStep] = useState(PaymentPasswordEditSteps.PAYMENT_PASSWORD);

  const { loading, refetch: changePaymentPassword } = useChangePaymentPassword();

  const checkType = currentType => {
    return type === currentType;
  };

  const title = checkType(ModalType.SET_UP_PAYMENT_PASSWORD)
    ? t('accountSettings.accountSecurity.form.paymentPassword.setUp.title')
    : t('accountSettings.accountSecurity.form.paymentPassword.change.title');

  const resetModal = () => {
    setCurrentStep(PaymentPasswordEditSteps.PAYMENT_PASSWORD);
    setPaymentPasswordData(null);
  };

  const onFinishSuccess = () => {
    notificationApi.success({ message: t('notification.success.changePaymentPassword') });
    resetModal();
    onFinish();
    onClose();
  };

  const onError = ({ error, errorMessage }) => {
    onClose();
    resetModal();
    checkPaymentError(error);
    notificationApi.error({ message: t(errorMessage) });
  };

  const getPaymentPasswordData = data => {
    setPaymentPasswordData(data);
    setCurrentStep(PaymentPasswordEditSteps.AUTH);
  };

  const checkAuth = async authData => {
    const { success, errorMessage, error } = await changePaymentPassword({ ...paymentPasswordData, ...authData });

    if (success) {
      onFinishSuccess();
    } else {
      onError({ error, errorMessage });
    }
  };

  const handleClose = () => {
    resetModal();
    onClose();
  };

  return (
    <StagedModal
      title={title}
      open={open}
      width={450}
      steps={steps}
      currentStep={currentStep}
      onChangeStep={setCurrentStep}
      onClose={handleClose}
      beforeStepsComponent={checkType(ModalType.CHANGE_PAYMENT_PASSWORD) && <ChangePasswordWarning />}
    >
      {currentStep === PaymentPasswordEditSteps.PAYMENT_PASSWORD ? (
        <PaymentPasswordForm onFinish={getPaymentPasswordData} />
      ) : (
        <AuthenticationForm
          showGoBack
          includePaymentPassword={checkType(ModalType.CHANGE_PAYMENT_PASSWORD)}
          includePassword={checkType(ModalType.SET_UP_PAYMENT_PASSWORD)}
          includeGa={user['2fa']}
          loading={loading}
          onFinish={checkAuth}
          onGoBack={() => setCurrentStep(PaymentPasswordEditSteps.PAYMENT_PASSWORD)}
        />
      )}
    </StagedModal>
  );
};

export default PaymentPasswordEditModal;
