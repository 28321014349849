import React from 'react';

import SwitchItem from './components/SwitchItem';

import './SwitchList.styles.scss';

const SwitchList = ({ items = [], values, loading, onChange = () => {} }) => {
  return (
    <div className="np-switch-list">
      {items.map(notification => (
        <SwitchItem
          key={notification.id}
          id={notification.id}
          title={notification.title}
          tooltip={notification.tooltip}
          loading={loading}
          isActive={values?.[notification.id]}
          divider
          readOnly={notification.readOnly}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

export default SwitchList;
