import React from 'react';
import { useTranslation } from 'react-i18next';

import FilterSelect from '../FilterSelect';

import { CoinsLabels } from 'constants/coins';

const CoinFilter = ({ width = 100, minWidth = 100, ...props }) => {
  const { t } = useTranslation();

  return (
    <FilterSelect
      allowClear={false}
      options={CoinsLabels}
      popupMatchSelectWidth={false}
      placeholder={t('common.coin')}
      width={width}
      minWidth={minWidth}
      {...props}
    />
  );
};

export default CoinFilter;
