import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useNotifications from 'hooks/common/useNotifications';

import { ExportButton, ExportModal } from 'modules';

const ExportToEmailButton = ({ callback = async () => {}, ...props }) => {
  const { t } = useTranslation();
  const { notificationApi } = useNotifications();

  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onExport = async (params = {}) => {
    setLoading(true);

    const { success, errorMessage } = await callback(params);

    if (success) {
      notificationApi.success({ message: t('success.export.emailSend') });
      closeModal();
    } else {
      notificationApi.error({ message: t(errorMessage) });
    }

    setLoading(false);
  };

  return (
    <>
      <ExportButton onClick={openModal} {...props} />
      <ExportModal open={showModal} loading={loading} onClose={closeModal} onFinish={onExport} />
    </>
  );
};

export default ExportToEmailButton;
