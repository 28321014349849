import { useQuery } from '@tanstack/react-query';
import { DefaultPagination } from 'constants/pagination';
import useUserData from 'hooks/user/useUserData';

import { getApiKeys } from 'services/apiManagement.service';

const STALE_TIME_MS = 1000;

const useApiManagementDataQuery = (options = {}) => {
  const { page = DefaultPagination.START_PAGE, pageSize = DefaultPagination.PAGE_LIMIT } = options;

  const { user } = useUserData();

  const select = result => {
    return result.data;
  };

  return useQuery({
    queryKey: ['api-management-data', page, pageSize],
    enabled: !!user,
    staleTime: STALE_TIME_MS,
    refetchOnWindowFocus: false,
    queryFn: () => getApiKeys({ page, pageSize }),
    select,
  });
};

export default useApiManagementDataQuery;
