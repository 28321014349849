import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavigationMenu as BaseNavigationMenu } from 'components';

import useUserData from 'hooks/user/useUserData';
import useDesktopMenuLinks from './hooks/useDesktopMenuLinks';
import useUserSidebarOptions from 'hooks/navigation/useUserSidebarOptions';

import { publicUrls } from 'constants/urls';

const NavigationMenu = () => {
  const mobileItems = useUserSidebarOptions();
  const desktopItems = useDesktopMenuLinks();

  const location = useLocation();
  const navigate = useNavigate();

  const { user } = useUserData();

  const active = location.pathname;

  const defaultOpenKeys = useMemo(() => {
    if (
      active.includes(publicUrls.profile.settings.main) ||
      active.includes(publicUrls.profile.accountManagement.main)
    ) {
      return publicUrls.profile.settings.main;
    }

    if (active.includes(publicUrls.profile.wallet.main)) {
      return publicUrls.profile.wallet.main;
    }

    return null;
  }, [active]);

  const onClick = item => {
    navigate(item?.item.props.link);
  };

  const mobile = { items: user ? mobileItems : [], onClick, active, defaultOpenKeys };
  const desktop = { items: user ? desktopItems : [] };

  return <BaseNavigationMenu mobile={mobile} desktop={desktop} />;
};

export default NavigationMenu;
