import React from 'react';
import { Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckCircleFilled, LogoutOutlined, MessageOutlined } from '@ant-design/icons';

import EllipsisText from 'components/typography/EllipsisText';
import AutoTransferTooltip from './components/AutoTransferTooltip';

import useWorkersStatus from './hooks/useWorkersStatus';
import getComputingSpeedUnit from 'utils/power/getComputingSpeedUnit';

import './SubAccountMenuItem.styles.scss';

const { Text } = Typography;

const SubAccountMenuItem = ({ name, hr, hrPower, workers, selected, settings, note, isMain, isWatcher }) => {
  const { t } = useTranslation();

  const { auto_payout, auto_transfer_to } = settings || {};

  const isTransferActive = auto_payout || auto_transfer_to;

  const selectedClass = selected ? 'selected' : '';
  const activeTransfersClass = isTransferActive ? 'active' : '';
  const accountType = isMain ? `(${t('common.mainLowerCase')})` : '';

  const workerStatuses = useWorkersStatus(workers);

  return (
    <div className={`np-user-menu-sub-account ${selectedClass}`}>
      <div className="np-user-menu-sub-account__header">
        <Text className="np-user-menu-sub-account__header-title">
          {name} {accountType}
        </Text>

        {selected && <CheckCircleFilled className="np-user-menu-sub-account__header-icon" />}
      </div>

      <div className="np-user-menu-sub-account__hashrate">
        <div className="np-user-menu-sub-account__hashrate-circle" />

        <Text className="np-user-menu-sub-account__hashrate-text">{getComputingSpeedUnit(hr, hrPower)}</Text>
      </div>

      {(!isWatcher || note) && (
        <div className="np-user-menu-sub-account__info">
          {!isWatcher && (
            <Tooltip title={isTransferActive ? <AutoTransferTooltip settings={settings} /> : null}>
              <div className={`np-user-menu-sub-account__info-block ${activeTransfersClass}`}>
                <LogoutOutlined className="np-user-menu-sub-account__info-block-icon" />

                <Text className="np-user-menu-sub-account__info-block-title">
                  {isTransferActive
                    ? t('menu.userProfile.subAccountsInfo.autoTransfers.enabled')
                    : t('menu.userProfile.subAccountsInfo.autoTransfers.disabled')}
                </Text>
              </div>
            </Tooltip>
          )}

          {note && (
            <div className={`np-user-menu-sub-account__info-block`}>
              <MessageOutlined className="np-user-menu-sub-account__info-block-icon" />

              <EllipsisText width={200} className="np-user-menu-sub-account__info-block-title">
                {note}
              </EllipsisText>
            </div>
          )}
        </div>
      )}

      <div className="np-user-menu-sub-account__workers">
        <Text className="np-user-menu-sub-account__workers-title">
          {t('menu.userProfile.subAccountsInfo.workerStatus')}:
        </Text>

        <div className="np-user-menu-sub-account__workers-body">
          {workerStatuses.map(worker => (
            <Tooltip key={worker.label} title={worker.label}>
              <div className="np-user-menu-sub-account__workers-body-item">
                {worker.icon}

                <Text>{worker.value}</Text>
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubAccountMenuItem;
