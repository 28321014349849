import React from 'react';

import { Loading } from 'components';

import { useCheckUserAuth } from '../utils/hooks/useCheckUserAuth';

import UrlRestoringService from 'services/urlRestoring.service';
import { publicUrls } from 'constants/urls';
import { Navigate } from 'react-router-dom';

const ProtectedPublicContainer = ({ children, redirectOnAuth = publicUrls.profile.main }) => {
  const { loading, isAuth } = useCheckUserAuth();

  if (loading) {
    return <Loading isLoading fullScreen />;
  }

  if (isAuth) {
    const savedUrl = UrlRestoringService.getUrl();
    const to = window.location.pathname !== savedUrl ? savedUrl : redirectOnAuth;

    return <Navigate to={to} />;
  }

  return children;
};

export default ProtectedPublicContainer;
