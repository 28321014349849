import { createSlice } from '@reduxjs/toolkit';
import { StorageKey } from 'constants/localStorage';

const selectedAccountIdLS = localStorage.getItem(StorageKey.SELECTED_ACCOUNT_ID);

const defaultData = {
  selectedAccountId: selectedAccountIdLS ? +selectedAccountIdLS : null,
  accountsList: [],
  mainAccountId: null,
};

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState: defaultData,
  reducers: {
    setAccounts: (state, action) => {
      const { main, subaccounts: subAccounts } = action.payload;
      const accountsData = {
        selectedAccountId: state.selectedAccountId ? state.selectedAccountId : main.id,
        accountsList: [main, ...subAccounts],
      };

      state.selectedAccountId = accountsData.selectedAccountId;
      state.accountsList = accountsData.accountsList;
      state.mainAccountId = main.id;

      localStorage.setItem(StorageKey.SELECTED_ACCOUNT_ID, accountsData.selectedAccountId);
    },
    setSelectedAccountId: (state, action) => {
      const accountData = {
        ...state,
        selectedAccountId: action.payload,
      };

      state.selectedAccountId = accountData.selectedAccountId;
      localStorage.setItem(StorageKey.SELECTED_ACCOUNT_ID, accountData.selectedAccountId);
    },
    resetAccounts: state => {
      state.accountsList = defaultData.accountsList;
      state.selectedAccountId = null;
      localStorage.removeItem(StorageKey.SELECTED_ACCOUNT_ID);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAccounts, setSelectedAccountId, resetAccounts } = accountsSlice.actions;

export default accountsSlice.reducer;
