import React from 'react';

import { TableWithHeader } from 'components';

import './MiningAccountsTable.styles.scss';

const MiningAccountsTable = ({ className = '', ...props }) => {
  return (
    <TableWithHeader
      title="miningAccounts.title"
      rowKey={record => record.account_name}
      className={`np-mining-accounts-table ${className}`}
      {...props}
    />
  );
};

export default MiningAccountsTable;
