import React from 'react';
import { useTranslation } from 'react-i18next';

import { Loading } from 'components';

import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import './RowValue.styles.scss';

const RowValue = ({ value, loading }) => {
  const { t } = useTranslation();
  const { isMobileView } = useWindowResponsiveness(700);

  const fontSize = isMobileView ? 14 : 16;

  return (
    <Loading isLoading={loading} className="np-row-value__loading" inline noMargin>
      <div style={{ fontSize }} className="np-row-value">
        {value || t('common.notSet')}
      </div>
    </Loading>
  );
};

export default RowValue;
