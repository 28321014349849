import React from 'react';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import './ConfirmationReminders.styles.scss';

const ConfirmationReminders = ({ reminders = [] }) => {
  const getComponents = reminder => {
    const components = {};

    if (reminder.link) {
      components.Link = <NavLink to={reminder.link} />;
    }

    return components;
  };

  return (
    <ol className="np-reset-process-confirmation-reminders">
      {reminders.map(reminder => (
        <li key={reminder.key}>
          <Trans i18nKey={reminder.label} components={getComponents(reminder)} />
        </li>
      ))}
    </ol>
  );
};

export default ConfirmationReminders;
