import React from 'react';

import BTCIcon from 'assets/images/BTC.png';
import InfoItem from './components/InfoItem';

import formatBtcAmount from 'utils/coins/formatBtcAmount';

import { MIN_PAYMENT_AMOUNT, AUTO_WITHDRAWAL_TIME } from 'constants/payment';

import './PaymentSettingsInfo.styles.scss';

const PaymentSettingsInfo = ({ data, loading }) => {
  return (
    <div className="np-payment-settings-info">
      <InfoItem
        title={'payment.paymentSettings.balance'}
        titleIcon={<img src={BTCIcon} alt="btc-icon" />}
        value={formatBtcAmount(data?.balance)}
        loading={loading}
      />

      <InfoItem
        title={'payment.paymentSettings.minimalPayment'}
        value={formatBtcAmount(MIN_PAYMENT_AMOUNT, { round: false })}
      />

      <InfoItem title={'payment.paymentSettings.paymentTime'} value={AUTO_WITHDRAWAL_TIME} />
    </div>
  );
};

export default PaymentSettingsInfo;
