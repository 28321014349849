import React from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

const NoData = () => {
  const { t } = useTranslation();

  return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('common.noData')} />;
};

export default NoData;
