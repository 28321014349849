import { useTranslation } from 'react-i18next';

import formatDate from 'utils/date/formatDate';
import { DATE_DOT_FORMAT_WITH_TIME } from 'constants/date';

const useMyReferredUsersColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('referralRewards.table.myReferredUsers.columns.referredAccounts'),
      dataIndex: 'account_name',
      key: 'account_name',
      align: 'center',
      width: 200,
    },
    {
      title: t('referralRewards.table.myReferredUsers.columns.registrationDate'),
      dataIndex: 'created',
      key: 'created',
      align: 'center',
      width: 200,
      render: value => formatDate(value, { format: DATE_DOT_FORMAT_WITH_TIME }),
    },

    {
      title: t('referralRewards.table.myReferredUsers.columns.subAccountsNumber'),
      dataIndex: 'sub_acc_number',
      key: 'sub_acc_number',
      align: 'center',
      width: 200,
    },
  ];
};

export default useMyReferredUsersColumns;
