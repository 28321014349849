import { publicUrls } from 'constants/urls';

const URL_KEY = 'urlToRestore';
const REDIRECT_ON_FAIL_KEY = 'redirectOnFail';
const NEW_USER_URL_KEY = 'newUserUrl';

export default class UrlRestoringService {
  static setUrl(url) {
    sessionStorage.setItem(URL_KEY, url);
  }

  static getUrl() {
    const url = sessionStorage.getItem(URL_KEY);
    sessionStorage.removeItem(URL_KEY);

    return url || publicUrls.profile.main;
  }

  static setRedirectUrl(url) {
    sessionStorage.setItem(REDIRECT_ON_FAIL_KEY, url);
  }

  static getRedirectUrl() {
    const url = sessionStorage.getItem(REDIRECT_ON_FAIL_KEY);
    sessionStorage.removeItem(REDIRECT_ON_FAIL_KEY);

    return url || publicUrls.login;
  }

  static setNewUserUrl(url) {
    sessionStorage.setItem(NEW_USER_URL_KEY, url);
  }

  static getNewUserUrl() {
    const url = sessionStorage.getItem(NEW_USER_URL_KEY);
    sessionStorage.removeItem(NEW_USER_URL_KEY);

    return url;
  }
}
