import React from 'react';

import { TableWithHeader } from 'components';

import useMyEffectiveReferralsColumns from './hooks/useMyEffectiveReferralsColumns';

import './EffectiveReferralsTable.styles.scss';

const EffectiveReferralsTable = ({ dataSource, className = '', ...props }) => {
  const columns = useMyEffectiveReferralsColumns();

  return (
    <TableWithHeader
      title="referralRewards.table.myEffectiveReferrals.title"
      columns={columns}
      dataSource={dataSource}
      rowKey={record => record.account_name}
      className={`np-effective-referrals-table ${className}`}
      {...props}
    />
  );
};

export default EffectiveReferralsTable;
