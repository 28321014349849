import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyOutlined } from '@ant-design/icons';

import { DashboardIcon, LinksIcon, SettingsIcon, WalletIcon, WorkersIcon } from 'assets/images';

import useApiManagementDataQuery from 'hooks/requests/user/query/useApiManagementDataQuery';

import { publicUrls } from 'constants/urls';

const useUserSidebarOptions = () => {
  const { t } = useTranslation();

  const { data } = useApiManagementDataQuery();

  const getItem = (label, link, icon, children, type) => ({
    key: link,
    link,
    icon,
    children,
    label,
    type,
  });

  const apiSettingsItem = data?.settings?.api_enabled
    ? [
        getItem(t('menu.userProfile.apiSettings'), publicUrls.profile.accountManagement.main, <KeyOutlined />, [
          getItem(t('menu.userProfile.apiManagement'), publicUrls.profile.apiManagement.apiKeys),
        ]),
      ]
    : [];

  return [
    getItem(t('menu.userProfile.dashboard'), publicUrls.profile.main, <DashboardIcon />),

    getItem(t('menu.userProfile.workers'), publicUrls.profile.workers, <WorkersIcon />),

    getItem(t('menu.userProfile.assetsAndPayments'), publicUrls.profile.wallet.main, <WalletIcon />, [
      getItem(t('menu.userProfile.bills'), publicUrls.profile.wallet.myBills),
      getItem(t('menu.userProfile.paymentSettings'), publicUrls.profile.settings.payment),
      getItem(t('menu.userProfile.depositsAndWithdrawal'), publicUrls.profile.wallet.depositAndWithdrawal),
    ]),

    getItem(t('menu.userProfile.accountSettings'), publicUrls.profile.settings.main, <SettingsIcon />, [
      getItem(t('menu.userProfile.profile'), publicUrls.profile.settings.account),
      getItem(t('menu.userProfile.subAccounts'), publicUrls.profile.accountManagement.miningAccounts),
      getItem(t('menu.userProfile.watchers'), publicUrls.profile.accountManagement.watcher),
      getItem(t('menu.userProfile.notifications'), publicUrls.profile.accountManagement.notifications),
      getItem(t('menu.userProfile.mining'), publicUrls.profile.settings.mining),
    ]),

    ...apiSettingsItem,

    getItem(t('menu.userProfile.referral'), publicUrls.profile.referralRewards, <LinksIcon />),
  ];
};

export default useUserSidebarOptions;
