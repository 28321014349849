import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import NoticeModal from './components/NoticeModal';
import { EnableGaModal, PaymentPasswordEditModal } from 'modules';

import useUserData from 'hooks/user/useUserData';
import useNotifications from 'hooks/common/useNotifications';

import { ModalType, SecurityNoticeParams } from './constants/types';

import { ModalType as PayPasswordModalType } from 'modules/modals/PaymentPasswordEditModal';

import './SecurityNoticeModal.styles.scss';

const SecurityNoticeModal = ({ open, types = [], showSkip, onSkip = () => {}, onClose = () => {}, onReject }) => {
  const { t } = useTranslation();
  const { user, loading, reloadUser } = useUserData();
  const { notificationApi } = useNotifications();

  const [modalType, setModalType] = useState(ModalType.NOTICEMENT);

  const showTwoFa = !user['2fa'] && types.includes(SecurityNoticeParams.TWO_FA);
  const showPayPassword = !user.has_pay_password && types.includes(SecurityNoticeParams.PAYMENT_PASSWORD);

  const checkIsOpen = type => {
    return open && modalType === type;
  };

  const showNoticeModal = () => {
    setModalType(ModalType.NOTICEMENT);

    if (!showTwoFa && !showPayPassword) {
      onClose();
    }
  };

  const handleReject = onReject || onClose;

  const onFinish = () => {
    switch (modalType) {
      case ModalType.TWO_FA: {
        notificationApi.success({ message: t('notification.success.twoFAEnabled') });
        break;
      }

      case ModalType.PAYMENT_PASSWORD: {
        notificationApi.success({ message: t('notification.success.changePaymentPassword') });
        showNoticeModal();
        break;
      }

      default:
        break;
    }

    reloadUser();
  };

  useEffect(() => {
    if (open) {
      showNoticeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      {open && (
        <NoticeModal
          types={types}
          loading={loading}
          showSkip={showSkip}
          open={checkIsOpen(ModalType.NOTICEMENT)}
          onChangeType={setModalType}
          onReject={handleReject}
          onClose={onClose}
          onSkip={onSkip}
        />
      )}

      <EnableGaModal open={checkIsOpen(ModalType.TWO_FA)} onClose={showNoticeModal} onFinish={onFinish} />

      <PaymentPasswordEditModal
        type={PayPasswordModalType.SET_UP_PAYMENT_PASSWORD}
        open={checkIsOpen(ModalType.PAYMENT_PASSWORD)}
        onClose={showNoticeModal}
        onFinish={onFinish}
      />
    </>
  );
};

export default SecurityNoticeModal;
